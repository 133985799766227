import React, {useEffect, useState, useCallback} from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import MetaTags from '../library/metaTags';
import LoadingIcon from '../library/loadingIcon';
import CheckIcon from '@mui/icons-material/CheckCircle';
import PublicFooter from '../nav/footerPublic';
import diag from '../../images/pricing/blue-abstract.jpg';
import { fromZonedTime } from 'date-fns-tz';
import { customFade, getCsrfToken } from '../../services/guiService';
import Dialog from '../library/customDialog';
import axios from 'axios';
import { Fade, Zoom, Box, Stack, Typography, Button, Alert, Snackbar, ToggleButtonGroup, ToggleButton, ListItem, ListItemIcon, ListItemText, List, DialogContent } from '@mui/material';
// cms
import { client } from './prismic'
import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { useSinglePrismicDocument } from '@prismicio/react';
import { linkResolver } from './slices/slices';
import LoginDialog from '../app/login/loginDialog';


const Pricing = (props) => {

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = fromZonedTime( Date.now(), timezone);
  const utcOpen = fromZonedTime( "2023-01-01T00:00:00.000Z", timezone);
        
  const user = props.appRoot.user;
  const [alignment, setAlignment] = useState('annual');
  const [document, { state }] = useSinglePrismicDocument('pricing');
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false]);
  const [open2, setOpen2] = useState(false);
  const [product, setProduct] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const [csrfToken, setCsrfToken] = useState('');
  const [snack, setSnack] = useState({display: false, title: '', message: '', type: 'success'});
  const navigate = useNavigate();

  const freebullets = [{bullet_text: 'Virtual or in-person meetings'}, {bullet_text: 'One-time invites or booking links'},{bullet_text: 'Email, text or link invites'}, {bullet_text: 'Restaurant search & filter'}, {bullet_text: 'Google & Microsoft calendar sync'}, {bullet_text: 'Email reminders'}, {bullet_text: '5 meetings per month'}];
  const probullets = [{bullet_text: 'Unlimited meetings'}, {bullet_text: 'Text notifications'}, {bullet_text: 'Text reminders'}, {bullet_text: 'Premier support'}];
  

  const handleChange = (e) => {
    setAlignment(e.target.value);
  }

  const FireNav = styled(List)({
    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0
    },
    '& .MuiListItemIcon-root': {
      marginTop: 4,
      minWidth: 0,
      marginRight: 10,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0
    },
  });

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[props.appRoot.setFadeFooter, navigate, fadeTime.length]);

  const launchPortal = () => {
    const postUrl = process.env.REACT_APP_API_URL + '/app/start-portal';
    axios({method: 'GET', url: postUrl, withCredentials: true})
    .then( (response) => {
      window.location.href = response.data.url;
    })
    .catch( (error) => {
      setOpen2(true);
    });
  }

  // Close snackbar notifications
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnack({...snack, display: false});
  };

  const startCheckout = (type, sentUser) => {
    const localUser = sentUser ? sentUser : user;
    // does this user have a subscription?
    if (localUser.product !== 'Starter') {
      launchPortal();
    } else {
      const postUrl = process.env.REACT_APP_API_URL + '/app/start-checkout';
      let priceId;
      if (type === 'plus') {
        priceId = alignment === 'annual' ? process.env.REACT_APP_PLUS_ANNUAL : process.env.REACT_APP_PLUS_MONTHLY
      } else {
        priceId = alignment === 'annual' ? process.env.REACT_APP_ESSENTIALS_ANNUAL : process.env.REACT_APP_ESSENTIALS_MONTHLY
      }
      axios({method: 'POST', url: postUrl, data: {priceId: priceId}, withCredentials: true, headers: {'CSRF-Token': csrfToken}})
      .then( (response) => {
        setOpen2(false);
        window.location.href = response.data.url;
      })
      .catch( (error) => {
        console.log(error)
      });
    }
  }

  const buyNow = (type) => {
    setProduct(type);
    if (utcDate < utcOpen) {
      // can't buy yet
      setSnack({...snack, display: true, type: 'error', message: 'All Meetify features are free through Dec. 31! Come back next year to purchase.'});
    } else if (!user || !user.id) {
      setOpen2(true);
    } else {
      startCheckout(type);
    }
  }

  const handleLogin = (obj) => {
    if (obj.cancel) setOpen2(false);
    if (obj.complete && obj.user) {
      props.appRoot.setUser(obj.user);
      setOpen2(false);
      startCheckout(product, obj.user);
    } 
  }

  useEffect( () => {
    if (loadingState === 'loading' && state === 'loaded') {
      setLoadingState('complete');
      getCsrfToken(setCsrfToken);
      fade('in', null, true);
    } else if (loadingState === 'loading' && (state === 'failed')) {
      navigate('/404');
    }
  }, [loadingState, state, document, navigate, fade]);

  if (loadingState !== 'complete' || !document) {
    return (
      <LoadingIcon />
    )
  } else {
    return (
      <Box>
        <MetaTags content={document} />
        <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box sx={{width: '100%', backgroundColor: '#edf5f6', boxShadow: `inset 0px 0px 50px 0px #c6e7e9`}} align="center" pt={5} pb={7}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} pl={2} pr={2}>
            <Stack alignItems="center" spacing={1}>
                {/* document.data.small_header ? <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Typography mb={{xs: 2, sm: 1, md: 2}} variant="h6" component="h1" sx={{fontWeight: 'bold', textShadow: `1px 1px 6px #f6f6f6`}}>{document.data.small_header}</Typography></Fade>: null */}
                {document.data.large_header ? <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Typography variant="h3" component="h1" sx={{fontWeight: 'bold', textShadow: `2px 2px 10px #f6f6f6`}}>Pricing{/*document.data.large_header*/}</Typography></Fade>: null}
                {document.data.header_text ? <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Box typography="h6" component="h2" pt={2} sx={{lineHeight: '2.0em', fontWeight: 'bold'}}>
                Full featured and free for anyone. <br /> 
                Unlimited and budget-friendly for relationship pros.
                </Box></Fade>: null}{/* <PrismicRichText field={document.data.header_text} />*/}
                <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box pt={2}>
                    <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Subscription Length">
                      <ToggleButton value="monthly" sx={{backgroundColor: 'white'}}>Monthly</ToggleButton>
                      <ToggleButton value="annual" sx={{backgroundColor: 'white'}}>Annual&nbsp;</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Fade>
              </Stack>
            </Box>
        </Box>
        </Fade>

        <Fade in={fadeTime[3]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{width: '100%', backgroundImage: `url(${diag})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', boxShadow: `0px 3px 12px 0px #c6e7e9`, height: {xs: '950px', md: '575px'}}}>
            <Box sx={{width: '100%', height: '100%', backgroundColor: 'rgb(20,46,82,0.1)'}}>
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={4} alignItems="center" justifyContent="center">
                  <Zoom in={slideTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                    <Box>
                      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                        <Box sx={{boxShadow: '2px 2px 10px rgba(0,0,0,0.2)', backgroundColor: 'white', borderRadius: '50px 15px', textAlign: 'center', width: {xs: '300px', sm: '325px'}}} pt={3}>
                          <Typography variant="h5" component="h3" color="headers.main" sx={{fontWeight: 'bold', mt: 0, mb: 0, lineHeight: '2.0em'}}>FREE{/*document.data.starter_title*/}</Typography>
                          <Box><Typography variant="h2" color="primary" sx={{fontWeight: 'bold', mt: 0, mb: 0, lineHeight: '1.0em'}}>$0{/*document.data.starter_text*/}</Typography></Box>
                          {/* <Box><Typography variant="caption" color="textMain" sx={{ mt: 0, mb: 0, lineHeight: '1.0em'}} >Personal or Business Use</Typography></Box> */}
                          <Box pt={3}><Button color="secondary" variant="contained" size="large" onClick={ ()=> fade('out','/invite/what',false)}>Get Started{/* document.data.starter_button_text */}</Button></Box>
                          {/* <Box pl={4} pr={2} sx={{textAlign: 'left'}}><Typography variant="body1" color="textSecondary" mt={4} mb={1}>{document.data.starter_bullet_heading}</Typography></Box> */}
                          <Box pb={3} pt={4} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FireNav disablePadding sx={{width: '80%'}}>
                              {/*document.data.starter.bullets*/}
                              {freebullets.map( (item, index) => {
                                return (
                                  <ListItem key={`starterFeature${index}`} sx={{ py: 0, minHeight: 32 }} disableGutters>
                                  <ListItemIcon sx={{ color: 'inherit' }}>
                                    <CheckIcon color="secondary" aria-label="Bullet point" />
                                  </ListItemIcon>
                                  <ListItemText primary={item.bullet_text} primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}/>
                                </ListItem>
                                )
                              })}
                            </FireNav>
                          </Box>
                        </Box>
                      </Fade>
                    </Box>
                  </Zoom>
                  <Zoom in={slideTime[7]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                    <Box>
                      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                        <Box sx={{boxShadow: '2px 2px 10px rgba(0,0,0,0.2)', backgroundColor: 'secondary.light', borderRadius: '50px 15px', textAlign: 'center', width: {xs: '300px', sm: '325px'}}} pt={3}>
                          <Typography variant="h5" component="h3" color="headers.main" sx={{fontWeight: 'bold', lineHeight: '2.0em'}}>PRO{/* document.data.pro1_title */}</Typography>
                          <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography variant="h2" color="primary" sx={{fontWeight: 'bold', lineHeight: '1.0em'}}>{alignment === 'annual' ? document.data.pro1_annual_price.slice(0, document.data.pro1_annual_price.length-3) : document.data.pro1_monthly_price.slice(0, document.data.pro1_monthly_price.length-3)}</Typography>
                            <Stack justifyContent="center" alignItems="flex-start" spacing={0.8}  sx={{marginLeft: '3px'}}>
                              <Typography variant="h5" color={(alignment === 'annual' && document.data.pro1_annual_price.slice(2, 5) === '.00') || (alignment === 'monthly' && document.data.pro1_monthly_price.slice(document.data.pro1_monthly_price.length-3, document.data.pro1_monthly_price.length) === '.00') ? 'secondary.light' : 'primary.main'} sx={{fontWeight: 'bold', lineHeight: '.6em'}}>{alignment === 'annual' ? document.data.pro1_annual_price.slice(document.data.pro1_annual_price.length-3, document.data.pro1_annual_price.length) : document.data.pro1_monthly_price.slice(document.data.pro1_monthly_price.length-3, document.data.pro1_monthly_price.length)}</Typography>
                              <Typography variant="body2" color="primary" sx={{lineHeight: '1.0em'}} pt='2px'>month</Typography>
                            </Stack>
                          </Stack>
                          <Box mt={1}><Typography variant="caption" color="text.primary" >{alignment === 'annual' ? document.data.pro1_annual_subhead : document.data.pro1_monthly_subhead}</Typography></Box>
                          <Box pt={3}><Button color="primary" variant="contained" size="large" onClick={()=>buyNow('essentials')}>Get Started{/*document.data.pro1_button_text*/}</Button></Box>
                          <Box pl={4} pr={2} sx={{textAlign: 'left'}}><Typography variant="body1" color="headers.main" mt={4} mb={1}>Everything in Free plus:{/* document.data.pro1_bullet_heading */}</Typography></Box>
                          <Box  sx={{display: 'flex', justifyContent: 'center'}} pb={6.75}>
                            <FireNav disablePadding sx={{width: '80%'}}>
                              {/* document.data.pro1_bullets */ }
                              {probullets.map( (item, index) => {
                                return (
                                  <ListItem key={`essentialsFeature${index}`} sx={{ py: 0, minHeight: 32 }} disableGutters>
                                  <ListItemIcon sx={{ color: 'inherit' }}>
                                    <CheckIcon color="primary" />
                                  </ListItemIcon>
                                  <ListItemText primary={item.bullet_text} primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium', color: 'text.primary' }}/>
                                </ListItem>
                                )
                              })}
                            </FireNav>
                          </Box>
                          {/* <Box pl={2} pr={2} pt={1} pb={2}>
                            <Typography variant="caption" color="headers.main"><PrismicRichText field={document.data.pro1_soon_text} /></Typography>
                            </Box>*/}
                        </Box>
                      </Fade>
                    </Box>
                  </Zoom>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Fade>

        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={snack.display} autoHideDuration={4500} onClose={handleSnackClose}>
            <Alert elevation={6} variant="filled" severity={snack.type} onClose={handleSnackClose}>
              {snack.message}
            </Alert>
        </Snackbar>

        <Dialog open={open2} onClose={ () => setOpen2(false) } fullWidth>
          <DialogContent sx={{minHeight: {xs: 450, sm: 475}}}>
            {open2 ? <LoginDialog passback={handleLogin} appRoot={props.appRoot} display="new" /> : null}
          </DialogContent>
        </Dialog>
        <Box mt={2}>&nbsp;</Box>
        <PublicFooter appRoot={props.appRoot} />
      </Box>
    );
  }
}

const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Pricing {...props} />
      {process.env.REACT_APP_ENV !== 'production' ? <PrismicToolbar repositoryName="meetify" /> : null}
    </PrismicProvider>
  )
}

export default Wrapper;