import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import AlertMessage from '../../library/alertMessage';
import LoginForm from './loginForm';
import SignupForm from './signupForm';
import PasswordForm from './passwordForm';
import EmailForm from './emailForm';
import axios from 'axios';

const LoginDialog = (props) => {

  const appRoot = props.appRoot;
  const [display, setDisplay] = useState(props.display ? props.display : 'login');
  const [alert, setAlert] = useState(props.alert ? props.alert : {});

  const updateData = async (obj) => {
    // If you are linking to a different page, change display
    if (obj.display) setDisplay(obj.display);
    // If you are cancelling, pass the cancel back
    if (obj.cancel && props.passback) props.passback(obj);
    // If you just reset the password, send them back to the login form
    if (obj.reset) {
      setAlert({display: true, type:"success", title: 'Your password has been reset.', message: 'You may now login with your new password.'});
      setDisplay('login');
    } else {
      setAlert({});
    }
    if (obj.logout) {
      props.passback({user: {}});
    }
    // If you just logged in, pass the new user info back
    if (obj.complete && props.passback) {
      const getUrl = process.env.REACT_APP_API_URL + `/users/s?includeFeatures=${props.includeFeatures ? 1 : 0}&includeUsage=${props.includeUsage ? 1 : 0}&includeInvitees=${props.includeInvitees ? 1 : 0}&includeLocations=${props.includeLocations ? 1 : 0}`
      axios.get(getUrl, {withCredentials: true, headers: {'Cache-Control': 'no-cache','Pragma': 'no-cache','Expires': '0'}})
      .then( (response) => { 
          const user = response.data;
          // sessionStorage.setItem('user', JSON.stringify({id: user.id, firstName: user.firstName, lastName: user.lastName, timezone: user.timezone, features: user.features}));
          props.passback({...obj, user: user})
      })
      .catch( (error) => { 
        // sessionStorage.removeItem('user');
        setAlert({display: true, type:"error", title: 'Browser problem', message: 'You logged in successfully, but your browser is having difficulty remembering your session. Try closing the browser completely and opening it again.'});
        setDisplay('error');
      });
    }
  }
   
  let returnModule = '';


  switch(display) {
    case 'new':
      returnModule = ( 
        <SignupForm appRoot={appRoot} inDialog={true} passback={updateData} planner={props.planner ? props.planner : ''} />
      )
      break;
    case 'forgot':
      returnModule = ( 
        <PasswordForm appRoot={appRoot} inDialog={true} passback={updateData} />
      )
      break;
    case 'email':
      returnModule = (
        <EmailForm appRoot={appRoot} inDialog={true} passback={updateData} meetUp={props.meetUp} email={props.email} />
      )
      break;
    case 'error':
      returnModule = ( 
        <Box align="center">
          <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
          Login
          </Typography>
          <Box mt={2} mb={6}>
            <AlertMessage display={alert.display} severity={alert.type} title={alert.title} message={alert.message} />
          </Box>
          <Button type="button" variant="outlined" color="primary" fullWidth onClick={ () => props.passback({cancel: true}) }>
            Close
          </Button>
        </Box>
      )
      break;
    default:
      returnModule = (
        <LoginForm appRoot={appRoot} inDialog={true} passback={updateData} alert={alert} />
      )
      break;
  }

    return (
      <Box mt={1} mb={4} >
        {returnModule}
      </Box>
    )
}

export default LoginDialog;
