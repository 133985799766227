import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Box, Link, TextField, Button, Typography, Stack, Slide, Fade } from '@mui/material';
import validate from '../../../services/validationService';
import AlertMessage from '../../library/alertMessage';
import VerifyForm from './verifyForm'
import { customFade, getCsrfToken } from '../../../services/guiService'
import {Helmet} from "react-helmet-async";
import axios from 'axios';

const defaultObj = {contact: {value:'', error: false, errorText: '', required: true}, 
                    password: {value:'', error: false, errorText: '', visible: false, required: true},
                    rememberMe: {value: false}}

const EmailForm = (props) => {

  const appRoot = props.appRoot;
  const [inDialog] = useState(props.inDialog);
	const [verifyData, setVerifyData] = useState('');
  const [responder, setResponder] = useState('');
	const [csrfToken, setCsrfToken] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const [alert, setAlert] = useState({display: props.alert && props.alert.display ? props.alert.display : false, title: props.alert && props.alert.title ? props.alert.title : '', message: props.alert && props.alert.message ? props.alert.message : '', type: props.alert && props.alert.type ? props.alert.type : 'error'});
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [formData, setFormData] = useState(defaultObj);
  const [submitting, setSubmitting] = useState(false);
  const [defaultTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

  const linkLogin = async () => {
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      props.passback({display: 'login'})
    } else {
      fade('out','/login');
    }
  }

  const passback = props.passback ? props.passback : linkLogin;

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[navigate, fadeTime.length, props.appRoot.setFadeFooter]); 
	

	// Submit the email login form
	const submitForm = useCallback( (passIt, emailValue, resend) => {
		if ( (!formData.contact.error && formData.contact.value) || passIt ) {
      let count = 0;
      let stopNow = false;
      getCsrfToken( setCsrfToken ).then( (localToken) => {
        // This is a safety. If the CSRFToken fails, then we're going to try again (up to 3 times) to avoid issues
        if (resend && resend.count && resend.count > 3) { 
          setAlert({display: true, message: resend.error.response.data.message, type: 'error'});
          window.scrollTo(0, 0);
          stopNow = true;
        } else if (resend) {
          count = resend.count + 1;
        }
        if (!stopNow) {
          setSubmitting(true);
          const postUrl = process.env.REACT_APP_API_URL + '/login/code';
          const bodyData = {
            type: 'login',
            contactData: emailValue ? emailValue : formData.contact.value ? formData.contact.value.trim().toLowerCase() : null,
            userId: (props.meetUp && props.meetUp.responses.filter(x=>x.responder.user).length) ? props.meetUp.responses.filter(x=>x.responder.user)[0].responder.userId : (props.meetUp && !props.meetUp.inviteMultiple && props.meetUp.status === 'p') ? props.meetUp.responses[0].responder.userId : null,
            timezone: defaultTimezone,
            meetUpId: props.meetUp ? props.meetUp.id : null,
            notification: 'contactVerification'
          };
          axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': localToken}})
          .then( async () => {
            if (fadeTime[fadeTime.length-1]) {
              fade('out');
              await new Promise(resolve => setTimeout(resolve, 400));
            }
            setSubmitting(false);
            setAlert({...alert, display: false});
            setVerifyData(emailValue ? emailValue : formData.contact.value);
            setResponder(props.meetUp && props.meetUp.responses.filter(x=>x.responder.user).length ? props.meetUp.responses.filter(x=>x.responder.user)[0].responder.userId : (props.meetUp && !props.meetUp.inviteMultiple && props.meetUp.status === 'p') ? props.meetUp.responses[0].responder.userId : null);
            if (!fadeTime[fadeTime.length-1]) {
              fade('in', null, true);
            } 
          })
          .catch(function (error) {
            console.log(error)
            setSubmitting(false);
            if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('authenticating')) {
              submitForm(passIt, emailValue, {count: count, error: error});
            } else if (error.response && error.response.data && error.response.data.message) {
              setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
            }
          });
        }
      });
		} else {
      setAlert({display: true, type: 'error', title: '', message: 'Please enter a valid email.'});
    }
	}, [csrfToken, fade, fadeTime, formData.contact.error, formData.contact.value, props.meetUp, defaultTimezone, alert]);

  // Get csrfToken
  useEffect( () => {
    if (!csrfToken && loadingState === 'loading') {
      setLoadingState('step1');
      getCsrfToken( setCsrfToken );
    } 
    if (csrfToken && loadingState === 'step1') {
      setLoadingState('complete');
      // if we know the responder, we'll autogenerate the code
      if ( (props.meetUp && props.meetUp.responses.filter(x=>x.responder.user).length) || (props.meetUp && !props.meetUp.inviteMultiple && props.meetUp.status === 'p') ) {
        submitForm(true);
      // if a multiperson meetup with email info (this scenario shouldn't happen any more, since we autogenerate the code for multiperson meetups)
      } else if (props.meetUp && props.meetUp.inviteMultiple && props.email) {
        setFormData({...formData, contact: {...formData.contact, value: props.email, error: false, errorText: ''}});
        submitForm(true, props.email);
      } else {
        props.appRoot.logout(csrfToken);
        fade('in', null, true);
      }
    }
  }, [csrfToken, fade, loadingState, props.appRoot, props.meetUp, submitForm, props.email, formData]);

  const validateContact = (e) => {
    if (!formData.contact.required && !e.target.value) {
      setFormData({...formData, contact: {...formData.contact, value: e.target.value, error: false, errorText: ''}})
    } else {
      const response = validate.check(e.target.value, ['required', 'email']);
      setFormData({...formData, contact: {...formData.contact, value: e.target.value, error: !response.success, errorText: !response.success ? `Must be a valid email.` : ''}})
    }
  }
	
	const resetLogin = (message) => {
		setVerifyData('');
    setResponder('');
    fade('in', null, true);
		if(message) {
      setAlert({display: true, type: 'error', title: 'Oops!', message: message});
		}
	}
	
	const renderResults = () => {
		if (verifyData || responder) {
			return (
				<VerifyForm appRoot={appRoot} type="login" csrfToken={csrfToken} contactData={verifyData} userId={responder} rememberMe={false} resetLogin={linkLogin} resetForm={resetLogin} inDialog={inDialog} passback={passback}
          verifyTitle={'Verify email'} verifyMessage={props.meetUp && !props.meetUp.inviteMultiple && props.meetUp.responses.filter(x=>x.responder.user).length ? 'A code has been sent to the email you used to respond to this meetup.': 'A code has been sent to your email. Enter below.'} />
				)
		} else {
				return (
            <Container align="center">
              {!inDialog ? <Helmet><title>Login - Meetify</title></Helmet> : null}       
              <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
                <Box>
                  <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
							      <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
								      Continue with Email
							      </Typography>
                  </Fade>
                </Box>
              </Slide>
              {alert.display ? <Box mt={4}><AlertMessage id="alertBox" display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>: null}
              {props.meetUp ? <Box mt={4}><Typography variant="body1">Enter the email you used to respond to this meetup.</Typography></Box>: null}
              
              <Box sx={{maxWidth: {xs: '352px', sm: '368px'}}}>
              
                <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box mt={props.meetUp ? 1 : 2}>
                    <TextField id="emailLoginInfo" size="small" label="email" variant="outlined" margin="normal" fullWidth required 
                    error={formData.contact.error} helperText={formData.contact.errorText}
                    onChange={validateContact} maxLength={150} />
                  </Box>
                </Fade>
                <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Stack mt={4} spacing={2} direction="row" justifyContent="center">
                    {inDialog && props.passback ? 
                    <Button id="cancelButton" type="button" variant="outlined" color="primary" size="large" onClick={ () => props.passback({cancel: true}) }>
                      Cancel 
                    </Button>
                    : null}
                    <Button id="loginButton" type="button" variant="contained" color="primary" size="large" onClick={submitForm} disabled={!formData.contact.value || formData.contact.error}>
                      {!submitting ? 'Submit' : <CircularProgress size={26} color="inherit" sx={{mr: 1.6, ml: 1.6}} />} 
                    </Button>
                  </Stack>
                </Fade>
                <Fade in={fadeTime[10]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Stack direction="row" spacing={1} justifyContent="center" mt={6}>
                    <Typography variant="body2" color="textSecondary">Have an account?</Typography>
                    <Link id="backToLogin" component="button" variant="body2" onClick={linkLogin} color="textSecondary">Login</Link>
                  </Stack>
                </Fade>

              </Box>
						</Container>
					);
				}
			}
			
			return renderResults();
			
		}
		
		export default EmailForm;

 