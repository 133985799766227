import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Box, Link, Button, Typography, TextField, InputAdornment, IconButton, Stack, Fade, Slide } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AlertMessage from '../../library/alertMessage';
import validate from '../../../services/validationService';
import { customFade, getCsrfToken } from '../../../services/guiService'
import {Helmet} from "react-helmet-async";
import axios from 'axios';

const defaultObj = {code: {value:'', error: false, errorText: '', required: true}, 
                    password: {value:'', error: false, errorText: '', visible: false, required: true}};

const ResetForm = (props) => {

  const [inDialog] = useState(props.inDialog);
  const [csrfToken, setCsrfToken] = useState(props.csrfToken ? props.csrfToken : '');
  const [loadingState, setLoadingState] = useState('loading');
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [formData, setFormData] = useState(defaultObj);
  const [alert, setAlert] = useState({display: false, title: '', message: '', type: 'alert'});
  const [submitting, setSubmitting] = useState(false);

  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

  // Fade and slide animations
  const fade = useCallback( (direction, redirect) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, true, props.appRoot.setFadeFooter) },[navigate, fadeTime.length, props.appRoot.setFadeFooter]);


  useEffect( () => { 
    if (!csrfToken) getCsrfToken( setCsrfToken );
    if (csrfToken && loadingState === 'loading') {
      setLoadingState('complete');
      fade('in', null, true);;
    }
  },[csrfToken, loadingState, fade])

  const linkLogin = async (e) => {
    e.preventDefault();
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      props.passback({display: 'login'})
    } else {
      fade('out','/login');
    }
  }

  const handleClickShowPassword = (e) => {
    setFormData({...formData, password: {...formData.password, visible: !formData.password.visible}});
  };
  
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const validatePassword = (e) => {
    const response = validate.check(e.target.value, ['required', 'minLength_8', 'password']);
    setFormData({...formData, password: {...formData.password, value: e.target.value, error: !response.success, errorText: response.message}});
  }

  const submitCode = () => {
    if (formData.code.value.length) {
      setSubmitting(true);
      const postUrl = process.env.REACT_APP_API_URL + '/login/reset';
      const bodyData = {
        code: formData.code.value,
        type: props.contactData && props.contactData.includes('@') ? 'email' : 'cell',
        contactData: props.contactData ? props.contactData : '',
        password: formData.password.value
      };
      axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': csrfToken}})
      .then( async (response) => {
        if (inDialog && props.passback) {
          fade('out');
          await new Promise(resolve => setTimeout(resolve, 500));
          props.passback({reset: true})
        } else {
          fade('out','/login/complete');
        }
      })
      .catch( (error) => {
        setSubmitting(false)
        if (error.response.data.backToLogin) {
          props.resetLogin(error.response.data.message);
        } else if (error.response.data.message) {
          setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
        }
      });
    }
  }

  const sendNewCode = (e) => {
    e.preventDefault();
    if (props.contactData) {
      const postUrl = process.env.REACT_APP_API_URL + '/login/code'
      const bodyData = {
        type: props.contactData.includes('@') ? 'email' : 'cell',
        contactData: props.contactData,
        notification: 'passwordReset'
      };
      axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': csrfToken}})
      .then( (response) => {
        if (response.data.message) {
          setAlert({display: true, type: 'success', title: 'Code Sent!', message: response.data.message});
        }
      })
      .catch(function (error) {
        if (error.response.data.backToLogin) {
          props.resetLogin(error.response.data.message);
        } else if (error.response.data.message) {
          setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
        }
      });
    }
  }

  return (
    <Container maxWidth="xs" align="center">
      {!inDialog ? <Helmet><title>Verification for Password - Meetify</title></Helmet> : null}    
      <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
        <Box>
          <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
              Update Password
            </Typography>
          </Fade>
        </Box>
      </Slide>
      <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box><AlertMessage display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>
      </Fade>
      <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4}>
          <Typography >
            Check your email for a verification code and then choose your new password below.
          </Typography>
        </Box>
      </Fade>
      <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={2}>
          <TextField id="code" label="verification code" variant="outlined" margin="normal" fullWidth required
            maxLength={8} error={formData.code.error} helperText={formData.code.errorText} onChange={ (e) => { setFormData({...formData, code: {...formData.code, value: e.target.value}}) } } />
        </Box>
      </Fade>
      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box>
          <TextField id="password" label="new password" type={formData.password.visible ? 'text' : 'password'} variant="outlined" margin="normal" fullWidth required
            error={formData.password.error} helperText={formData.password.errorText} maxLength={50}
            onChange={validatePassword} 
            InputProps={{
              endAdornment: <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                      {formData.password.visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
        </Box>
      </Fade>
      <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Stack mt={4} spacing={2} direction="row" justifyContent="center">
          {inDialog && props.passback ? 
          <Button type="button" variant="outlined" color="primary" size="large" onClick={ () => props.passback({cancel: true}) }>
            Cancel 
          </Button>
          : null}
          <Button type="button" variant="contained" color="primary" size="large" onClick={submitCode} disabled={!formData.code.value || !formData.password.value || formData.password.error}>
            {!submitting ? 'Submit' : <CircularProgress size={26} color="inherit" sx={{mr: 1.6, ml: 1.6}} />} 
          </Button>
        </Stack>
      </Fade>
      <Fade in={fadeTime[10]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
          <Box mt={4}>
          {!props.loggedIn ?
            <Stack spacing={0.75} direction="row" justifyContent="center"><Typography variant="body2" color="textSecondary">Having troubles?</Typography><Link component="button" variant="body2" onClick={sendNewCode} color="textSecondary" id="newCode">Get a new code</Link><Typography variant="body2" color="textSecondary">or go</Typography><Link component="button" variant="body2" onClick={linkLogin} color="textSecondary">back to login</Link></Stack>
          :
            <Stack spacing={0.75} direction="row" justifyContent="center"><Typography variant="body2" color="textSecondary">Having troubles?</Typography><Link component="button" variant="body2" onClick={sendNewCode} color="textSecondary" id="newCode">Get a new code</Link></Stack>
          }
          </Box>
        </Fade>
      </Container>
  );
}

export default ResetForm;