import { getTimezoneOffset, format, formatInTimeZone } from "date-fns-tz";
import geodist from 'geodist';
// Common functions used in React gui

// Dependencies
import axios from 'axios';
import { getCountryForTimezone, getTimezonesForCountry } from 'countries-and-timezones';

// Revised fade utility
const customFade =  async ( navigate, fadeLength, setFadeTime, setSlideTime, direction, redirect, setSlideCount, updateSlide, setFadeFooter) => {
  const truthVal = (direction ==='out') ? true : false;
  let newFades;
  let startPoint = [];
  if (setSlideCount) setSlideCount(!updateSlide ? 0 : Number(process.env.REACT_APP_TRANS_SPEED_FAST));
  for (let i=0; i<fadeLength; i++) { startPoint.push(truthVal) };
  setFadeTime(startPoint);
  const outSpeed = Number(process.env.REACT_APP_TRANS_SPEED_FAST) ? Number(process.env.REACT_APP_TRANS_SPEED_FAST)/100 : 0;
  for (let i=0; i < fadeLength; i++) {
    newFades = []
    for (let t=0; t < fadeLength; t++) {
      if (t<=i) { newFades.push(!truthVal) } else { newFades.push(truthVal) }
    }
    if (outSpeed) await new Promise(resolve => setTimeout(resolve, direction === 'out' ? outSpeed : outSpeed));
    setFadeTime(newFades);
    if (updateSlide) setSlideTime(newFades);
    if (setFadeFooter && direction === 'out' && i === 2) {
      setFadeFooter(false);
    }
  }
  // if (setSlideCount && updateSlide) setSlideCount(Number(process.env.REACT_APP_TRANS_SPEED_MED));
  if (setFadeFooter && direction === 'in') {
    if (Number(process.env.REACT_APP_TRANS_SPEED_FAST)) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_FAST)));
    setFadeFooter(true);
  }
  if (redirect) {
    if (outSpeed) await new Promise(resolve => setTimeout(resolve, process.env.REACT_APP_TRANS_SPEED_FAST*.1));
    if (String(redirect).includes('back=1')) {
      navigate(redirect.replace('back=1',''),{state: {back: 1}})
    } else {
      let urlObj = null;
      let newRedirect = redirect;
      if (redirect.includes('?')) {
        // convert url variables in the redirect variable to an object that can be passed into state
        const urlVars = redirect.split('?')[1].split('&');
        newRedirect = redirect.split('?')[0];
        urlObj = {};
        urlVars.forEach( entry => {
          const key = entry.split('=')[0];
          const val = entry.split('=')[1];
          urlObj[key] = val;
        })
      }
      navigate(newRedirect, {state: urlObj});
    }
  }
}

const transition = async ( type, direction, redirect, navigate, fadeLength, setFadeTime, setSlideTime, setSlideCount, setFadeFooter, callback) => {
  // If jumping
  if (type === 'jump' && redirect) {
    let urlObj = null;
    let newRedirect = redirect;
    if (redirect.includes('?')) {
      // convert url variables in the redirect variable to an object that can be passed into state
      const urlVars = redirect.split('?')[1].split('&');
      newRedirect = redirect.split('?')[0];
      urlObj = {};
      urlVars.forEach( entry => {
        const key = entry.split('=')[0];
        const val = entry.split('=')[1];
        urlObj[key] = val;
      })
      if (urlObj.id) newRedirect = `${newRedirect}?id=${urlObj.id}`;
    }
    if (callback) setTimeout(callback, '50');
    navigate(newRedirect, {state: urlObj});
  }
  // If callback to execute
  if (callback) {
    if (Number(process.env.REACT_APP_TRANS_SPEED_FAST) > 0) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_FAST)*0.1));
    callback();
  }
  // If fading
  if (type === 'fade') {
    // Eliminate all sliding
    if (setSlideCount) setSlideCount(0);
    const truthVal = (direction ==='out') ? false : true;
    let newFades, startPoint=[];
    const outSpeed = Number(process.env.REACT_APP_TRANS_SPEED_FAST) ? Number(process.env.REACT_APP_TRANS_SPEED_FAST)/100 : 0;
    const inSpeed = Number(process.env.REACT_APP_TRANS_SPEED_FAST) ? Number(process.env.REACT_APP_TRANS_SPEED_FAST)/10 : 0;
    for (let i=0; i<fadeLength; i++) { startPoint.push(truthVal) };
    for (let i=0; i<fadeLength; i++) {
      newFades = [];
      for (let t=0; t<fadeLength; t++) {
        if (t<=i) { newFades.push(truthVal) } else { newFades.push(!truthVal) }
      }
      if (outSpeed > 0) await new Promise(resolve => setTimeout(resolve, direction === 'out' ? outSpeed : inSpeed));
      if (direction === 'in' && setSlideTime) setSlideTime(newFades);
      setFadeTime(newFades);
      if (setFadeFooter && direction === 'out' && i === 2) {
        setFadeFooter(false);
      }
    }
    if (setFadeFooter && direction === 'in') {
      if (direction === 'in') await new Promise(resolve => setTimeout(resolve, inSpeed));
      setFadeFooter(truthVal);
    }
    // If redirecting
    if (direction === 'out' && redirect) {
      if (Number(process.env.REACT_APP_TRANS_SPEED_FAST) > 0) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_FAST)*0.1));
      let urlObj = null;
      let newRedirect = redirect;
      if (redirect.includes('?')) {
        // convert url variables in the redirect variable to an object that can be passed into state
        const urlVars = redirect.split('?')[1].split('&');
        newRedirect = redirect.split('?')[0];
        urlObj = {};
        urlVars.forEach( entry => {
          const key = entry.split('=')[0];
          const val = entry.split('=')[1];
          urlObj[key] = val;
        })
        if (urlObj.id) newRedirect = `${newRedirect}?id=${urlObj.id}`;
      }
      navigate(newRedirect, {state: urlObj});
    }
    // If callback to execute
    if (callback) {
      if (Number(process.env.REACT_APP_TRANS_SPEED_FAST) > 0) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_FAST)*0.1));
      callback();
    }
  }
  // If sliding
  if (type === 'slide') {
    // Enable sliding
    if (setSlideCount) setSlideCount(Number(process.env.REACT_APP_TRANS_SPEED_MED));
    // if sliding out, immediately fade footer
    if (direction === 'out') {
      setFadeFooter(false);
    }
    // Slide and fade
    const truthV = (direction ==='out') ? false : true;  
    let startPoint = [];
    for (let i=0; i<fadeLength; i++) { startPoint.push(truthV) };
    if (direction !== 'out') setFadeTime(startPoint);
    if (setSlideTime) setSlideTime(startPoint);
    // If redirecting
    if (direction === 'out' && redirect) {
      if (Number(process.env.REACT_APP_TRANS_SPEED_MED) > 0) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_MED)*0.75));
      let urlObj = null;
      let newRedirect = redirect;
      if (redirect.includes('?')) {
        // convert url variables in the redirect variable to an object that can be passed into state
        const urlVars = redirect.split('?')[1].split('&');
        newRedirect = redirect.split('?')[0];
        urlObj = {};
        urlVars.forEach( entry => {
          const key = entry.split('=')[0];
          const val = entry.split('=')[1];
          urlObj[key] = val;
        })
        if (urlObj.id) newRedirect = `${newRedirect}?id=${urlObj.id}`;
      }
      navigate(newRedirect, {state: urlObj});
    }
    // If callback to execute
    if (callback) {
      if (Number(process.env.REACT_APP_TRANS_SPEED_FAST) > 0) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_FAST)*0.1));
      callback();
    }
    if (direction === 'in') {
      // wait for slide to finish
      if (Number(process.env.REACT_APP_TRANS_SPEED_MED) > 0) await new Promise(resolve => setTimeout(resolve, Number(process.env.REACT_APP_TRANS_SPEED_MED)*0.5));
      // fade in footer
      setFadeFooter(true);
    }
  }
}

// Get the csrfToken from local or ajax call. If you want to feed in the set method you can. Otherwise, it returns it as a promise
const getCsrfToken = async ( setCsrfToken ) => {
  // Used to store this temporarily, but Safari mobile has issues, so calling a fresh one each time.
  // const localCsrf = sessionStorage.getItem('csrfToken') ? sessionStorage.getItem('csrfToken') : '';
  const localCsrf = ''
  if (!localCsrf) {
    const getUrl = process.env.REACT_APP_API_URL + '/app';
    try {
      const response = await axios.get(getUrl, {withCredentials: true, headers: {'Cache-Control': 'no-cache','Pragma': 'no-cache','Expires': '0'}});
      if (response.data.csrfToken) {
        if( setCsrfToken) setCsrfToken(response.data.csrfToken);
        // if ( !response.data.loggedIn ) sessionStorage.removeItem('user');
        // sessionStorage.setItem('csrfToken', response.data.csrfToken );
        return response.data.csrfToken;
      } else {
        // failed to get csrfToken
        console.log('failed to get csrfToken');
        return null;
      }
    } catch (err) {
      // failed to get csrfToken
      console.log(err);
      return null;
    }
  } else {
    if( setCsrfToken) setCsrfToken(localCsrf);
    return localCsrf;
  }
}

// Get the country from timezone
const getCountryFromTimezone = (timezone) => {
  if (getCountryForTimezone(timezone)) {
    return getCountryForTimezone(timezone).id;
  } else {
    return 'US';
  }
}

const getTimezoneList = (country) => {
  const activeCities = ['America/Anchorage','America/Chicago','America/Denver','America/Los_Angeles','America/New_York','Pacific/Honolulu']
  const timezoneList = (country === 'US' || country === 'USA') ? activeCities : getTimezonesForCountry(country).map( entry => entry.name);
  return timezoneList;
}

const getBaseCountry = async ( setBaseCountry, user, meetUp, timezone ) => {
  const localBaseCountry = sessionStorage.getItem('baseCountry') ? sessionStorage.getItem('baseCountry') : '';
  let returnCountry;
  if (localBaseCountry) {
    returnCountry = localBaseCountry;
  } else if (user && user.timezone && getCountryForTimezone(user.timezone)) {
    returnCountry = getCountryForTimezone(user.timezone).id;
  } else if (meetUp && meetUp.timezone && getCountryForTimezone(meetUp.timezone)) {
    returnCountry = getCountryForTimezone(meetUp.timezone).id;
  } else if (timezone && getCountryForTimezone(timezone)) {
    returnCountry = getCountryForTimezone(timezone).id;
  } else {
    const getUrl = process.env.REACT_APP_API_URL + '/app/location';
    try {
      const response = await axios.get(getUrl, {withCredentials: true});
      returnCountry = response.data.baseCountry;
    } catch (error) {
      returnCountry = 'US';
    }
  }
  if (setBaseCountry) setBaseCountry(returnCountry);
  sessionStorage.setItem('baseCountry',returnCountry);
  return returnCountry
}

// Get the baseCountry and baseLocation from local or ajax call // I NEED IP HERE?
const getBaseLocation = ( setBaseCountry, setBaseLocation, user, meetUp ) => {
  const localBaseCountry = sessionStorage.getItem('baseCountry') ? sessionStorage.getItem('baseCountry') : '';
  const localBaseLocation = sessionStorage.getItem('baseLocation') ? JSON.parse(sessionStorage.getItem('baseLocation')) : '';
  if (localBaseCountry && localBaseLocation) {
    if (setBaseCountry && localBaseCountry) setBaseCountry(localBaseCountry);
    if (setBaseLocation && localBaseLocation) setBaseLocation(localBaseLocation);
  } else if (user && user.homeLoc && user.homeLoc.data) {
    if (setBaseCountry) setBaseCountry(getCountryForTimezone(user.timezone).id);
    if (setBaseLocation) setBaseLocation(user.homeLoc.data.position);
    sessionStorage.setItem('baseCountry',getCountryForTimezone(user.timezone).id);
    sessionStorage.setItem('baseLocation', JSON.stringify(user.homeLoc.data.position));
  } else if (user && user.workLoc && user.workLoc.data) {
    if (setBaseCountry) setBaseCountry(getCountryForTimezone(user.timezone).id);
    if (setBaseLocation) setBaseLocation(user.workLoc.data.position);
    sessionStorage.setItem('baseCountry',getCountryForTimezone(user.timezone).id);
    sessionStorage.setItem('baseLocation', JSON.stringify(user.workLoc.data.position));
  } else if (meetUp && meetUp.locations && meetUp.locations.length && meetUp.locations[0].data) {
    if (setBaseCountry) setBaseCountry(getCountryForTimezone(meetUp.timezone).id);
    if (setBaseLocation) setBaseLocation(meetUp.locations[0].data.position);
    sessionStorage.setItem('baseCountry',getCountryForTimezone(meetUp.timezone).id);
    sessionStorage.setItem('baseLocation', JSON.stringify(meetUp.locations[0].data.position));
  } else {
    const getUrl = process.env.REACT_APP_API_URL + '/app/location';
    axios.get(getUrl, {withCredentials: true})
    .then( (response) => { 
      sessionStorage.setItem('baseCountry',response.data.baseCountry);
      sessionStorage.setItem('baseLocation', JSON.stringify(response.data.baseLocation));
      if (setBaseCountry) setBaseCountry(response.data.baseCountry);
      if (setBaseLocation) setBaseLocation(response.data.baseLocation);
    })
    .catch( (error) => { 
      console.log(error);
    });
  }
}


const convertDatePicker = (dateField, timeField, timezone) => {
  const monthVals = {Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'};
  const dateString = String(dateField);
  const dateArray = dateString.split(' ');
  const timeString = String(timeField);
  const timeArray = timeString.split(' ');
  // figure out offset based on a rough date to start... noon time on that date (not exact, but we assuming we have no meetups 12am-3am when the time can change)
  const offsetSec = getTimezoneOffset(timezone, new Date(`${monthVals[dateArray[1]]}/${dateArray[2]}/${dateArray[3]} 12:00:00`));
  const sign = String(offsetSec)[0] === '-' ? '-' : '+';
  const secs = String(offsetSec)[0] === '-' ? String(offsetSec).substring(1) : offsetSec;
  let hours = Math.floor(secs / (60 * 60 * 1000));
  const divisor_for_minutes = secs % (60 * 60 * 1000);
  let minutes = Math.floor(divisor_for_minutes / 60);
  hours = hours < 10 ? `0${hours}` : `${hours}`;
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  const result = `${dateArray[3]}-${monthVals[dateArray[1]]}-${dateArray[2]}T${timeArray[4]}.000${sign}${hours}:${minutes}`;
  return new Date(result);
}

// combine the date and time into a date object in the correct timezone
const convertDateTime = (dateField, timeField, timezone) => {
  const monthVals = {Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'};
  const dateString = String(new Date(dateField));
  const dateArray = dateString.split(' ');
  const timeString = String(timeField);
  const timeArray = timeString.split(' ');
  const timeHourArray = timeArray[0].split(':');
  if (timeArray[1] === 'pm' && timeHourArray[0] !== '12') timeHourArray[0] = Number(timeHourArray[0]) + 12;
  if (timeArray[1] === 'am' && timeHourArray[0] < 10) timeHourArray[0] = `0${timeHourArray[0]}`
  if (timeArray[1] === 'am' && timeHourArray[0] === '12') timeHourArray[0] = '00';
  // figure out offset based on a rough date to start... noon time on that date (not exact, but we assuming we have no meetups 12am-3am when the time can change due to DST)
  const offsetSec = getTimezoneOffset(timezone, new Date(`${monthVals[dateArray[1]]}/${dateArray[2]}/${dateArray[3]} 12:00:00`));
  const sign = String(offsetSec)[0] === '-' ? '-' : '+';
  const secs = String(offsetSec)[0] === '-' ? String(offsetSec).substring(1) : offsetSec;
  let hours = Math.floor(secs / (60 * 60 * 1000));
  const divisor_for_minutes = secs % (60 * 60 * 1000);
  let minutes = Math.floor(divisor_for_minutes / 60);
  hours = hours < 10 ? `0${hours}` : `${hours}`;
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  const result = `${dateArray[3]}-${monthVals[dateArray[1]]}-${dateArray[2]}T${timeHourArray[0]}:${timeHourArray[1]}:00.000${sign}${hours}:${minutes}`;
  return new Date(result);
}


// Depreciated function that could have been used in Meta tags. Could back this out
const getCMSContent = async (obj, setContent) => {
  console.log(obj)
  const postUrl = `${process.env.REACT_APP_API_URL}/marketing/cms?type=${obj.type}${obj.uid ? '&uid=' + obj.uid : ''}`
  axios.get(postUrl)
  .then( response => {
    if (setContent) setContent(response.data);
    return response.data;
  })
  .catch( (err) => {
    console.log(err)
    return null;
  });
}

// Load ads
const loadAds = async (setReadyForAds, options=['left','right','top']) => {
  const cookieConsent = document.cookie.split('; ').filter(row => row.startsWith('cookieyes-consent=')).map(c=>c.split('=')[1])[0];
  const allowCookies = cookieConsent && cookieConsent.includes('advertisement:yes');
  // if googletag is not loaded, wait for it
  if (!window.googletag || !window.googletag.defineSlot) {
    await new Promise(resolve => setTimeout(resolve, 500));
  }
  if (!window.googletag || !window.googletag.defineSlot) {
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
  if (window.googletag && window.googletag.defineSlot) {
    const googletag = window.googletag;
    // Are these slots already on the page and filled with an ad? This could happen if you access a new page without a full page reload
    if (window.googletag.pubadsReady) {
      refreshAds();
    } else {
      googletag.cmd.push(function() {
        // responsive top
        if (options.includes('top')) {
          const responsiveAdSlot = googletag.defineSlot('/22864271773/responsive_horizontal',[[320, 50], [468, 60], [728, 90]], 'div-gpt-ad-1672948777470-0').addService(googletag.pubads());
          const mapping = googletag.sizeMapping()
              .addSize([1000, 300], [[970, 90], [728, 90]])
              .addSize([728, 300], [728, 90])
              .addSize([468, 300], [468, 60])
              .addSize([0, 0], [320, 50])
              .build();
          responsiveAdSlot.defineSizeMapping(mapping);
        }
        // responsive left
        if (options.includes('left')) {
          const responsiveAdSlot2 = googletag.defineSlot('/22864271773/responsive_vertical', [160, 600], 'div-gpt-ad-1672952665861-0').addService(googletag.pubads());
          const mapping2 = googletag.sizeMapping()
              .addSize([900, 300], [160, 600])
              .addSize([0, 0], [])
              .build();
          responsiveAdSlot2.defineSizeMapping(mapping2);
        }
        // responsive right
        if (options.includes('right')) {
          const responsiveAdSlot3 = googletag.defineSlot('/22864271773/responsive_vertical', [160, 600], 'div-gpt-ad-1672952665861-2').addService(googletag.pubads());
          const mapping3 = googletag.sizeMapping()
              .addSize([1200, 300], [300, 600])
              .addSize([900, 300], [160, 600])
              .addSize([0, 0], [])
              .build();
          responsiveAdSlot3.defineSizeMapping(mapping3);
        }
        // load the ads
        googletag.pubads().setCentering(true);
        googletag.pubads().setPrivacySettings({nonPersonalizedAds: allowCookies});
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });
      googletag.cmd.push(function() {
        if (options.includes('top')) googletag.display('div-gpt-ad-1672948777470-0');
        if (options.includes('left')) googletag.display('div-gpt-ad-1672952665861-0');
        if (options.includes('right')) googletag.display('div-gpt-ad-1672952665861-2');
      });
    }    
    // Fire the ads as soon as you can
    if (setReadyForAds && window.googletag && window.googletag.pubadsReady) { 
      setReadyForAds(true);
    } else if (setReadyForAds) {
      await new Promise(resolve => setTimeout(resolve, 100));
      if (window.googletag && window.googletag.pubadsReady) {
        setReadyForAds(true);
      } else if (setReadyForAds)  {
        await new Promise(resolve => setTimeout(resolve, 100));
        if (window.googletag && window.googletag.pubadsReady) {
          setReadyForAds(true);
        } else if (setReadyForAds)  {
          await new Promise(resolve => setTimeout(resolve, 200));
          if (window.googletag && window.googletag.pubadsReady) {
            setReadyForAds(true);
          } else if (setReadyForAds) {
            await new Promise(resolve => setTimeout(resolve, 500));
            setReadyForAds(true);
          }
        }
      }
    } 
  } else {
    setReadyForAds(true);
  }
}

const initialSize = () => {
  if (window.innerWidth < 600) {
   return 'xs';
  } else if (window.innerWidth < 729) {
    return 'sm2';
  } else if (window.innerWidth < 900) {
    return 'sm1';
  } else if (window.innerWidth < 1200) {
    return 'md';
  } else {
    return 'lg';
  }
}

const initialSize2 = () => {
  if (window.innerWidth < 600) {
   return 'xs';
  } else if (window.innerWidth < 724) {
    return 'sm2';
  } else if (window.innerWidth < 852) {
    return 'sm1';
  } else if (window.innerWidth < 1200) {
    return 'md';
  } else {
    return 'lg';
  }
}

const standardSize = () => {
  if (window.innerWidth < 600) {
    return 'xs';
  } else if (window.innerWidth < 900) {
    return 'sm';
  } else if (window.innerWidth < 1200) {
    return 'md';
  } else {
    return 'lg';
  }
}

const refreshAds = () => {
  const googletag = window.googletag || {};
  if (googletag.cmd) {
    googletag.cmd.push(function() {
      googletag.pubads().refresh();
    });
  }
}

// localStorage stores the last login so we know if we need to refresh the user
const recentLogin = () => {
  const loggedIn = localStorage.getItem('lastLogin') ? JSON.parse(localStorage.getItem('lastLogin')) : {};
  if (loggedIn.exp) {
    const nowTime = new Date(Date.now());
    const expTime = new Date(loggedIn.exp);
    return nowTime < expTime;
  } else {
    return false;
  }
}

const getDistance = (fromPoint, toPoint, unit) => {
  console.log(fromPoint, toPoint)
  const from = {lat: fromPoint.lat, lon: fromPoint.lng}
  const to = {lat: toPoint.lat, lon: toPoint.lng}
  return Math.round( geodist(from, to, {exact: true, unit: unit ? unit : 'miles'} ) * 10) / 10;
}

const getDistances = (fromPoint, locations, unit) => {
  const from = {lat: fromPoint.lat, lon: fromPoint.lng}
  let newLocations = [];
  if (locations) {
    locations.forEach( location => {
      const to = {lat: location.data.position.lat, lon: location.data.position.lng}
      let row = {...location};
      row.distance = Math.round( geodist(from, to, {exact: true, unit: unit ? unit : 'miles'} ) * 10) / 10;
      newLocations.push(row);
    })
  }
  return newLocations;
}

const getCookie = (name) => {
  return document.cookie.split('; ').filter(row => row.startsWith(name + '=')).map(c=>c.split('=')[1])[0];
}

const isPlaywright = () => {  
  return process.env.REACT_APP_ENV !== 'production' && getCookie('playwright') && getCookie('playwright') === process.env.REACT_APP_TEST_COOKIE ? true : false;
}


const checkRestaurant = (openingHours, meetUp) => {
  // First, we generate an array of hours for each day from the data
  const hours = {MO: [], TU: [], WE: [], TH: [], FR: [], SA: [], SU: []}
  let hasHoursListed = false;
  // Get the open hours
  if (openingHours && openingHours.length) {
    hasHoursListed = true;
    Object.keys(hours).forEach( day => {
      openingHours[0].structured.forEach( listing => {
        if (listing.recurrence.replace('FREQ:DAILY;BYDAY:','').includes(day) ) {
          let strippedStart = listing.start.replace('T','');
          let newStart = `${strippedStart[0]}${strippedStart[1]}:${strippedStart[2]}${strippedStart[3]}:${strippedStart[4]}${strippedStart[5]}`
          let newHours = Number(`${listing.duration[2]}${listing.duration[3]}`)
          let newMinutes = Number(`${listing.duration[5]}${listing.duration[6]}`)
          newHours = (newHours  * 60 * 60 * 1000) + (newMinutes * 60 * 1000)
          const startDate = new Date(`2023-01-01T${newStart}.000Z`)
          const endDate = new Date(startDate).getTime() + newHours;
          const newEnd = formatInTimeZone(endDate, 'Etc/GMT', 'HH:mm:ss');
          // let newMinutes = Number(`${listing.duration[5]}${listing.duration[6]}`) * 60 * 1000
          hours[day].push({start: newStart.substring(0,5), end: newEnd.substring(0,5)});
        }
      })
    })
    const hoursList = ['MO','TU','WE','TH','FR','SA','SU']
    // re-order if there are two open times
    hoursList.forEach( day => {
      if (hours[day].length > 1) {
        hours[day] = hours[day].sort( (a, b) => Number(`${a.start[0]}${a.start[1]}`) > Number(`${b.start[0]}${b.start[1]}`) ? 1 : -1 )
      }
    })
    // Then, we rework the data to handle times that span midnight and add the extra hours to the previous day
    for (let i=0; i<Object.keys(hours).length; i++) {
      if (hours[hoursList[i]].length) {
        for (let k=0; k<hours[hoursList[i]].length; k++) {
          if (hours[hoursList[i]][k].start === '00:00' && Number(`${hours[hoursList[i]][k].end[0]}${hours[hoursList[i]][k].end[1]}`) < 6) {
            let prevDate = i === 0 ? 6 : i-1;
            let foundIt = false;
            for (let z=0; z<hours[hoursList[prevDate]].length; z++) {
              if (hours[hoursList[prevDate]][z].end === '24:00' || hours[hoursList[prevDate]][z].end === '23:59') {
                hours[hoursList[prevDate]][z].end = hours[hoursList[i]][k].end;
                foundIt = true;
              }
            }
            if (foundIt) hours[hoursList[i]].splice(k, 1);
          }
        }
      }
    }
    // get rid of the weird 11:59pm stuff
    for (let i=0; i<Object.keys(hours).length; i++) {
      if (hours[hoursList[i]].length) {
        for (let k=0; k<hours[hoursList[i]].length; k++) {
            if (hours[hoursList[i]][k].end === '23:59') hours[hoursList[i]][k].end = '24:00';
        }
      }
    }
    // get the meetTimes or the schedule
    const meetHours = {SU: [], MO: [], TU: [], WE: [], TH: [], FR: [], SA: []}
    if (meetUp.url && meetUp.schedule) {
      meetUp.schedule.scheduleData.forEach( meetTime => {
        // small flaw here if restaurant is not in timezone of meetup
        let myDay = formatInTimeZone(meetTime.startTime, meetUp.timezone, 'EEEEEE').toUpperCase();
        meetHours[myDay].push({start: formatInTimeZone(meetTime.startTime, meetUp.timezone, 'HH:mm:' ) + '00', end: formatInTimeZone(meetTime.endTime, meetUp.timezone, 'HH:mm:' ) + '00'})
      });
    } else if (meetUp.meetTimes && meetUp.meetTimes.length) {
      meetUp.meetTimes.forEach( meetTime => {
        // small flaw here if restaurant is not in timezone of meetup
        let myDay = formatInTimeZone(meetTime.startTime, meetUp.timezone, 'EEEEEE').toUpperCase();
        meetHours[myDay].push({start: formatInTimeZone(meetTime.startTime, meetUp.timezone, 'HH:mm:' ) + '00', end: formatInTimeZone(meetTime.endTime, meetUp.timezone, 'HH:mm:' ) + '00'})
      });
    }
    // evaluate
    let timesAreGood = true;
    Object.keys(meetHours).forEach( day => {
      if (meetHours[day].length) {
        meetHours[day].forEach( thisTime => {
          let storeIsOpen = false;
          let eventStart = new Date(format(Date.now(),'yyyy-MM-dd') + 'T' + thisTime.start);
          let eventEnd = new Date(format(Date.now(),'yyyy-MM-dd') + 'T' + thisTime.end);
          hours[day].forEach( openHour => {
            let open = new Date(format(Date.now(),'yyyy-MM-dd') + 'T' + openHour.start);
            let closed;
            // if spans past midnight or the place is open all day, then endtime is the next day
            if (Number(`${openHour.end[0]}${openHour.end[1]}`) <= Number(`${openHour.start[0]}${openHour.start[1]}`)) {
              let tomorrow = new Date(open.getTime() + (24*60*60*1000));
              closed = new Date(format(tomorrow,'yyyy-MM-dd') + 'T' + openHour.end)
            } else {
              closed = new Date(format(eventStart,'yyyy-MM-dd') + 'T' + openHour.end)
            }
            if (eventStart >= open && eventEnd <= closed) {
              storeIsOpen = true;
            }
          })
          if (!storeIsOpen) timesAreGood = false;
        })
      }
    })
    return {hasHours: hasHoursListed, available: timesAreGood};
  } else {
    return {hasHours: false, available: false};
  }
}

const searchSettings = {
  breakfast: {
    type: 'casual',
    include: ['all'],      
  },
  coffee: {
    type: 'coffee',
    include: ['all'],
    exclude: ['grocery','specialty'],          
  },
  food: {
    price: ['casual'],
    type: 'cuisine',
    include: ['all'],
    exclude: [],
  },
  drinks: {
    type: 'brewery',
    exclude: ['billiards','liquor'],
  },
  dessert: {
  },
  custom: {
    location: '',
  }
}


export { customFade, transition, getCsrfToken, getBaseCountry, getCountryFromTimezone, getBaseLocation, getCMSContent, convertDatePicker, convertDateTime, getTimezoneList, loadAds, standardSize, initialSize, initialSize2, refreshAds, checkRestaurant, searchSettings, recentLogin, getDistance, getDistances, isPlaywright };