import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { getCMSContent } from '../../services/guiService';
import { linkResolver } from '../main/slices/slices';


const defaultObj = {title: 'Meetify', description: 'Easily schedule in person meetings. Choose a central place and time without back and forth emails and texts.'};

const MetaTags = (props) => {

  const [content, setContent] = useState('');
  const [uid, setUid] = useState();

  useEffect( () => {
    if (!content && props.type) {
      try {
        getCMSContent(props, setContent);
      } catch (err) {
        setContent(defaultObj);
      }
    } else if ((!content && props.content) || (uid && props.content && props.content.uid !== uid)) {
      setUid(props.content.uid);
      const newContent = {url: process.env.REACT_APP_API_URL + props.content.url};
      for (let obj of props.content.data.body) {
        if (obj.slice_type === "general_card") {
          newContent.title = obj.primary.title[0].text;
          if (obj.primary.subheader && obj.primary.subheader[0] && obj.primary.subheader[0].text) {
            const subheader = obj.primary.subheader[0].text;
            const newTitle = newContent.title + ' - ' + subheader;
            if (newTitle.length <= 60) {
              newContent.title = subheader;
            }
          }
          newContent.description = obj.primary.description[0].text;
          if (obj.primary.image && obj.primary.image.dimensions) {
            newContent.image = obj.primary.image.url;
            newContent.iHeight = obj.primary.image.dimensions.height;
            newContent.iWidth = obj.primary.image.dimensions.width;
          }
        } else if (obj.slice_type === "twitter_card") {
          newContent.twitterCardType = obj.primary.card_type;
          newContent.twitterImage = obj.primary.image.url;
          newContent.twitterSite = obj.primary.twitter_handle;
          newContent.twitterTitle = obj.primary.title[0].text;
          newContent.twitterDescription = obj.primary.description[0].text;
        }
      }
      setContent(newContent);
    }
  }, [content, props, props.type, props.content, uid]);

  if (content) {
    return (
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
        <link rel="canonical" href={linkResolver(props.content)} />
        {content.iWidth ? <meta property="og:image:width" content={content.iWidth} /> : null}
        {content.iHeight ? <meta property="og:image:height" content={content.iHeight} /> : null}
        <meta property="og:title" content={content.title} />
        <meta property="og:description" content={content.description} />
        <meta property="og:type" content={linkResolver(props.content).includes('resources') ? "article" : "website"} />
        <meta property="og:url" content={linkResolver(props.content)} />
        <meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID} />
        {content.image ? <meta property="og:image" content={content.image} /> : null}
        {content.twitterCardType && content.twitterCardType === 'Summary Card with Large Image' ? <meta name="twitter:card" content="summary_large_image" /> : <meta name="twitter:card" content="summary" />}
        {content.twitterSite ? <meta name="twitter:site" content={content.twitterSite} /> : null}
        {content.twitterTitle ? <meta name="twitter:title" content={content.twitterTitle} /> : null}
        {content.twitterDescription ? <meta name="twitter:description" content={content.twitterDescription} /> : null}
        {content.twitterImage ? <meta name="twitter:image" content={content.twitterImage} /> : null}
        {props.youtube ? <script type="application/ld+json">{JSON.stringify(props.youtube)}</script> : null}
      </Helmet>
    );
  } else {
    return null;
  }
}

export default MetaTags;

