// Front end utility service
import { formatInTimeZone } from "date-fns-tz";
import { getCountryForTimezone } from 'countries-and-timezones';
import axios from 'axios';

  // minimum bounding rectangle
  const calculateMBR = (points) => {
    let minX;
    let minY;
    let maxX;
    let maxY;
    for (let point of points) {
      if (!minX || point.lat < minX) { minX = point.lat };
      if (!maxX || point.lat > maxX) { maxX = point.lat };
      if (!minY || point.lng < minY) { minY = point.lng };
      if (!maxY || point.lng > maxY) { maxY = point.lng };
    }
    return [ {lat: minX, lng: minY}, {lat: maxX, lng: maxY} ]
  }

  const getMidpoint = (points) => {
    let sumX = 0;
    let sumY = 0;
    for (let point of points) {
      sumX += point.lat
      sumY += point.lng
    }
    return {lat: sumX/points.length, lng: sumY/points.length}
  }

  const getCoordsFromPoints = (points) => {
    return getMidpoint(calculateMBR(points));
  }

  // simple comparison of arrays, objects, or strings/numbers. Does not include functions
  const compareValues = (a, b) => {
    if ( Array.isArray(a) && Array.isArray(b) ) {
      return compareArrays(a, b);
    } else if ( a instanceof Date && !isNaN(a) ) {
      return (new Date(a.valueOf()).toUTCString() === new Date(b.valueOf()).toUTCString())
    } else if (typeof a === 'object' && !Array.isArray(a) && a !== null && typeof b === 'object' && !Array.isArray(b) && b !== null ) {
      return compareObjects(a, b);
    } else {
      return a === b;
    }
  }

  const compareArrays = (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;
    a.sort();
    b.sort();
    let arraysMatch = true;
    for (let i=0; i<a.length; i++ ) {
      if ( !compareValues(a[i], b[i]) ) arraysMatch = false;
    }
    return arraysMatch;
  }

  const compareObjects = (a, b) => {
    if (typeof a !== 'object' || Array.isArray(a) || a === null || typeof b !== 'object' || Array.isArray(b) || b === null ) return false;
    const aKeys = Object.keys(a);
    if (aKeys.length !== Object.keys(b).length) return false;
    let objectsMatch = true;
    for (const key of aKeys ) {
      if ( !compareValues(a[key], b[key]) ) objectsMatch = false;
    }
    return objectsMatch;
  }

  const parseTitle = (loc) => {
    if (!loc) return ['', '', '', ''];
    if (loc.title && (!loc.data || !loc.data.address || !loc.data.resultType)) {
      return [loc.title, '', '', ''];
    } else {
      const title = loc.settings && loc.settings.title ? loc.settings.title : loc.data.resultType === 'houseNumber' ? loc.data.title.split(', ')[0] : loc.data.title;
      const titleDetails = loc.settings && loc.settings.title ? `${loc.data.address.street}` : loc.data.resultType === 'houseNumber' ? `${loc.data.address.city}` : `${loc.data.address.street}`;
      const titleDetailsWithNumber = loc.settings && loc.settings.title ? `${loc.data.address.houseNumber} ${loc.data.address.street}` : loc.data.resultType === 'houseNumber' ? `${loc.data.address.city}` : `${loc.data.address.houseNumber} ${loc.data.address.street}`;
      const titleCityState = loc.data.address.city && titleDetailsWithNumber !== `${loc.data.address.city}` ? `${loc.data.address.city}, ${loc.data.address.stateCode} ${loc.data.address.postalCode}` : ``;
      return [title, titleDetails, titleDetailsWithNumber, titleCityState];
    }
  }

  const generateChangeLog = (type='all', dbMeetUp, latestMeetUp, prevLog=[]) => {
    const newChanges = type ? prevLog.filter( row => row.category !== type ) : []
    if (type === 'all' || type === 'invitees') {
      // Invitees OR responders
      latestMeetUp.invitees.forEach( (row, index) => {
        if ( !row.inviteeId || String(row.inviteeId).includes('O') ) {
          newChanges.push({category: 'invitees', type: 'add', value: `Added invitee ${row.firstName} ${row.lastName} (${row.contact ? row.contact : 'no contact info'}).`})
        } else {
          const original = dbMeetUp.invitees.filter( x=> x.contactId === row.contactId );
          // We're ignoring the order of invitees
          if ( !compareValues( {...original[0], order: 1}, {...row, order: 1} ) ) {
            const changeRow = [`Updated the invitee ${original[0].firstName} ${original[0].lastName}.`]
            if (original[0].firstName !== row.firstName) changeRow.push(`Changed first name from '${original[0].firstName}' to '${row.firstName}'.`);
            if (original[0].lastName !== row.lastName) changeRow.push(`Changed last name from '${original[0].lastName}' to '${row.lastName}'.`);
            if ( (!original[0].location.data && row.location.data) || (original[0].location.data && !row.location.data) || (original[0].location.data && row.location.data && original[0].location.data.id !== row.location.data.id)) changeRow.push(!row.location.data ? 'Removed address.' : `Changed address to '${row.location.data.title}'.`);
            newChanges.push({category: 'invitees', type: 'update', value: changeRow.join(' ')})
          }
        }
      })
      const deleted = dbMeetUp.invitees.filter( x => !latestMeetUp.invitees.map(i=>i.contactId).includes(x.contactId) );
      if (deleted.length) {
        deleted.forEach( row => {
          newChanges.push({category: 'invitees', type: 'delete', value: `Deleted invitee ${row.firstName} ${row.lastName} (${row.contact ? row.contact : 'no contact info'}).`})
        })
      }
    }
    if (type === 'all' || type === 'responses') {
      // Responder name
      latestMeetUp.responses.forEach( (row, index) => {
        if ( !row.responseId || String(row.responseId).includes('O') ) {
          newChanges.push({category: 'responses', type: 'add', value: `Added response from ${row.responder.firstName} ${row.responder.lastName} (${row.responder.contact}).`})
        } else {
          const original = dbMeetUp.responses.filter( x=> x.responseId === row.responseId );
          // We're ignoring the order of invitees
          if ( !compareValues( {...original[0], order: 1}, {...row, order: 1} ) ) {
            console.log('original', original[0])
            console.log('row', row)
            const changeRow = [`Updated the response for ${original[0].responder.firstName} ${original[0].responder.lastName}.`]
            if ( !compareValues(original[0].responder.firstName, row.responder.firstName) ) changeRow.push(`Changed first name to '${row.responder.firstName}'.`);
            if ( !compareValues(original[0].responder.lastName, row.responder.lastName) ) changeRow.push(`Changed last name to '${row.responder.lastName}'.`);
            if ( !compareValues(original[0].selectedTime.times, row.selectedTime.times) ) changeRow.push(`Updated the selected time${row.selectedTime.times.length > 1 ? 's' : ''}.`);
            if ( !compareValues(original[0].selectedTime.alternates, row.selectedTime.alternates) ) changeRow.push(`Updated the alternate time ${row.selectedTime.alternateslength > 1 ? 's' : ''}.`);
            if ( !compareValues(original[0].selectedLocation.locations, row.selectedLocation.locations) ) changeRow.push(`Updated the selected location${row.selectedLocation.locations.length > 1 ? 's' : ''}.`);
            if ( !compareValues(original[0].selectedLocation.alternates, row.selectedLocation.alternates) ) changeRow.push(`Updated the alternate location ${row.selectedLocation.alternates.length > 1 ? 's' : ''}.`);
            newChanges.push({category: 'responses', type: 'update', value: changeRow.join(' ')})
          }
        }
      })
      const deletedRs = dbMeetUp.responses.filter( c => !latestMeetUp.responses.map(z=>z.responseId).includes(c.responseId) );
      deletedRs.forEach( row => {
        // If this person was not also an invitee
        // if (dbMeetUp.invitees.filter(x=>x.userId === row.responder.userId).length === 0) {
        // }
        newChanges.push({category: 'responses', type: 'delete', value: `Deleted response from ${row.responder.firstName} ${row.responder.lastName} (${row.responder.contact}).`})
      })
    }
    if (type === 'all' || type === 'meetTimes') {
      // MeetTimes
      latestMeetUp.meetTimes.forEach( (row, index) => {
        if ( String(row.meetTimeId).includes('O') || !dbMeetUp.meetTimes.map(x=>x.meetTimeId).includes(row.meetTimeId) ) {
          newChanges.push({category: 'meetTimes', type: 'add', value: `Added date ${formatInTimeZone(new Date(row.startTime), latestMeetUp.timezone, 'MMM dd, yyyy hh:mm aaa')}.`})
        } else {
          const original = dbMeetUp.meetTimes.filter( x=> x.meetTimeId === row.meetTimeId );
          console.log(original[0], new Date(row.endTime) )
          if ( !compareValues( new Date(original[0].startTime), new Date(row.startTime) ) ) {
            newChanges.push({category: 'meetTimes', type: 'update', value: `Updated date ${formatInTimeZone(new Date(original[0].startTime), latestMeetUp.timezone, 'MMM dd, yyyy hh:mm aaa')} to ${formatInTimeZone(new Date(row.startTime), latestMeetUp.timezone, 'MMM dd, yyyy hh:mm aaa')}.`})
          } else if ( !compareValues( new Date(original[0].endTime), new Date(row.endTime) ) ) {
            newChanges.push({category: 'meetTimes', type: 'update', value: `Updated the end time for ${formatInTimeZone(new Date(original[0].endTime), latestMeetUp.timezone, 'MMM dd, yyyy hh:mm aaa')} to ${formatInTimeZone(new Date(row.endTime), latestMeetUp.timezone, 'hh:mm aaa')}.`})
          } else if ( !compareValues( original[0].range, row.range ) ) {
            newChanges.push({category: 'meetTimes', type: 'update', value: `Updated the range of possible times for ${formatInTimeZone(new Date(row.startTime), latestMeetUp.timezone, 'MMM dd, yyyy hh:mm aaa')}.`})
          }
        }
      })
      const deletedMt = dbMeetUp.meetTimes.filter( x => !latestMeetUp.meetTimes.map(i=>i.meetTimeId).includes(x.meetTimeId) );
      deletedMt.forEach( row => {
        newChanges.push({category: 'meetTimes', type: 'delete', value: `Deleted date ${formatInTimeZone(new Date(row.startTime), latestMeetUp.timezone, 'MMM dd, yyyy hh:mm aaa')}.`})
      })
    }
    if (type === 'all' || type === 'locations') {
      // Locations
      latestMeetUp.locations.forEach( (row, index) => {
        if ( String(row.meetLocationId).includes('O') || ( row.meetLocationId !== 0 && (!dbMeetUp.locations.map(x=>x.meetLocationId).includes(row.meetLocationId) && !dbMeetUp.locations.map(x=>x.locationId).includes(row.locationId)) ) ){
          newChanges.push({category: 'locations', type: 'add', value: `Added location ${parseTitle(row)[0]} on ${row.data.address.street}.`})
        } 
      })
      const deletedLoc = dbMeetUp.locations.filter( x => !latestMeetUp.locations.map(i=>i.meetLocationId).includes(x.meetLocationId) && !latestMeetUp.locations.map(i=>i.locationId).includes(x.locationId) );
      deletedLoc.forEach( row => {
        newChanges.push({category: 'locations', type: 'delete', value: `Deleted location ${parseTitle(row)[0]} on ${row.data.address.street}.`})
      })       
    }
    if (type === 'all' || type === 'settings') {
      // Basic settings to check
      const fields = [['timezone','Updated the timezone to %%value%%.'], ['meetMin', 'Changed meeting length to %%value%% minutes.'],['message','Updated the personal note.'],['settings','Updated the meetup settings.'],['status','Updated the meetup status.'],['scheduleId', 'Changed the schedule used for the booking link']]
      fields.forEach( array => {
        let obj1 = dbMeetUp[array[0]] ? dbMeetUp[array[0]] : null;
        let obj2 = latestMeetUp[array[0]] ? latestMeetUp[array[0]] : null;
        if (array[0] !== 'settings') {
          obj1 = obj1 ? obj1.toString() : null;
          obj2 = obj2 ? obj2.toString() : null;
        }
        if ( !compareValues( obj1, obj2 ) ) {
          newChanges.push({category: 'settings', type: 'update', value: `${array[1].replace('%%value%%',latestMeetUp[array[0]])}`})
        }
      })
      // Final time and location
      if (!dbMeetUp.finalTime && !dbMeetUp.finalLocation && latestMeetUp.finalTime && (latestMeetUp.finalLocation || String(latestMeetUp.finalLocation) === '0')) {
        newChanges.push({category: 'settings', type: 'update', value: `Finalized the time and location.`});
      } else if (dbMeetUp.finalTime && (dbMeetUp.finalLocation || String(dbMeetUp.finalLocation) === '0') && !latestMeetUp.finalTime && !latestMeetUp.finalLocation) {
        newChanges.push({category: 'settings', type: 'update', value: `Re-opened the meetup for response.`});
      }  else {
        let fields = [['finalTime','Updated the final meet time.'],['finalLocation','Updated the final meet location.']];
        fields.forEach( array => {
          if ( !compareValues( dbMeetUp[array[0]], latestMeetUp[array[0]] ) ) {
            newChanges.push({category: 'settings', type: 'update', value: `${array[1].replace('%%value%%',latestMeetUp[array[0]])}`});
          }
        })
      } 
      // Number of invitees
      if (dbMeetUp.inviteMultiple !== latestMeetUp.inviteMultiple) {
        newChanges.push({category: 'settings', type: 'update', value: `Updated the meetup to invite ${latestMeetUp.inviteMultiple ? 'a group' : 'an individual'}.`});
      }
      // Meeting type
      if (dbMeetUp.includeVirtual !== latestMeetUp.includeVirtual || dbMeetUp.type !== latestMeetUp.type) {
        if (dbMeetUp.type !== 'virtual' && latestMeetUp.type === 'virtual') {
          newChanges.push({category: 'settings', type: 'update', value: `Changed the meetup to virtual only.`});
        } else if (dbMeetUp.includeVirtual !== latestMeetUp.includeVirtual) {
          newChanges.push({category: 'settings', type: 'update', value: `${latestMeetUp.includeVirtual ? 'Added' : 'Removed'} virtual as an option from the meetup.`});
          if (dbMeetUp.type !== latestMeetUp.type) {
            newChanges.push({category: 'settings', type: 'update', value: `Changed the ${latestMeetUp.includeVirtual ? 'in person option' : 'meetup type'} to ${latestMeetUp.type}.`});
          }
        } else if (dbMeetUp.type !== latestMeetUp.type) {
          newChanges.push({category: 'settings', type: 'update', value: `Changed the ${latestMeetUp.includeVirtual ? 'in person option' : 'meetup type'} to ${latestMeetUp.type}.`});
        }
      } 
      // Invitee name
      if (dbMeetUp.inviteeName !== latestMeetUp.inviteeName) {
        newChanges.push({category: 'settings', type: 'update', value: latestMeetUp.url ? `Changed the name of the booking link to "${latestMeetUp.inviteeName}".` : `Changed the ${latestMeetUp.inviteMultiple ? 'group name' : 'name of the invitee'} to "${latestMeetUp.inviteeName}".`});
      }
      // Booking link
      if (latestMeetUp.url && dbMeetUp.url !== latestMeetUp.url) {
        newChanges.push({category: 'settings', type: 'update', value: `Updated the url of the booking link to ${process.env.REACT_APP_WEB_URL}/${latestMeetUp.url}`});
      }
      // Virtual details
      if (latestMeetUp.includeVirtual && (!dbMeetUp.settings.virtual || dbMeetUp.settings.virtual !== latestMeetUp.settings.virtual)) {
        newChanges.push({category: 'settings', type: 'update', value: `Updated the virtual meeting details.`});
      }
      // Contact pref
      const pastPref = dbMeetUp.settings && dbMeetUp.settings.contactPref ? dbMeetUp.settings.contactPref : null;
      const currentPref = latestMeetUp.settings && latestMeetUp.settings.contactPref ? latestMeetUp.settings.contactPref : null;
      if (pastPref !== currentPref) {
        newChanges.push({category: 'settings', type: 'update', value: `${latestMeetUp.settings && latestMeetUp.settings.contactPref === 'b' ? 'Enabled' : 'Disabled'} text message reminders/notifications.`});
      }
    }
    if ((type === 'all' || type === 'schedule') && dbMeetUp.schedule && latestMeetUp.schedule) {
      if (dbMeetUp.schedule.name !== latestMeetUp.schedule.name) {
        newChanges.push({category: 'schedule', type: 'update', value: `Updated the schedule name to "${latestMeetUp.schedule.name}".`});
      }
      if (JSON.stringify(dbMeetUp.schedule.settings) !== JSON.stringify(latestMeetUp.schedule.settings)) {
        newChanges.push({category: 'schedule', type: 'update', value: `Updated the holidays/exceptions for the meetup schedule.`});
      }
      const dayName = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      // Loop through each day 0-7 and find matching rows in dbMeetUp.scheduleData and latestMeetUp.scheduleData
      // Then compare the times for each row
      // If they don't match, add a change log
      for (let i=0; i<7; i++) {
        const dbDay = dbMeetUp.schedule.scheduleData && dbMeetUp.schedule.scheduleData.length ? dbMeetUp.schedule.scheduleData.filter( x => Number(new Date(x.startTime).getDay()) === Number(i) ) : [];
        const latestDay = latestMeetUp.schedule.scheduleData && latestMeetUp.schedule.scheduleData.length ? latestMeetUp.schedule.scheduleData.filter( x => new Date(x.startTime).getDay() === i ) : [];
        if (dbDay.length === 0 && latestDay.length === 0) continue;
        if (dbDay.length === 0 && latestDay.length > 0) {
          newChanges.push({category: 'schedule', type: 'update', value: `Added schedule for ${dayName[i]}.`})
        } else if (dbDay.length > 0 && latestDay.length === 0) {
          newChanges.push({category: 'schedule', type: 'update', value: `Removed schedule for ${dayName[i]}.`})
        } else {
          let dateChange = false;
          // loop through each day and compare the start and end times
          for (let j=0; j<dbDay.length; j++) {
            if ((JSON.stringify(new Date(dbDay[j].startTime)) !== JSON.stringify(new Date(latestDay[j].startTime))) || (JSON.stringify(new Date(dbDay[j].endTime)) !== JSON.stringify(new Date(latestDay[j].endTime)))) {
              dateChange = true;
            }
          }
          if (dateChange) {
            newChanges.push({category: 'schedule', type: 'update', value: `Updated the schedule for ${dayName[i]}.`})
          }
        }
      }
      console.log('newChanges', newChanges)
    }
    return newChanges;
  }

  const getCountryForUser = async (user) => {
    let returnCountry;
    if (user.timezone) {
      returnCountry = getCountryForTimezone(user.timezone).id;
    }
    if (!returnCountry) {
      const localBaseCountry = sessionStorage.getItem('baseCountry') ? sessionStorage.getItem('baseCountry') : '';
      if (localBaseCountry) {
        returnCountry = localBaseCountry;
      }
    }
    if (!returnCountry) {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (localTimezone && getCountryForTimezone(localTimezone)) {
        returnCountry = getCountryForTimezone(localTimezone).id;
      }
    }
    if (!returnCountry) {
      try{
        const response = await axios.get(process.env.REACT_APP_API_URL + '/app/location', {withCredentials: true});
        returnCountry = response.data.baseCountry;
      } catch (error) {
        if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(error);
      }
    }
    if (!returnCountry) {
      returnCountry = 'US';
    }
    return returnCountry;
  }


  const getNested = (obj, nest) => {
    let baseObject = obj;
    const args = nest.split('.');
    for (let i=0; i<args.length; i++) {
      if (!baseObject || !baseObject[args[i]]) {
        return false;
      } else if (i === args.length - 1) {
        return baseObject[args[i]];
      } else {
        baseObject = baseObject[args[i]];
      }
    }
  }

export { getCoordsFromPoints, compareValues, generateChangeLog, parseTitle, getNested, getCountryForUser };