import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Box, Link, Button, Typography, TextField, Stack, Fade, Slide, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AlertMessage from '../../library/alertMessage';
import validate from '../../../services/validationService';
import { customFade } from '../../../services/guiService'
import {Helmet} from "react-helmet-async";


const RequireReset = (props) => {

  const [newPassword, setNewPassword] = useState({value:'', error: false, errorText: '', visible: false, required: true});
  const [inDialog] = useState(props.inDialog);
  const [loadingState, setLoadingState] = useState('loading');
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [alert, setAlert] = useState({display: false, title: '', message: '', type: 'alert'});
  const [submitting, setSubmitting] = useState(false);
  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

// Fade and slide animations
const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[navigate, fadeTime.length, props.appRoot.setFadeFooter]); 
  useEffect( () => { 
    if (loadingState === 'loading') {
      setLoadingState('complete');
      fade('in', null, true);;
    }
    if (props.alert) { 
      setAlert(props.alert);
      setSubmitting(false);
    }
  },[loadingState, fade, props.alert])

  const linkLogin = async (e) => {
    e.preventDefault();
    if (props.resetLogin) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      props.resetLogin();
    } else {
      fade('out','/login');
    }
  }

  const handleClickShowPassword = (e) => {
    setNewPassword({...newPassword, visible: !newPassword.visible});
	};
	
	const handleMouseDownPassword = (e) => {
		e.preventDefault();
	};

  const validatePassword = (e) => {
    const response = validate.check(e.target.value, ['required', 'minLength_8', 'password']);
    setNewPassword({...newPassword, value: e.target.value, error: !response.success, errorText: response.message});
	}


  return (
    <Container maxWidth="xs" align="center">
      {!inDialog ? <Helmet><title>Password Reset Required - Meetify</title></Helmet> : null}    
      <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
        <Box>
          <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
                Reset Required
            </Typography>
          </Fade>
        </Box>
      </Slide>
      <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box><AlertMessage display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>
      </Fade>
      <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4}>
          <Typography >
              {props.resetMessage ? props.resetMessage : 'You logged in with a one-time-use password. Please reset your password to continue.'}
          </Typography>
        </Box>
      </Fade>
      <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box>
            <TextField id="password" label="new password" type={newPassword.visible ? 'text' : 'password'} variant="outlined" margin="normal" fullWidth required
                  error={newPassword.error} helperText={newPassword.errorText}  maxLength={50}
                  onChange={validatePassword}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large">
                    {newPassword.visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                  }} />
        </Box>
      </Fade>
      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Stack mt={4} spacing={2} direction="row" justifyContent="center">
          {inDialog && props.passback ? 
          <Button type="button" variant="outlined" color="primary" size="large" onClick={ () => props.passback({cancel: true}) }>
            Cancel 
          </Button>
          : null}
          <Button id="loginButton" type="button" variant="contained" color="primary" size="large" onClick={ () => {setSubmitting(true); props.submitReset(newPassword.value)}} disabled={!newPassword.value || newPassword.error}>
            {!submitting ? 'Submit' : <CircularProgress size={26} color="inherit" sx={{mr: 1.6, ml: 1.6}} />} 
          </Button>
        </Stack>
      </Fade>
      <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4}>
          
          {!props.loggedIn ?
            <Link id="loginLink" component="button" variant="body2" onClick={linkLogin}>Back to login</Link>
            :
             null
          }
        </Box>
      </Fade>
    </Container>
  );
}

export default RequireReset;