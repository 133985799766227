import React, {useEffect, useState, useRef, useCallback} from 'react';
import { useParams, useNavigate, Link as ReactRouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Divider, Fade, Link, Stack, Grid } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import MetaTags from '../library/metaTags';
import SocialShare from './slices/socialShare';
import LoadingIcon from '../library/loadingIcon';
import PublicFooter from '../nav/footerPublic';
import { customFade, initialSize, loadAds, refreshAds } from '../../services/guiService';
import { ResponsiveAd } from '../library/ads'
import { getNested } from '../../services/utilityService';
// cms
import { client } from './prismic'
import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { usePrismicDocumentByUID } from '@prismicio/react'; // usePrismicDocumentsByType 
import { SliceZone } from '@prismicio/react'
import { sliceList, SliceZoneFade, ResponsiveImageClean } from './slices/slices'
import { linkResolver } from './slices/slices';

const classes = {
  imageCorners: `image-imageCorners`,
  thumbCorners: `image-thumbCorners`,
  stickyRight: 'right-stickyRight',
  bannerImage: `image-bannerImage`,
  mainContainer: `image-mainContainer`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.bannerImage}`]: {
    [theme.breakpoints.up("xs")]: {
      border: 0,
      marginBottom: '-4px',
    },
    [theme.breakpoints.up("lg")]: {
      borderRadius: '8px',
      marginTop: '32px',
      marginBottom: '32px',
      boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
    },
  },
  [`& .${classes.mainContainer}`]: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: 'white',
      marginTop: '0px',
      paddingTop: '0px',
    },
    [theme.breakpoints.up("lg")]: {
      borderRadius: '8px',
      boxShadow: '1px 1px 5px rgba(0,0,0,0.1)',
    },
  },
  [`& .${classes.imageCorners}`]: {
    borderRadius: '20px 20px 20px 20px',
  },
  [`& .${classes.thumbCorners}`]: {
    borderRadius: '5px 5px 5px 5px',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.1)',
  },
  [`& .${classes.stickyRight}`]: {
    textAlign: 'center',
    position: "sticky",
    top: "5rem",
  },
}));

const Article = (props) => {

  const appRoot = props.appRoot;
  const { category, uid } = useParams();
  const navigate = useNavigate();
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false]);
  const [loadingState, setLoadingState] = useState('loading');
  const [readyForAds, setReadyForAds] = useState(false);
  const [showAds, setShowAds] = useState(true);
  // Authors now pulled via related links
  // const [authors]= usePrismicDocumentsByType('authors');
  const [browserSize, setBrowserSize] = useState(initialSize());
  const [author, setAuthor] = useState('');
  const [document, { state }]= usePrismicDocumentByUID('article', uid, {fetchLinks: ['authors.name', 'authors.descriptor', 'authors.image', 'article.title', 'article.summary', 'article.image']});
  const [loadedUid, setLoadedUid] = useState('');
  const [timezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/New_York');
  // The dimensions of the responsive image
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const bannerRef = useRef();
  const footerRef = useRef();

  // determine the height and width of the responsive images
  const resizeImg = useCallback( () => {
    if (footerRef.current) {
      const width = footerRef.current.getBoundingClientRect().width;
      const height = 240*width/400
      setWidth(Math.floor(width));
      setHeight(Math.floor(height));
    };
    if (bannerRef.current) {
      const width3 = bannerRef.current.getBoundingClientRect().width;
      let height3;
      if (width3 >= 900) {
        height3 = 400*width3/1200;
      } else if (width3 < 900 && width3 >= 400) {
        height3 = 300*width3/900;
      } else if (width3 < 400) {
        height3 = 275*width3/600;
      }
      setImageWidth(Math.floor(width3));
      setImageHeight(Math.floor(height3));
    };
  }, []);

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(null, fadeTime.length, setFadeTime, null, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[props.appRoot.setFadeFooter, fadeTime.length]);

  useEffect( () => {
    // Set the author for this page
    if (getNested(document, 'data.author.data') && !author) {
      setAuthor(document.data.author);
      // Author no longer pulled separately
      // const thisAuthor = authors.results.filter(row=>row.id === document.data.author.id);
      // if (thisAuthor.length) { 
      //   setAuthor(thisAuthor[0]);
      // } else {
      //   navigate('/404');
      // }
    } 
    if (loadingState === 'loading' && state === 'loaded' && author) {
      if (!document.tags.length || (document.tags[0].toLowerCase() !== category)) navigate('/404');
      if (document.data && document.data.hide_ads) setShowAds(false);
      setBrowserSize(initialSize());
      setLoadingState('preload');
    } else if (loadingState === 'preload' && state === 'loaded' && author) {
      setLoadingState('complete');
      if (showAds) loadAds(setReadyForAds, ['top', 'right']);
      setLoadedUid(uid);
      fade('in');
    } else if (loadingState === 'loading' && (state === 'failed')) {
      navigate('/404');
    } else if (loadingState === 'complete' && loadedUid !== uid) {
      if (props.appRoot) props.appRoot.setFadeFooter(false);
      setLoadingState('loading');
    }
    resizeImg();
  }, [loadingState, category, state, document, author, fade, navigate, loadedUid, props, uid]);
  
  useEffect( () => {
    const goRender = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      window.prerenderReady = true;
    }
    if (!window.prerenderReady && loadingState === 'complete') goRender();
  },[loadingState]);

  // Listen for window resize
  useEffect(() => {
    const handleResize = () => {
      setBrowserSize(initialSize());
      resizeImg();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Refresh ads on size change and page change
  useEffect( () => {
    if (readyForAds) {
      refreshAds();
    }
  }, [readyForAds, appRoot.browserSize, appRoot.pathname])

  if (loadingState === 'loading') {
    return (
      <LoadingIcon />
    )
  } else {
      return (
        <Box>
        {showAds && <Box align="center" sx={{textAlign: 'center'}} mt={2} mb={2}>
          <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Box sx={{textAlign: 'center'}}><Typography variant="caption" pb={0.5}>Advertisement</Typography></Box></Fade>
          <ResponsiveAd slot="top" size={browserSize} appRoot={appRoot} />
        </Box>}
        <StyledBox sx={{width: '100%', backgroundColor: '#edf5f6'}} align="center">
          <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Box sx={{width: imageWidth}}><ResponsiveImageClean field={document.data.image} width={imageWidth} height={imageHeight} className={classes.bannerImage} /></Box></Fade>
          <Container className={classes.mainContainer} ref={bannerRef}>
            <MetaTags content={document} />
            <Box mb={8} pt={{xs:3.5, md: 4.5}} pr={3} pl={3}>
              <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                <Stack direction="row" spacing={0} justifyContent="center">
                  <Typography variant="body2" color="textSecondary"><Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources`} color="textSecondary">Resources</Link>&nbsp;&nbsp;/&nbsp;&nbsp;<Link component={ReactRouterLink} to={process.env.REACT_APP_WEB_URL + '/resources/' + category} color="textSecondary">{category.charAt(0).toUpperCase() + category.slice(1)}</Link></Typography>
                  {/* <Typography variant="body2" color="textSecondary" sx={{display: {xs: 'none', sm: 'block'}}}>&nbsp;&nbsp;/&nbsp;&nbsp;{document.data.title[0].text}</Typography> */}
                </Stack>
              </Fade>
              <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box mt={0.5} mb={4} sx={{textAlign: 'center'}}>
                    <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}}>{document.data.title[0].text}</Typography>
                    {document.data.subheader && document.data.subheader.length ? <Typography mt={0.5} variant="h6" color="primary" component="h2" sx={{fontWeight: 'bold'}}>{document.data.subheader[0].text}</Typography> : null}
                  </Box>
              </Fade>
              <Fade in={fadeTime[3]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                <Box>
                  <Divider />
                  <Grid container alignItems="center">
                    <Grid item xs={7} sm={6}>
                      <Stack direction="row" mt={1} mb={1} ml={2} alignItems="center" justifyContent="flex-start" spacing={1}>
                        {author ? <img src={author.data.image.url} alt={author.data.image.alt} width="40" height="40" border="0" className={classes.imageCorners} /> : null}
                        {author ? <Box sx={{textAlign: 'left'}}><Typography>{author.data.name}</Typography><Typography variant="body2" color="textSecondary">{author.data.descriptor}</Typography></Box> : null}
                      </Stack>
                    </Grid>
                    <Grid item xs={5} sm={6} sx={{textAlign: 'right'}} pr={2}><SocialShare document={document} align="right" /></Grid>
                  </Grid>
                  <Divider />
                </Box>
              </Fade>
              <Grid container mt={3}>
                <Grid item xs sx={{pr: {xs: 0, sm: document.data.body2 ? 6 : 0, lg: document.data.body2 ? 8 : 0}}}>
                  {document.data.show_date ? 
                  <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                    <Box pt={2} pb={2} sx={{textAlign: 'left'}}><Typography variant="body2" color="textSecondary" sx={{fontWeight: 'bold'}}>Published: { formatInTimeZone(new Date(document.data.publish_date), timezone, 'LLL d, yyyy') }</Typography></Box>
                  </Fade>
                  : null}
                  <Box sx={{textAlign: 'left'}}>
                    <SliceZoneFade slices={document.data.body1} components={sliceList} spacing="0" fadeTime={fadeTime} fadeStart="4" />
                  </Box>
                </Grid>
                {document.data.body2 ? 
                <Grid mt={2} item xs={12} sm={0} md="auto" sx={{display: {xs: 'block', sm: 'none', md: 'block'}}}>
                  <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                    {showAds ? <Box className={classes.stickyRight}>
                      {/* <SliceZone slices={document.data.body2} components={sliceList} articles={props.document} /> */}
                        <Box sx={{width: {md: '160px', lg: '300px'}, textAlign: 'center'}}>
                        <Box sx={{textAlign: 'center'}}><Typography variant="caption" pb={1}>Advertisement</Typography></Box>
                        <ResponsiveAd slot="right" size={browserSize} appRoot={appRoot} />
                      </Box>
                    </Box>
                    : 
                    <Box className={classes.stickyRight}>
                      <SliceZone slices={document.data.body2} components={sliceList} articles={props.document} />
                    </Box>
                    }
                  </Fade>
                </Grid>
                : null}
              </Grid>
            </Box>
            <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
              <Divider />
            </Fade>
          </Container>
          <Box pt={2} sx={{display:{xs: 'none', lg: 'block'}}}>&nbsp;</Box>
      </StyledBox>

      {/* See if the last slice has related articles */}
      {getNested(document, 'data.body1') && document.data.body1[document.data.body1.length-1].items && document.data.body1[document.data.body1.length-1].items.length &&document.data.body1[document.data.body1.length-1].items[0].related_article && document.data.body1[document.data.body1.length-1].items[0].related_article.id ? 
        <StyledBox align="center" sx={{backgroundColor: '#edf5f6', boxShadow: `0px 2px 2px 0px #c6e7e9`}} pr={{xs: 5, xl: 0}} pl={{xs: 5, xl: 0}}>
          <Box sx={{maxWidth: 'lg'}} pt={{xs: 4, lg: 0}} pb={{xs: 2, sm: 6}}>
            <Typography variant="h4" component="h4" sx={{pb: 3, fontWeight: 'bold'}}>Related articles:</Typography>
            <Grid container>
            {/* return articles from the related links */}
            {document.data.body1[document.data.body1.length-1].items.map( (row, ind) => {
              const article = row.related_article;
              let padl; let padr; let displayVar = true;
              const remainder = ind % 2;
              if (browserSize === 'xs') {
                padl = '16px';
                padr = '16px';
              } else if (browserSize === 'sm1' || browserSize === 'sm2') {
                if (remainder === 0) {
                  padl = 0;
                  padr = '16px';
                  if (ind === document.data.body1[document.data.body1.length-1].items.length - 1) displayVar = false;
                } else {
                  padl = '16px';
                  padr = 0;
                }
              } else {
                const bigpad = browserSize === 'lg' || browserSize === 'xl' ? '32px' : '24px';
                const smallpad = browserSize === 'lg' || browserSize === 'xl' ? '16px' : '12px';
                if (ind !== 0) {
                  const remainder = ind % 3;
                  if (remainder === 0) {
                    padl = 0;
                    padr = bigpad;
                  } else if (remainder === 1) {
                    padl = smallpad;
                    padr = smallpad;
                  } else {
                    padl = bigpad;
                    padr = 0;
                  }
                } else {
                  padl = 0;
                  padr = bigpad;
                }
              }
            {/* hide last item on small screens */}
            return (
              <Grid item xs={12} sm={6} md={4} key={'related-article-' + ind} pr={padr} pl={padl} pb={{xs: 3, sm: 0}} 
              sx={{display: {xs: 'block', sm: ind % 2 === 0 && ind === document.data.body1[document.data.body1.length-1].items.length - 1 ? 'none' : 'block', md: 'block'}}}> {/* hide last item on small screens */}
                <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="center" ref={footerRef}>
                  <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources/${article.tags.length ? article.tags[0].toLowerCase() + '/' : ''}${article.uid}`} sx={{mt: '3px'}}>
                    <img src={article.data.image.thumbnail.url}  border="0" alt={article.data.title[0].text} className={classes.thumbCorners} width={width} height={height}></img></Link>
                  <Link sx={{typography: 'h6', color: 'primary', fontWeight: 'bold'}} underline="none" component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources/${article.tags.length ? article.tags[0].toLowerCase() + '/' : ''}${article.uid}`}>{article.data.title[0].text}</Link>
                </Stack>
                </Grid>
              )
            })}
            </Grid>
          </Box>
        </StyledBox>
      : null}


      <PublicFooter appRoot={props.appRoot} />
      </Box>
    );
  }
}

const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Article {...props} />
      {process.env.REACT_APP_ENV !== 'production' ? <PrismicToolbar repositoryName="meetify" /> : null}
    </PrismicProvider>
  )
}

export default Wrapper;