import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Box, Link, Button, Typography, TextField, Stack, Fade, Slide } from '@mui/material';
import AlertMessage from '../../library/alertMessage';
import Contact from '../../library/contact';
import parsePhoneNumber from 'awesome-phonenumber';
import { customFade, getCsrfToken } from '../../../services/guiService'
import {Helmet} from "react-helmet-async";
import axios from 'axios';

const defaultObj = {firstName: {value: '', error: false, errorText: ''}, 
                    lastName: {value: '', error: false, errorText: ''},
                    contact: {value: '', error: false, errorText: '', required: false},
                    password: {value: '', error: false, errorText: '', required: false}}

const VerifyForm = ({ csrfToken='', appRoot={}, inDialog=false, contactData='', loggedIn=false, type, userId, baseCountry='USA', rememberMe=false, verifyTitle='Verify Email', verifyMessage='A code has been sent to your email.', passback, resetForm }) => {

  // const appRoot = props.appRoot;
  // const [inDialog] = useState(props.inDialog);
  const [localCsrfToken, setLocalCsrfToken] = useState(csrfToken);
  const [codeField, setCodeField] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [alert, setAlert] = useState({display: false, title: '', message: '', type: 'alert'});
  const [submitting, setSubmitting] = useState(false);
  const [nameNeeded, setNameNeeded] = useState(false);
  const [formData, setFormData] = useState(defaultObj);
  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

// Fade and slide animations
const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, appRoot.setFadeFooter) },[navigate, fadeTime.length, appRoot.setFadeFooter]); 
  
useEffect( () => { 
  if (!localCsrfToken) getCsrfToken( setLocalCsrfToken );
  if (localCsrfToken && loadingState === 'loading') {
    setLoadingState('complete');
    fade('in', null, true);
  }
},[localCsrfToken, loadingState, fade])

  const linkLogin = async (e) => {
    e.preventDefault();
    if (inDialog && passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      passback({display: 'login'})
    } else {
      if (passback) {
        fade('out');
        await new Promise(resolve => setTimeout(resolve, 400));
        passback();
      } else {
        fade('out','/login');
      }
    }
  }

  // update the formData for the name fields
  const updateData = (args) => {
    setFormData({...formData, ...args});
  }

  const submitCode = async () => {
    if (codeField.length) {
      setSubmitting(true);
      const postUrl = process.env.REACT_APP_API_URL + '/login/verify';
      const bodyData = {
        code: codeField,
        type: type ? type : contactData && !contactData.includes('@') ? 'cell' : 'email',
        userId: userId,
        contactData: !contactData ? null : contactData.includes('@') ? contactData.trim().toLowerCase() : parsePhoneNumber(contactData,{regionCode: baseCountry}).getNumber(),
        rememberMe: rememberMe
      };
      if (formData.firstName.value) bodyData.firstName = formData.firstName.value;
      if (formData.lastName.value) bodyData.lastName = formData.lastName.value;
      axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': localCsrfToken}})
      .then( async (response) => {
        const newUser = await appRoot.refreshUser();
        appRoot.setUser(newUser);
        if (inDialog && passback) {
          fade('out');
          await new Promise(resolve => setTimeout(resolve, 400));
          passback({complete: true, message: ''});
        } else {
          fade('out', appRoot.redirect ? appRoot.redirect : '/app');
        }
      })
      .catch( async (error) => {
        appRoot.setUser('');
        if (error.response.data.backToLogin) {
          fade('out');
          setSubmitting(false);
          await new Promise(resolve => setTimeout(resolve, 400));
          resetForm(error.response.data.message);
        } else if (error.response.data.nameNeeded) {
          fade('out');
          setSubmitting(false);
          await new Promise(resolve => setTimeout(resolve, 400));
          setNameNeeded(true);
          fade('in', null, true);
        } else if (error.response.data.message) {
          setSubmitting(false);
          setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
        }
      });
    }
  }

  const sendNewCode = async (e) => {
    e.preventDefault();
    if (contactData) {
      const postUrl = process.env.REACT_APP_API_URL + '/login/code'
      const bodyData = {
        type: type ? type : contactData && !contactData.includes('@') ? 'cell' : 'email',
        userId: userId,
        contactData: !contactData ? null : contactData.includes('@') ? contactData.toLowerCase() : parsePhoneNumber(contactData, {regionCode: baseCountry}).getNumber(),
        notification: 'contactVerification'
      };
      await axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': localCsrfToken}})
      .then( (response) => {
        if (response.data.message) {
          setAlert({display: true, type: 'success', title: 'Code Sent!', message: response.data.message});
        }
      })
      .catch(function (error) {
        if (error.response.data.message) {
          setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
        }
      });
    }
  }

  if (nameNeeded) {
    return (
      <Container maxWidth="xs" align="center">
        {!inDialog ? <Helmet><title>Verify Account - Meetify</title></Helmet> : null}    
        <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
          <Box>
            <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}} id="pageTitle">
                Welcome to Meetify!
              </Typography>
            </Fade>
          </Box>
        </Slide>
        <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4} mb={2}>
          <Typography >
            Enter your name to proceed.
          </Typography>
        </Box>
      </Fade>
      <Box>
        <Contact showLocation={false} hideEmail={true} formData={formData} passback={updateData} fadeTime={[fadeTime[4],fadeTime[4],fadeTime[4],fadeTime[4]]} noAutoComplete={true} />
      </Box>

      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Stack mt={4} spacing={2} direction="row" justifyContent="center">
          <Button id="submitButton" type="button" variant="contained" color="primary" size="large" onClick={submitCode} disabled={!formData.firstName.value || !formData.lastName.value}>
            {!submitting ? 'Submit' : <CircularProgress size={24} color="inherit" />} 
          </Button>
        </Stack>
      </Fade>
      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4}>
          <Link component="button" variant="body2" onClick={linkLogin} color="textSecondary">Back to login</Link>
        </Box>
      </Fade>

      </Container>
    )


  } else {

   return (
    <Container maxWidth="xs" align="center">
      {!inDialog ? <Helmet><title>Verify Account - Meetify</title></Helmet> : null}    
      <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
        <Box>
          <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}} id="pageTitle">
                {verifyTitle}
            </Typography>
          </Fade>
        </Box>
      </Slide>
      <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box><AlertMessage display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>
      </Fade>
      <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4}>
          <Typography >
              {verifyMessage}
          </Typography>
        </Box>
      </Fade>
      <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box>
          <TextField id="code" label="verification code" variant="outlined" margin="normal" fullWidth required={true}
              maxLength={6} onChange={ (e) => {setCodeField(e.target.value)} } />
        </Box>
      </Fade>
      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Stack mt={4} spacing={2} direction="row" justifyContent="center">
          {inDialog && passback ? 
          <Button id="cancelButton" type="button" variant="outlined" color="primary" size="large" onClick={ () => passback({cancel: true}) }>
            Cancel 
          </Button>
          : null}
          <Button id="submitButton" type="button" variant="contained" color="primary" size="large" onClick={submitCode} disabled={!codeField}>
            {!submitting ? 'Submit' : <CircularProgress size={26} color="inherit" sx={{mr: 1.6, ml: 1.6}} />} 
          </Button>
        </Stack>
      </Fade>
      <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={5}>
          {!loggedIn ?
            <Typography variant="body2" color="textSecondary">Problems? <Link component="button" variant="body2" onClick={sendNewCode} color="textSecondary" sx={{pb: 0.25}} id="newCode">Get a new code</Link> or go <Link component="button" variant="body2" onClick={linkLogin} color="textSecondary" id="backToLogin" sx={{pb: 0.25}}>back to login</Link></Typography>
            :
            <Stack spacing={0.75} direction="row" justifyContent="center"><Typography variant="body2" color="textSecondary">Problems?</Typography><Link component="button" variant="body2" onClick={sendNewCode} color="textSecondary" id="newCode">Get new code</Link></Stack>
          }
        </Box>
      </Fade>
    </Container>
  );

  }

}

export default VerifyForm;