import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid, Fade, Typography } from '@mui/material';
import validate from '../../services/validationService';
import parsePhoneNumber from 'awesome-phonenumber';
import AutoComplete from './autoComplete'

const defaultFormData = {firstName: {value: '', error: false, errorText: ''}, lastName: {value: '', error: false, errorText: ''}, contact: {value: '', error: false, errorText: '', required: true, label: 'email or cell phone'}, location: {value: '', field: '', error: false, errorText: '', required: true, label: 'address or general area'}};

const Contact = ({row=0, showLocation=true, baseCountry='USA', baseLocation={lat: 35.213359, lng: -80.826202}, fadeTime=[true, true, true, true], noFade, csrfToken='', hideEmail=false, formData=defaultFormData, passback, allowCell, noAutoComplete}) => {

  // Local variable holds the values, so that this works without a passback if needed
  const [localRow, setLocalRow] = useState(row);
  const [localFormData, setLocalFormData] = useState(formData);

  const validateFirstname = (e) => {
    const response = validate.check(e.target.value, ['required']);
    setLocalFormData({...localFormData, firstName: {...localFormData.firstName, value: e.target.value, error: !response.success, errorText: response.message}});
    if (passback) passback( {row: row, firstName: {...localFormData.firstName, value: e.target.value, error: !response.success, errorText: response.message}});
  }

  const validateLastname = (e) => {
    const response = validate.check(e.target.value, ['required']);
    setLocalFormData({...localFormData, lastName: {...localFormData.lastName, value: e.target.value, error: !response.success, errorText: response.message}});
    if (passback) passback({row: row, lastName: {...localFormData.lastName, value: e.target.value, error: !response.success, errorText: response.message}});
  }

  const validateContact = (e) => {
    if (!localFormData.contact.required && !e.target.value) {
      setLocalFormData({...localFormData, contact: {...localFormData.contact, value: e.target.value, error: false, errorText: ''}})
      if (passback) passback({row: row, contact: {...localFormData.contact, value: e.target.value, error: false, errorText: ''}} );
    } else if (allowCell && !e.target.value.includes('@')) {
      const checkPhone = parsePhoneNumber(e.target.value, {regionCode: baseCountry});
      setLocalFormData({...localFormData, contact: {...localFormData.contact, value: e.target.value, error: !checkPhone.isValid(), errorText: checkPhone.isValid() ? '' : `Must be a valid email or phone.`}})
      if (passback) passback({row: row, contact: {...localFormData.contact, value: e.target.value, error: !checkPhone.isValid(), errorText: checkPhone.isValid() ? '' : `Must be a valid email or phone.`}});
    } else {
      const response = validate.check(e.target.value, ['required', 'email']);
      const errMess = allowCell ? `Must be a valid email or phone.` : `Must be a valid email.`;
      setLocalFormData({...localFormData, contact: {...localFormData.contact, value: e.target.value, error: !response.success, errorText: !response.success ? errMess : ''}})
      if (passback) passback( {row: row, contact: {...localFormData.contact, value: e.target.value, error: !response.success, errorText: !response.success ? errMess : ''}});
    }
  }

  const updateLocation = (args) => {
    setLocalFormData({...localFormData, location: {...localFormData.location, ...args}});
    if (passback) passback({row: row, location: {...localFormData.location, ...args}});
  }

  useEffect( () => {
    // if a new row comes in, update everything
    if (row !== localRow) {
      setLocalRow(row);
      setLocalFormData(formData);
    }
  }, [row, formData, localRow])

  // First time through, update the phone format if needed
  useEffect( () => {
    setLocalFormData( e => {
      const newRow = {...e}
      if (newRow.contact.value && !newRow.contact.value.includes('@') && parsePhoneNumber(newRow.contact.value, {regionCode: baseCountry}).isValid() ) {
        newRow.contact = {...newRow.contact, value: parsePhoneNumber(newRow.contact.value, {regionCode: baseCountry}).getNumber('national')}
      }
      return newRow;
    })
  }, [baseCountry])

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container item xs={6}>    
          <Fade in={fadeTime[0]} timeout={noFade ? 0 : Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <TextField id="firstName" label={localFormData.firstName.label ? localFormData.firstName.label : 'first name'} variant="outlined" margin="normal" fullWidth required value={localFormData.firstName.value}
            onChange={validateFirstname} error={localFormData.firstName.error} helperText={localFormData.firstName.errorText} maxLength={50} />
          </Fade>
        </Grid>
        <Grid container item xs={6}>
          <Fade in={fadeTime[1]} timeout={noFade ? 0 : Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <TextField id="lastName" label={localFormData.lastName.label ? localFormData.lastName.label : 'last name'} variant="outlined" margin="normal" fullWidth required value={localFormData.lastName.value}
            onChange={validateLastname} error={localFormData.lastName.error} helperText={localFormData.lastName.errorText} maxLength={100} />
          </Fade>
        </Grid>
      </Grid>
      {!hideEmail ? 
      <Box>
        <Fade in={fadeTime[2]} timeout={noFade ? 0 : Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
          <TextField id="contact" label={localFormData.contact.label ? localFormData.contact.label : 'email or cell phone'} variant="outlined" 
          margin="normal" fullWidth required={localFormData.contact.required} value={localFormData.contact.value}
          autoComplete={noAutoComplete ? 'off' : 'yes'}
          onChange={validateContact} error={localFormData.contact.error} helperText={localFormData.contact.errorText} disabled={localFormData.contact.disabled} maxLength={150} />
        </Fade>
      </Box>
      : showLocation ? 
          <Typography variant="body1" mt={3}>Where will you be coming from?</Typography>
      : null}
      {showLocation ?
      <Box>
        <Fade in={fadeTime[3]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
          <Box>
           <AutoComplete row={localRow} csrfToken={csrfToken}
            label={localFormData.location.label} location={localFormData.location} passback={updateLocation}
            baseLocation={baseLocation} baseCountry={baseCountry} />
          </Box>
        </Fade>
      </Box>
      : null }
    </Box>
  );
}

export default Contact;

