import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Fade, Slide, Container, Box, Link, TextField, Button, Typography, InputAdornment, IconButton, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import validate from '../../../services/validationService';
import AlertMessage from '../../library/alertMessage';
import Contact from '../../library/contact';
import VerifyForm from './verifyForm'
import { customFade, getCsrfToken } from '../../../services/guiService';
import { getNested } from '../../../services/utilityService';
import {Helmet} from "react-helmet-async";
import axios from 'axios';

const SignupForm = (props) => {

  const appRoot = props.appRoot;

  const defaultObj = {firstName: {value: props.planner && props.planner.firstName ? props.planner.firstName : '', error: false, errorText: ''}, 
                    lastName: {value: props.planner && props.planner.lastName ? props.planner.lastName : '', error: false, errorText: ''}, 
                    contact: {value: props.planner && props.planner.contact ? props.planner.contact : '', error: false, errorText: '', required: true, label: 'email'},
                    password: {value: '', error: false, errorText: '', visible: false, required: true}}

  const [inDialog] = useState(props.inDialog);
  const [csrfToken, setCsrfToken] = useState('');
  const [formData, setFormData] = useState({...defaultObj});
  const [formDisabled, setFormDisabled] = useState(true);
  const [alert, setAlert] = useState({display: false, title: '', message: '', type: 'alert'});
  const [verifyData, setVerifyData] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [submitting, setSubmitting] = useState(false);
  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

// Fade and slide animations
const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[navigate, fadeTime.length, props.appRoot.setFadeFooter]); 
  // Get csrfToken
  useEffect( () => { 
    if (!csrfToken && loadingState === 'loading') {
      setLoadingState('step1');
      getCsrfToken( setCsrfToken );
    } 
    if (csrfToken && loadingState === 'step1') {
      setLoadingState('complete');
      props.appRoot.logout(csrfToken);
      fade('in', null, true);;
    }
  }, [csrfToken, fade, loadingState, props.appRoot]);

  useEffect( () => {
    if ( (formData.firstName.value || (props.planner && props.planner.firstName)) && 
        (formData.lastName.value || (props.planner && props.planner.lastName)) &&
        (formData.contact.value || (props.planner && props.planner.contact)) && formData.password.value
        && !formData.firstName.error && !formData.lastName.error && !formData.contact.error && !formData.password.error) {
      setFormDisabled(false); 
    } else {
      setFormDisabled(true);
    }
  }, [props, formData.firstName.value, formData.lastName.value, formData.contact.value, formData.password.value, formData.firstName.error, formData.lastName.error, formData.contact.error, formData.password.error])

  const updateData = (args) => {
    setFormData({...formData, ...args});
  }

  const linkLogin = async (e) => {
    e.preventDefault();
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      props.passback({display: 'login'})
    } else {
      fade('out','/login');
    }
  }

  const handleClickShowPassword = (e) => {
    setFormData({...formData, password: {...formData.password, visible: !formData.password.visible}});
  };
  
  const validatePassword = (e) => {
    const response = validate.check(e.target.value, ['required', 'minLength_8', 'password']);
    setFormData({...formData, password: {...formData.password, value: e.target.value, error: !response.success, errorText: response.message}});
  }
  
  const resetLogin = (message) => {
    setVerifyData('');
    fade('in', null, true);
    if (message) {
      setAlert({display: true, title: 'Oops!', message: message, type: 'error'});
    }
  }

  const submitForm = (resend) => {
    let count = 0;
    let stopNow = false;
    getCsrfToken( setCsrfToken ).then( (localToken) => {
      // This is a safety. If the CSRFToken fails, then we're going to try again (up to 3 times) to avoid issues
      if (resend && resend.count && resend.count > 3) { 
        setAlert({display: true, message: resend.error.response.data.message, type: 'error'});
        window.scrollTo(0, 0);
        stopNow = true;
      } else if (resend) {
        count = resend.count + 1;
      }
      if (!stopNow) {
        setSubmitting(true);
        const postUrl = process.env.REACT_APP_API_URL + '/login/new'
        axios.post(postUrl, {
          firstName: props.planner && props.planner.firstName ? props.planner.firstName : formData.firstName.value,
          lastName: props.planner && props.planner.lastName ? props.planner.lastName : formData.lastName.value,
          contact:  formData.contact.value,
          password: formData.password.value,
          timezone: props.planner && props.planner.timezone ? props.planner.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
          }, {withCredentials: true, headers: {'CSRF-Token': localToken}})
        .then( async (response) => {
          fade('out');
          await new Promise(resolve => setTimeout(resolve, 400));
          setSubmitting(false);
          setVerifyData(formData.contact.value);
        })
        .catch( (error) => {
          // if there is authentication problems, try again.
          if (getNested(error, 'response.data.message') && error.response.data.message.includes('authenticating')) {
            submitForm({count: count, error: error});
          } else if (error.response.data.errors) {
            for (let i=0; i < error.response.data.errors.length; i++) {
              let row = error.response.data.errors[i]; 
              switch(row.type) {
                case 'first_name': setFormData({...formData, firstName: {...formData.firstName, error: true, errorText: row.message}}); break;
                case 'last_name': setFormData({...formData, lastName: {...formData.lastName, error: true, errorText: row.message}}); break;
                case 'email': setFormData({...formData, contact: {...formData.contact, error: true, errorText: row.message}}); break;
                case 'password': setFormData({...formData, password: {...formData.password, error: true, errorText: row.message}}); break;
                default: 
              }
            }
            setSubmitting(false);
            setAlert({...alert, display: true, message: 'You have not completed all the fields correctly. Please check the error explanations below.'})
          }
        });
      }
    });
  }
  
  const renderResults = () => {
    if (verifyData) {
      return (
        <VerifyForm appRoot={appRoot} csrfToken={csrfToken} contactData={verifyData} resetLogin={linkLogin} resetForm={resetLogin} inDialog={props.inDialog} passback={props.passback}
        verifyTitle={'Verify email'} verifyMessage={'A code has been sent to your email. Enter below.'}  />
      )
    } else {
      return (
        <Container maxWidth="xs" align="center">
          {!inDialog ? <Helmet><title>Sign Up - Meetify</title></Helmet> : null}    
          <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
            <Box>
              <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
                  Sign Up
                </Typography>
              </Fade>
            </Box>
          </Slide>
          <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Box><AlertMessage display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>
          </Fade>
          <form noValidate autoComplete="off">
          {props.planner ?
            <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Box mt={4}>
                <Typography variant="body1">Before you can proceed, please create an account. You'll use this to edit or check the status of your invite.</Typography>
              </Box>
            </Fade>
          : null }
            <Box mt={2}>
              <Contact showLocation={false} allowCell={false} row="0" formData={formData} passback={updateData} fadeTime={[fadeTime[4],fadeTime[5],fadeTime[6],fadeTime[7]]} noAutoComplete={true} />
            </Box>
          
          <Fade in={fadeTime[7]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Box>
              <TextField id="password" label="password" type={formData.password.visible ? 'text' : 'password'} variant="outlined" margin="normal" fullWidth required={formData.password.required}
                error={formData.password.error} helperText={formData.password.errorText}
                autoComplete='new-password'
                onChange={validatePassword} maxLength={50}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={ (e) => e.preventDefault() }
                      edge="end"
                      size="large">
                    {formData.password.visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Box>
          </Fade>
          <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Stack mt={4} spacing={2} direction="row">
              {inDialog && props.passback ? 
              <Button type="button" variant="outlined" color="primary" fullWidth onClick={ () => props.passback({cancel: true}) }>
                Cancel 
              </Button>
              : null}
              <Button type="button" variant="contained" color="primary" fullWidth onClick={submitForm} disabled={formDisabled}>
                {submitting ? <CircularProgress size={24} color="inherit" />  : 'Sign Up'} 
              </Button>
            </Stack>
          </Fade>
          <Fade in={fadeTime[10]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
            <Box mt={4} align="center">
              <Link component="button" variant="body2" onClick={linkLogin}>
                Already a member? Login
              </Link>
            </Box>
          </Fade>
          </form>
        </Container>
      );
    }
  }

  return renderResults();

}

export default SignupForm;