import React, { useState, Suspense, lazy } from 'react';
import {Routes, Route, useLocation, useSearchParams, useParams, Navigate } from 'react-router-dom';
import { Box, DialogContent, Stack } from '@mui/material';
// Load immediately
import Footer from '../nav/footerApp';
import Dialog from '../library/customDialog';
import LoadingIcon from '../library/loadingIcon';
import axios from 'axios';

const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        } else {
          // Send error information to support before rejecting
          try {
            const errorData = {
              firstName: 'System',
              lastName: 'Error',
              contact: 'support@meetify.com',
              message: `Lazy loading error:\nPath: ${window.location.pathname}\nUser: ${JSON.stringify(sessionStorage.getItem('user'))}\nFailed Component: ${storageKey}\nError: ${error.message || error}`
            };
            axios.post(`${process.env.REACT_APP_API_URL}/app/support`, errorData, {withCredentials: true});
          } catch (err) {
            // ignore any errors from sending the support message
          }
          reject(error); // Default error behaviour as already tried refresh
        }
      });
  });
// load when used
const LoginDialog = lazy(() => lazyRetry(() => import('./login/loginDialog')));
const Dash = lazy(() => lazyRetry(() => import('./dashboard/app')));
const Meetups = lazy(() => lazyRetry(() => import('./meetUp/app')));
const Settings = lazy(() => lazyRetry(() => import('./settings/app')));
const NotFound = lazy(() => lazyRetry(() => import('../main/404')));
const Invite = lazy(() => lazyRetry(() => import('./invite/app')));
const Login = lazy(() => lazyRetry(() => import('./login/app'))); 

function SecureApp(props) {

  const [searchParams] = useSearchParams();

  // Redirects for meetups
  const FinalRedirect = (props) => {
    const { urlId, responseId, responseType } = useParams();
    return <Navigate to={'/a/' + urlId + '/final'} state={{ responseId: responseId, responseType: responseType }} />;
  }
  const StatusRedirect = (props) => {
    const { urlId, responseId } = useParams();
    return <Navigate  to={'/a/' + urlId + '/final'} state={{ responseId: responseId, back: searchParams.get('back'), calOpen: searchParams.get('calOpen') }} />;
  }
  const RescheduleRedirect = (props) => {
    const { urlId, responseId } = useParams();
    return <Navigate  to={`/a/${urlId}/final`} state={{ responseId: responseId, reschedule: true, back: searchParams.get('back'), calOpen: searchParams.get('calOpen') }} />;
  }
  const PlannerRedirect = (props) => {
    const { urlId, plannerId } = useParams();
    return <Navigate  to={'/a/' + urlId + '/final'} state={{ plannerId: plannerId, back: searchParams.get('back'), calOpen: searchParams.get('calOpen') }} />;
  }
  const UserRedirect = (props) => {
    const { urlId, inviteeId } = useParams();
    return <Navigate to={'/a/' + urlId} state={{ inviteeId: inviteeId, back: searchParams.get('back'), calOpen: searchParams.get('calOpen') }} />;
  }

  // state
  const location = useLocation();
  const [open, setOpen] = useState(false);

  // Pop the login dialog
  const popLogin = () => {
    setOpen(true);
  }

  const handleLogin = (obj) => {
    if (obj.user) appRoot.setUser(obj.user)
    if (obj.complete || obj.cancel) setOpen(false);
  }

  // Additional items needed for everything below this point
  // We reset the pathname because the new Router knows the pathname before the wrapper router
  const appRoot = {...props.appRoot, popLogin: popLogin,pathname: location.pathname, query: location.search, state: location.state};

  return (
    <Stack align="center" sx={{minHeight: 'calc(100vh - 64px)', display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{width: '100%'}}>
        <Suspense fallback={<LoadingIcon />}>
          <Routes>
              {/* Login */}
              <Route exact path="/login/*" element={<Login appRoot={appRoot} />} />
              {/* Invite */}
              <Route path="/invite/*" element={<Invite appRoot={appRoot} />} />
              {/* Dashboard & Settings */}
              <Route path="/app/settings/*" element={<Settings appRoot={appRoot} />} />
              <Route path="/app/*" element={<Dash appRoot={appRoot} />} />
              {/* Meetups */}
              <Route path='/a/:urlId/l/:responseId' exact element={<StatusRedirect />} />
              <Route path='/a/:urlId/l2/:responseId' exact element={<RescheduleRedirect />} />
              <Route path='/a/:urlId/l3/:plannerId' exact element={<PlannerRedirect />} />
              <Route path='/a/:urlId/final/:responseId/:responseType' exact element={<FinalRedirect />} />
              <Route path='/a/:urlId/final/:responseId' exact element={<FinalRedirect />} />
              {/* Not the best way to do this, but the invitee variable is not consistent with the way the rest of the routes are setup */}
              {appRoot.pathname.split('/').length === 4 && appRoot.pathname.split('/')[3] !== 'final' ? <Route path="/a/:urlId/:inviteeId" element={<UserRedirect />} /> : null}
              <Route path="/a/*" element={<Meetups appRoot={appRoot} />} />
              {/* Meetups */}
              <Route path="/:url" exact element={<Meetups appRoot={appRoot} />} />
              {/* 404 */}
              <Route path="*" element={<NotFound appRoot={appRoot} />} />
          </Routes>
        </Suspense>
      </Box>
      <Dialog open={open} onClose={ () => { setOpen(false); }} fullWidth>
        <DialogContent sx={{minHeight: {xs: 450, sm: 475}}}>
          {open ? <LoginDialog appRoot={appRoot} passback={handleLogin} alert={{display: true, type:"warning", title: '', message: 'Your login session has expired. Please login again to continue.'}} /> : null}
        </DialogContent>
      </Dialog>
      <Box sx={{width: '100%'}}>
        <Footer appRoot={appRoot} pathname={location.pathname} />
      </Box>
    </Stack>
  );

}

export default SecureApp;
