import React, {useEffect, useState, useRef, useCallback} from 'react';
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import { Container, Box, Typography, Button, Stack, Grid, Fade, Link } from '@mui/material';
import { CheckCircle }  from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel'
import MetaTags from '../library/metaTags';
import LoadingIcon from '../library/loadingIcon';
import PublicFooter from '../nav/footerPublic';
import { customFade, standardSize, getCsrfToken } from '../../services/guiService';
// cms
import { client } from './prismic'
import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { useSinglePrismicDocument } from '@prismicio/react';
import { linkResolver } from './slices/slices';
// images
import logo from '../../images/nav/Meetify-logo-200.png';
import diag from '../../images/pricing/blue-abstract.jpg';
import coffeeCups from '../../images/home/coffee-cups4d.jpg';
import testimonials from '../../images/home/testimonial-bground2.jpg';
import coffeeMeetup from '../../images/home/coffee-meetup.png'
import thumbnail from '../../images/home/video-thumb2.jpg';
import meetingSchedulingSoftware from '../../images/home/meeting-scheduling-software2.png';
import feature1 from '../../images/home/feature1.png';
import feature2 from '../../images/home/feature2.png';
import feature3 from '../../images/home/feature3.png';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import Contact from '../library/contact';
import axios from 'axios';

const defaultDefault = {firstName: {value:'', error: false, errorText: '', required: true}, 
                    lastName: {value:'', error: false, errorText: '', required: true}, 
                    contact: {value:'', error: false, errorText: '', required: true, label: 'email'}};

const Home = (props) => {

  const appRoot = props.appRoot;
  const [loadingState, setLoadingState] = useState('loading');
  const [width, setWidth] = useState(0);
  const [width2, setWidth2] = useState(0);
  const [width3, setWidth3] = useState(0);
  const [height, setHeight] = useState(0);
  const [height2, setHeight2] = useState(0);
  const [height3, setHeight3] = useState(0);
  const [width4, setWidth4] = useState(0);
  const [height4, setHeight4] = useState(0);
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false]);
  const [document] = useSinglePrismicDocument('home_page');
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(defaultDefault)));
  const [newsletter, setNewsletter] = useState(false);
  const measureRef = useRef(null);
  const meetupRef = useRef(null);
  const imageRef = useRef(null);
  const navigate = useNavigate();

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, null, direction, redirect, null, includeSlide, appRoot.setFadeFooter) },[appRoot.setFadeFooter, navigate, fadeTime.length]);

  // update local from the form passback
  const updateData = (newData) => {
    setFormData({...formData, ...newData})
  }

  // submit the message
  const submitMessage = () => { 
    getCsrfToken().then(csrfToken => {
      const data = {firstName: formData.firstName.value, lastName: formData.lastName.value, contact: formData.contact.value, message: 'Add to newsletter'};
      const postUrl = process.env.REACT_APP_API_URL + `/app/support`;
      axios({method: 'POST', url: postUrl, data: data, withCredentials: true, headers: {'CSRF-Token': csrfToken}})
      .then( (response) => {
        setNewsletter({success: true, message: `We've added your email to our newsletter!`});
      })
      .catch( (error) => {
        console.log(error)
        setNewsletter({success: false, message: `There was a problem. Please check all fields and try again.`});
      });
    })
    .catch( (error) => {
      setNewsletter({success: false, message: `here was a problem. Please check all fields and try again.`});
    });
  }

  // determine the height and width of the responsive images
  const resizeImg = useCallback( () => {
    let responsiveSize;
    if (measureRef.current) {
      const width1 = measureRef.current.getBoundingClientRect().width;
      if (width1 === 10) responsiveSize = 'xs';
      if (width1 === 20) responsiveSize = 'sm';
      if (width1 === 30) responsiveSize = 'md';
      if (width1 === 40) responsiveSize = 'lg';
      if (width1 === 50) responsiveSize = 'xl';
    }
    const windowSize = responsiveSize ? responsiveSize : standardSize();
    if (imageRef.current) {
      const widthB = imageRef.current.getBoundingClientRect().width;
      const heightB = 384*widthB/503
      setWidth(Math.floor(widthB));
      setHeight(Math.floor(heightB))
    };
    if (meetupRef.current) {
      const width2 = meetupRef.current.getBoundingClientRect().width > 600 ? 600 : meetupRef.current.getBoundingClientRect().width;
      const height2 = 451*width2/600
      setWidth2(Math.floor(width2));
      setHeight2(Math.floor(height2));
    }
    let widthC = 590;
    if (windowSize === 'xs') widthC = 320;
    if (windowSize === 'sm') widthC = 475;
    if (windowSize === 'md') widthC = 475;
    const heightC = 506*widthC/590
    setWidth3(Math.floor(widthC));
    setHeight3(Math.floor(heightC));
    let widthD = 1000;
    if (windowSize === 'xs') widthD = 320;
    if (windowSize === 'sm') widthD = 525;
    if (windowSize === 'md') widthD = 800;
    const heightD = 9.02*widthD/16
    setWidth4(Math.floor(widthD));
    setHeight4(Math.floor(heightD));
  }, [imageRef, meetupRef]);

  // Initial load
  useEffect( ()=> {
    if(loadingState !== 'complete') {
      setLoadingState('complete');
      fade('in');
    }
    resizeImg();
    return () => {
      setFadeTime([true, true, true, true, true, true, true, true, true]); 
    };
  }, [loadingState, fade, resizeImg]);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener("resize", resizeImg);
    return () => window.removeEventListener("resize", resizeImg);
  }, [resizeImg]);

  // preRenderReady
  useEffect( () => {
    const goRender = async () => {
      await new Promise(resolve => setTimeout(resolve, 200));
      window.prerenderReady = true;
    }
    if (!window.prerenderReady && fadeTime[fadeTime.length-1] === true) goRender();
  },[fadeTime]);


  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": 'Meetify: Free Online Scheduling Software for Finding the Best Time and Place',
    "description": 'Meetify makes scheduling in-person meetings simple and personal. Unlike generic booking tools like Calendly, Meetify helps you coordinate both the time and the place, whether you are meeting at a coffee shop, a restaurant, or virtually if needed. With personalized invites, a built-in restaurant search, and easy calendar integration, Meetify takes the hassle out of scheduling and keeps the focus on relationships. Invite someone today at Meetify.com!',
    "thumbnailUrl": `https://img.youtube.com/vi/NnSpslNNkaY/maxresdefault.jpg`,
    "uploadDate": '2025-02-11T12:00:00-05:00',
    "duration": "PT1M22S",
    "embedUrl": `https://www.youtube.com/embed/NnSpslNNkaY?start=0&hd=1&vq=hd1080p&rel=0&showinfo=0&autohide=1`,
    "contentUrl": `https://www.youtube.com/watch?v=NnSpslNNkaY?start=0&hd=1&vq=hd1080p&rel=0&showinfo=0&autohide=1`,
    "publisher": {
      "@type": "Organization",
      "name": "Meetify",
      "logo": {
        "@type": "ImageObject",
        "url": window.location.origin + logo,
        "width": 400,
        "height": 122
      }
    }
  };


  if (loadingState !== 'complete') {
    return (
      <LoadingIcon />
    )
  } else {
    return (
      <Box>
        
        <MetaTags content={document} youtube={schema} />
        
        {/* Header graphic */}
        <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{backgroundColor: '#ddebe7', backgroundImage: `url(${coffeeCups})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', boxShadow: '0px 5px 5px rgb(221,235,231,0.25)'}} align="center">
              <Grid container justifyContent="center" alignItems="center" sx={{maxWidth: 'lg'}}>
                <Grid item xs={12} md={5.5} sx={{height: {xs: '400px', sm: '400px', md: '445px', lg: '550px'}}}>
                    <Stack sx={{height: '100%', alignItems: 'flex-start', justifyContent: 'center'}} spacing={1}>
                      <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                      <Box align="center" pb={2} sx={{width: '100%'}}>
                      <a href="https://www.producthunt.com/posts/meetify-2?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-meetify&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=910430&theme=neutral&period=daily&t=1742508111278" alt="Meetify - Find&#0032;a&#0032;time&#0032;and&#0032;place&#0032;to&#0032;meet&#0046;&#0032;Calendly&#0032;meets&#0032;Google&#0032;Maps&#0046; | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a>                        <Box pt={4} sx={{width: {xs: '80%', sm: '550px', md: '400px', lg: '500px'}}}><Typography variant={['md'].includes(appRoot.windowSize) ? 'h4' : 'h3'} color="header.main" component="h1" sx={{fontSize: ['md'].includes(appRoot.windowSize) ? '2.4em !important' : '3.0 em', fontWeight: 'bold', textShadow: `0px 0px 25px rgb(221,235,231,1.0)`}}>Meeting Scheduling for the Real World</Typography></Box> {/* Effortless Scheduling for <i>time</i> and <i>place</i> */}
                      </Box>
                      </Fade>
                      <Box align="center" sx={{width: '100%'}}>
                        <Box sx={{maxWidth: {xs: '80%', sm: '500px', md: '370px', lg: '500px'}}} align="center">
                          {/* FINALLY - Meeting Scheduling That Includes Location. Easily Schedule Virtual or In-Person Meetings with One-Time Invites or Ongoing Booking Links. */}
                          <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                            <Typography variant={appRoot.windowSize === 'xs' || appRoot.windowSize === 'md' ? 'body1' : 'h6'} color="primary.main" component="h3" pb={4} sx={{lineHeight: '2.0em', textShadow: `0px 0px 25px rgb(221,235,231,1.0)`, fontWeight: 'bold'}}>Schedule in-person meetings at coffee shops or restaurants with virtual when needed.</Typography>
                          </Fade>
                        </Box>
                        <Fade in={fadeTime[3]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                          <Box pb={{xs: 0, md: 1, lg: 2}}><Button variant="contained" color="primary" size="large" component={ReactRouterLink} to="/invite/what">Invite Someone</Button></Box>
                        </Fade> 
                      </Box>            
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6.5} pr={1} sx={{height: {xs: '275px', sm: '410px', md: '445px', lg: '550px'}, display: 'flex', alignItems: 'flex-end', justifyContent: {xs: 'center', lg: 'flex-end'}}}>
                  <img alt="Free online meeting scheduling software that includes location" src={meetingSchedulingSoftware} align="right" border="0" height={height3} width={width3} />
                </Grid>
              </Grid>
          </Box>
        </Fade>

        <a name="video"></a>
        <Box mt={12} align="center">

        {/* Key Features */}
        <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Container maxWidth="lg">
            <Typography component="h3" variant="h4" pb={2} sx={{fontWeight: 'bold'}}>Professional Power. 🚀  Personal Touch. ☕ </Typography>
            <Box sx={{width: {xs: '85%'}}} align="center" pb={4} >
              <Typography variant="h6" sx={{fontWeight: 'normal'}} color="text.main">
                Ditch the cold, corporate appointment links. Whether you’re scheduling a demo call or grabbing coffee with a friend, Meetify combines pro-level features with warm, personal vibes. Built for real-world relationships. 
              </Typography>
            </Box>
          </Container>
        </Fade>

        
        <Box ml={0} mr={0} mb={12} align="center" sx={{height: `${height4}px`, width: `${width4}px`, border: '1px solid #eeeeee'}}>
        <LiteYouTubeEmbed 
        id="NnSpslNNkaY"
        title="Meetify: Free Online Scheduling Software for Finding the Best Time and Place"
        thumbnail={thumbnail}
        params='start=0&hd=1&vq=hd1080p&rel=0&showinfo=0&autohide=1'
    />
        </Box>


        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Container sx={{width: appRoot.windowSize === 'sm' ? '75%' : '100%'}}>
          <Typography component="h3" variant="h4" pb={6} sx={{fontWeight: 'bold'}}>Built for Real World Meetings</Typography>
              <Grid container sx={{maxWidth:"lg"}}>
                <Grid item xs={12} md={4} sx={{mb: {xs: 5, md: 0}, pr: {xs: 0, md: 2}}}>
                  <Box sx={{backgroundColor: '#f6f6f6', borderRadius: 5, flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 'fix-content'}}>
                    <Box p={{xs: 3, sm: 4, md: 3, lg: 4}}>
                    <Typography mt={1} variant={appRoot.windowSize === 'sm' ? 'h4' : 'h5'} component="h3" sx={{fontWeight: 'bold'}} color="primary.main">In-person when possible, virtual when needed</Typography>
                    <Box sx={{mt: 3, mb: 4, p: 3, background: 'white',border: '1px solid rgba(109, 85, 85, 0.1)', boxShadow: '2px 2px 8px rgba(109, 85, 85, 0.2)', borderRadius: 5}} >
                    <img src={feature1} alt="Screenshot for location focused scheduling"  border="0" width={width} height={height} />
                    </Box>
                    <Box sx={{textAlign: 'left'}}><Typography variant="body1">Let invitees choose what works best—whether face-to-face or online. Offer time and place options and let them recommend alternatives if needed. Perfect when you’re unsure about dietary, budget, or location preferences.</Typography></Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{mr: {xs:0, md: 0}, mb: {xs: 5, md: 0}, pr: {xs: 0, md: 1}, pl:{xs: 0, md: 1}}}>
                  <Box sx={{backgroundColor: '#f6f6f6', borderRadius: 5, flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 'fix-content'}}>
                    <Box p={{xs: 3, sm: 4, md: 3, lg: 4}}>
                    <Typography mt={1} variant={appRoot.windowSize === 'sm' ? 'h4' : 'h5'} component="h3" sx={{fontWeight: 'bold'}} color="primary.main">Flexible one-time invites or schedule-based links</Typography>
                    <Box sx={{mt: 3, mb: 4, p: 3, background: 'white',border: '1px solid rgba(109, 85, 85, 0.1)', boxShadow: '2px 2px 8px rgba(109, 85, 85, 0.2)', borderRadius: 5}} >
                    <Box sx={{width: '100%'}} ref={imageRef}><img src={feature3} alt="Screenshot for booking appointment links"  border="0" width={width} height={height} /></Box>
                    </Box>
                    <Box sx={{textAlign: 'left'}}><Typography variant="body1">No need to overwhelm people with your entire schedule. Send a one-time invite with several handpicked options, or use a single link for ongoing bookings based on a custom schedule. Meetify adapts to your meeting type.</Typography></Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{pl: {xs:0, md: 2}}}>
                  <Box  sx={{backgroundColor: '#f6f6f6', borderRadius: 5, flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 'fix-content'}}>
                    <Box p={{xs: 3, sm: 4, md: 3, lg: 4}}>
                    <Typography mt={1} variant={appRoot.windowSize === 'sm' ? 'h4' : 'h5'} component="h3" sx={{fontWeight: 'bold'}} color="primary.main">Coordinate time/place for a group meetup as well</Typography>
                    <Box sx={{mt: 3, mb: 4, p: 3, background: 'white',border: '1px solid rgba(109, 85, 85, 0.1)', boxShadow: '2px 2px 8px rgba(109, 85, 85, 0.2)', borderRadius: 5}} >
                    <img src={feature2} alt="Screenshot for group meeting polls"  border="0" width={width} height={height} />
                    </Box>
                    <Box sx={{textAlign: 'left'}}><Typography variant="body1">Scheduling a group? Skip the endless chats. Invite everyone to mark their preferences. Review responses, finalize the plan, and notify the group in a click. Ideal for team meetings, casual dinners, or hosting friends at home. </Typography></Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              </Container>
            </Fade>


              
        </Box>
         <Box sx={{width: {xs: '10px', sm: '20px', md: '30px', lg: '40px', xl: '50px'}}} ref={measureRef}></Box>
        <Box mt={6} align="center"><Button onClick={() => navigate('/features')} variant="outlined">Learn about our features</Button></Box>

        







        <Box mt={12}>&nbsp;</Box>

        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box sx={{width: '100%', backgroundColor: '#112945', boxShadow: `0px 3px 12px 0px #c6e7e9`}}>
            <Box pt={4} pb={4} sx={{width: '100%', height: '100%', backgroundImage: `url(${diag})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
              <Stack sx={{height: '100%', textAlign: 'center'}} pr={3} pl={3} pt={8} pb={8} alignItems="center" justifyContent="center">
                <Typography variant="h6" component="h2" sx={{color: 'white', fontWeight: 'bold', lineHeight: '1.5em'}}>Does your scheduling software offer in-person options?</Typography>
                <br />
                <Box sx={{maxWidth: '920px'}} align="center"><Typography variant="h4" color="secondary" sx={{ fontWeight: 'bold', lineHeight: '1.5em'}}>8 out of 10 executives prefer face-to-face meetings. 85% say it builds stronger, more meaningful business relationships.</Typography> </Box>
                <Typography variant="body2" color="secondary" mt={4} sx={{fontWeight: 'bold'}}><Link href="https://images.forbes.com/forbesinsights/StudyPDFs/Business_Meetings_FaceToFace.pdf" target="_blank" color="secondary">Forbes Insights</Link></Typography>
              </Stack>
            </Box>
        </Box>         
      </Fade>   

      <Box mt={16}>&nbsp;</Box>

      {/* Testimonial Quotes */}
      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Container>
          <Box sx={{width: '100%', height: '350px', boxShadow: `0px 3px 12px 0px #c6e7e9`, borderRadius: '15px', backgroundImage: {xs: `url(${testimonials})`, sm: `url(${testimonials})`}, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <Box pt={10} pb={6} pl={3} pr={3}>
              <Box pt={2} pb={2} pl={{xs: 1, sm: 2}} pr={{xs: 1, sm: 2}} sx={{width: {xs: '95%', sm: '50%'}, backgroundColor: `white`, borderRadius: '8px', textAlign: 'center'}}>
                <Carousel interval="8000" indicatorIconButtonProps={{style: {margin: '5px'}}}>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“Love this…. Simplify my life! That is my mantra!”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Amy Henderson</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“I just used Meetify today to coordinate grabbing beers next week. Worked like a charm!”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Kevin Carney</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“As someone who meets in person all over the city, this is a definite pain point…”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Juan Garzón</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“It was easy to use and automated an otherwise crappy back-and-forth process.”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Matt DeWald</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“I'm going to use this at least once a week!”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Patrick McElhaney</Typography>
                  </Box>
                </Carousel>
              </Box>
            </Box>
          </Box>
        </Container>
        </Fade>
        <Fade in={fadeTime[7]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box mt={4} p={2} typography="h6" component="h5" align="center" sx={{color: 'headers.main'}}>See why everyone is talking about Meetify.&nbsp;&nbsp;&nbsp;<Button component={ReactRouterLink} to="/invite" variant="contained" color="secondary" size="large">Schedule Now</Button></Box>
        </Fade>

        <Box mt={6}>&nbsp;</Box>

        <Fade in={fadeTime[7]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box>
        <Box sx={{boxShadow: '0px 50px 75px 0px rgb(168,212,186,0.3)', height: '50px'}}>&nbsp;</Box>
        <Box pt={12} pb={2}>
          <Container>
          <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6} pr={{xs: 0, md: 8}}>
              <Box align="center" ref={meetupRef} sx={{width: '100%', flexGrow: 1}}>
                <Box width={{xs: '100%', sm: '70%', md: '100%'}} align="center" pb={{xs: 4, md: 8}}><Typography variant="h4" component="h4" color="headers.main" sx={{fontWeight: 'bold', fontSize: appRoot.windowSize === 'xl' ? '2.4em !important' : '2.0em'}}>Scheduling for Go-Getters and Relationship Builders</Typography></Box>
                <img alt="A couple meeting for coffee in a coffee shop" src={coffeeMeetup} width={width2} height={height2} border="0" />
              </Box>
              </Grid>
              <Grid item xs={12} md={6} pr={{xs:0, sm: 4}} pl={{xs: 0, sm:5}} pt={{xs: 2, md: 0}}>
                <Box sx={{width: '100%'}} align={['xs','sm'].includes(appRoot.windowSize) ? 'center' : 'left'}>
                
                <Box sx={{width: {xs: '100%', sm: '90%', md: '100%'}}} align="left">
                <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="flex-start" pt={4}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Nonprofit Leaders</Typography>
                    <Typography variant="body1" color='rgba(0,0,0,0.5)'>Schedule strategy sessions, volunteer check-ins, or one-on-one discussions with board members.</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" pt={2}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                    <Typography variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Salespeople & Recruiters</Typography>
                    <Typography  variant="body1" color='rgba(0,0,0,0.5)'>Meet prospects for coffee or conduct virtual interviews.</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" pt={2}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                    <Typography variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Faith Community Leaders</Typography>
                    <Typography  variant="body1" color='rgba(0,0,0,0.5)'>Arrange meetups with parishioners for guidance, mentorship, or planning community events.</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" pt={2}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Development Directors & Fundraisers</Typography>
                    <Typography  variant="body1" color='rgba(0,0,0,0.5)'>Easily set up donor meetings over coffee or lunch to build relationships and secure support.</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" pt={2}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                    <Typography variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Small Business Entrepreneurs</Typography>
                    <Typography  variant="body1" color='rgba(0,0,0,0.5)'>Schedule consultations, vendor meetings, or client discussions at convenient locations.</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" pt={2}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                  <Typography variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Tutors, Mentors, and Life Coaches</Typography>
                    <Typography  variant="body1" color='rgba(0,0,0,0.5)'>Meet clients for one-on-one guidance in comfortable, conversational settings.</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" pt={2}>
                <CheckCircle color="secondary" sx={{fontSize: '32px'}}/>
                  <Box>
                    <Typography  variant="h6" sx={{fontWeight: 'bold'}} color="text.main">Friends and Family</Typography>
                    <Typography variant="body1" color='rgba(0,0,0,0.5)'>Plan coffee chats, brunch dates, or a quick lunch to catch up.</Typography>
                  </Box>
                </Stack>
              </Box></Box>
            </Grid>
          </Grid>

          </Container>
        </Box>
        </Box>
        </Fade>

        <Box mt={10}>&nbsp;</Box>

          
      {/* About Us Promo */}
      <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box sx={{width: '100%', backgroundColor: '#edf5f6', boxShadow: `inset 0px 0px 100px 0px #c6e7e9`}} align="center" pt={12} pb={12}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} pl={2} pr={2}>
            <Stack alignItems="center" spacing={1}>
                <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Typography variant="h3" component="h3" sx={{fontWeight: 'bold', textShadow: `2px 2px 10px #f6f6f6`}}>
                    We know scheduling. You know us.
                  </Typography>
                </Fade>
                <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box typography="h6" component="h4" sx={{lineHeight: '2.0em', fontWeight: 'bold', maxWidth: 'md'}} align="center">
                    Meetify was founded by the innovative creators of SignUpGenius, the web's largest group scheduling platform. We're passionate about saving people time and getting them together. 
                  </Box>
                </Fade>
                <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box pt={2}><Button variant="contained" color="secondary" size="large" component={ReactRouterLink} to="/about">How it Started</Button></Box>
                </Fade>
              </Stack>
            </Box>
        </Box>
        </Fade>

        {/* Newsletter submissin */}
        <a name="newsletter"></a>
        <Box mt={12} align="center">
          <Container sx={{maxWidth: 'sm'}}>
          <Typography pb={1} variant="h5" color="primary.main" sx={{fontWeight: 'bold'}}>Get meeting tips & Meetify news!</Typography>
          {newsletter && !newsletter.success ? 
            <Typography variant="h6" color="error.main" sx={{fontWeight: 'bold'}}>{newsletter.message}</Typography>
          : 
            <Typography variant="h6" color="text.main" sx={{fontWeight: 'bold'}}>Sign up for our monthly newsletter:</Typography> 
          } 
          {!newsletter || !newsletter.success ? 
            <Box>
              <Contact formData={formData} passback={updateData} allowCell={false} showLocation={false} size="small" />
              <Box align="center" pt={2}><Button size="large" variant="contained" onClick={submitMessage}>Submit</Button></Box>
            </Box> 
          : 
            <Typography pt={2} variant="h6" sx={{fontWeight: 'normal'}} color={newsletter.success ? 'success.main' : 'error.main'}>{newsletter.message}</Typography>
          }
          </Container>
        </Box>


        <Box mt={4}>&nbsp;</Box>
        <PublicFooter appRoot={appRoot} />
      </Box>
    );
  } 
}


const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Home {...props} />
      {process.env.REACT_APP_ENV !== 'production' ? <PrismicToolbar repositoryName="meetify" /> : null}
    </PrismicProvider>
  )
}


export default Wrapper;