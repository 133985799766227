import React, {useState, useEffect}  from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import BigVertical1 from '../../images/ads/Coming-Soon-300x600-2.png'
import BigVertical2 from '../../images/ads/Coming-Soon2-300x600-2.png'
import ThinVertical1 from '../../images/ads/Coming-Soon-160x600.jpg'
import ThinVertical2 from '../../images/ads/Coming-Soon-160x600-2.jpg'
import Horizontal from '../../images/ads/Ad-728.jpg'
import Horizontal2 from '../../images/ads/Ad-468.jpg'
import Mobile from '../../images/ads/Ad-320.jpg'
import { initialSize } from '../../services/guiService';


const ResponsiveAd = (props) => {

  const [size, setSize] = useState(props.size ? props.size : props.appRoot ? props.appRoot.browserSize : initialSize());
  const [random] = useState(Math.floor(Math.random() * 2) +1  === 1);

  const cookieConsent = document.cookie.split('; ').filter(row => row.startsWith('cookieyes-consent=')).map(c=>c.split('=')[1])[0];
  const allowAds = cookieConsent && cookieConsent.includes('functional:yes');

  useEffect(() => {
    // We render the ad on a responsive resize
    if (size !== props.size) {
      if (size !== props.size) setSize(props.size);
    }
  }, [size, props.size])

  // If Google ads ---- SAVE:  process.env.REACT_APP_ADS === 'true'
  if (props.appRoot && allowAds) {
    // Responsive Horizontal
    if (props.slot === 'top') {
      let height = 50; let width = 320;
      if (size === 'sm2' || size === 'sm') { height = 60; width = 468; }
      if (size === 'sm1' || size === 'md') { height = 90; width = 728; }
      if (size === 'lg' || size === 'xl') { height = 90; width = 970; }
      return (
        <div id="div-gpt-ad-1672948777470-0" align="center" style={{midWidth: width, minHeight: height}} />
      )
    // Responsive Vertical
    } else if (props.slot === 'left') {
      return (
        <div id="div-gpt-ad-1672952665861-0" style={{minWidth: 160, minHeight: 600}} />
      )
    } else if (props.slot === 'right') {
      return (
        <div id="div-gpt-ad-1672952665861-2" style={{minWidth: 160, minHeight: 600}} />
      )
    }
  // If local placeholders (test)
  } else {
    if (props.slot === 'top') {
      // Mobile banner
      if (size && size === 'xs') {
        return <Box sx={{textAlign: 'center'}}><Link component={ReactRouterLink} to="/invite"><img src={Mobile} width="320" alt="Horizontal Ad" /></Link></Box>
      // Responsive banner for small size 2
      } else if (size && size === 'sm2') {
        return <Box sx={{textAlign: 'center'}}><Link component={ReactRouterLink} to="/invite"><img src={Horizontal2} width="468" alt="Horizontal Ad" /></Link></Box>
      // Responsive banner for small size 1
      } else {
        return <Box sx={{textAlign: 'center'}}><Link component={ReactRouterLink} to="/invite"><img src={Horizontal} width="728" alt="Horizontal Ad" /></Link></Box>
      // Hidden
      // } else {
      //   return null;
      }
    } else if (props.slot === 'left') {
      return (
        <Box sx={{textAlign: 'center'}}>
          {random ?
            <Link component={ReactRouterLink} to="/invite"><img src={ThinVertical1} width="160" alt="Vertical Ad" /></Link>
          :
            <Link component={ReactRouterLink} to="/invite"><img src={ThinVertical2} width="160" alt="Vertical Ad" /></Link>
          }
        </Box>
      )
    } else if (props.slot === 'right') {
      if (size === 'md') {
        return (
          <Box sx={{textAlign: 'center'}}>
            {random ?
              <Link component={ReactRouterLink} to="/invite"><img src={ThinVertical1} width="160" alt="Vertical Ad" /></Link>
            :
              <Link component={ReactRouterLink} to="/invite"><img src={ThinVertical2} width="160" alt="Vertical Ad" /></Link>
            }
          </Box>
        )
      } else {
        return (
          <Box>
            {random ?
              <Link component={ReactRouterLink} to="/invite"><img src={BigVertical1} width="300" alt="Vertical Ad" /></Link>
            :
              <Link component={ReactRouterLink} to="/invite"><img src={BigVertical2} width="300" alt="Vertical Ad" /></Link>
            }  
          </Box>
        )
      }
    }
  }
}



export { ResponsiveAd };