import React from 'react';
import { IconButton, Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import TextIcon from '@mui/icons-material/Textsms';
import TagManager from 'react-gtm-module';


const SocialShare = (props) => {

  const document = props.document;

  const getContent = (document) => {
    const results = {};
    if (document.data && document.type === 'resources') {
      results.url = process.env.REACT_APP_WEB_URL + '/resources';
    } else if (document.data && document.type === 'article' && document.tags.length) {
      results.url = process.env.REACT_APP_WEB_URL + '/resources/' + document.tags[0].toLowerCase() + '/' + document.uid; 
    }
    if (document.data && document.data.body && document.data.body[0] && document.data.body[0].primary) {
      results.title = document.data.body[0].primary.title[0].text;
      results.description = document.data.body[0].primary.description[0].text;
      results.image = document.data.body[0].primary.image && document.data.body[0].primary.image.url ? document.data.body[0].primary.image.url : null;
      return results;
    } else {
      return null;
    }
  }

  const dataLayerInfo = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayer: {
      event: 'social_share',
      shareSite: 'Facebook'
      }
    }

  const facebookClick = () => {
    if (process.env.REACT_APP_ENV === 'production') TagManager.dataLayer(dataLayerInfo);
    const content = getContent(document);
    window.open('https://facebook.com/sharer/sharer.php?u=' + encodeURI(content.url), '_blank', 'toolbar=0,location=0,menubar=0,height=500,width=500');
  }

  const twitterClick = () => {
    if (process.env.REACT_APP_ENV === 'production') TagManager.dataLayer({...dataLayerInfo, shareSite: 'Twitter'});
    const content = getContent(document);
    window.open('https://twitter.com/intent/tweet/?text=' + encodeURI(content.title) + '&url=' + encodeURI(content.url), '_blank', 'toolbar=0,location=0,menubar=0,height=500,width=500');
  }

  const pinterestClick = () => {
    if (process.env.REACT_APP_ENV === 'production') TagManager.dataLayer({...dataLayerInfo, shareSite: 'Pinterest'});
    const content = getContent(document);
    window.open('https://pinterest.com/pin/create/button/?url=' + encodeURI(content.url) + '&media=' + encodeURI(content.image) + '&description=' + encodeURI(content.title), '_blank', 'toolbar=0,location=0,menubar=0,height=500,width=500');
  }

  const linkedinClick = () => {
    if (process.env.REACT_APP_ENV === 'production') TagManager.dataLayer({...dataLayerInfo, shareSite: 'LinkedIn'});
    const content = getContent(document);
    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURI(content.url) + '&title=' + encodeURI(content.title) + '&summary=' + encodeURI(content.description) + '&source=' + encodeURI(process.env.REACT_APP_WEB_URL), '_blank', 'toolbar=0,location=0,menubar=0,height=500,width=500');
  }

  const emailClick = () => {
    if (process.env.REACT_APP_ENV === 'production') TagManager.dataLayer({...dataLayerInfo, shareSite: 'Email'});
    const content = getContent(document);
    window.open( 'mailto:?to=%20&body=' + encodeURI('I wanted to share this link: ') + encodeURI(content.url) + '&subject=' + encodeURI('Check this out'));
  }

  const textClick = () => {
    if (process.env.REACT_APP_ENV === 'production') TagManager.dataLayer({...dataLayerInfo, shareSite: 'Text'});
    const content = getContent(document);
    window.open( 'sms:%20&body=' + encodeURI('I wanted to share this link: ') + encodeURI(content.url));
  }


  if (document && document.data) {
    return (
      <Box sx={{textAlign: props.align ? props.align : 'center'}}>
        <IconButton aria-label="Facebook" onClick={facebookClick} rel="noopener noreferrer">
          <FacebookIcon id="facebook_share" sx={{color: '#4267B2', height: {xs: '16px', sm: '32px'}, width: {xs: '16px', sm: '32px'}}} />
        </IconButton>
        <IconButton color="primary"  aria-label="Twitter" onClick={twitterClick} rel="noopener noreferrer">
          <TwitterIcon id="twitter_share" size="large" sx={{color: '#1DA1F2', height: {xs: '16px', sm: '32px'}, width: {xs: '16px', sm: '32px'}}}  />
        </IconButton>
        <IconButton color="primary" aria-label="LinkedIn" onClick={linkedinClick} rel="noopener noreferrer">
          <LinkedInIcon  id="linkedin_share" size="large" sx={{color: '#0A66C2', height: {xs: '16px', sm: '32px'}, width: {xs: '16px', sm: '32px'}}} />
        </IconButton>
        <Box sx={{display: {xs: 'block', sm: 'none'}}}></Box>
        <IconButton color="primary" aria-label="Pinterest" onClick={pinterestClick} rel="noopener noreferrer">
          <PinterestIcon id="pinterest_share" size="large" sx={{color: '#E60023', height: {xs: '16px', sm: '32px'}, width: {xs: '16px', sm: '32px'}}} />
        </IconButton>
        <IconButton color="primary" aria-label="Email" onClick={emailClick} rel="noopener noreferrer">
          <EmailIcon id="email_share" size="large" sx={{color: 'gray', height: {xs: '16px', sm: '32px'}, width: {xs: '16px', sm: '32px'}}}  />
        </IconButton>
        <IconButton color="primary" aria-label="SMS" onClick={textClick} rel="noopener noreferrer" sx={{display: {xs: 'inline', sm: 'none'}}}>
          <TextIcon id="sms_share" size="large" sx={{color: '#5BC236', display: {xs: 'block', sm: 'none'}, height: {xs: '16px', sm: '32px'}, width: {xs: '16px', sm: '32px'}}}  />
        </IconButton>
      </Box>
    )
  } else {
    return null;
  }
}

export default SocialShare;

