import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Box, Link, TextField, Button, Divider, Typography, Checkbox, FormControlLabel, InputAdornment, IconButton, Stack, Slide, Fade } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import validate from '../../../services/validationService';
import AlertMessage from '../../library/alertMessage';
import VerifyForm from './verifyForm'
import RequireReset from './requireReset'
import { customFade, getCsrfToken, initialSize } from '../../../services/guiService'
import {Helmet} from "react-helmet-async";
import axios from 'axios';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const defaultObj = {contact: {value:'', error: false, errorText: '', required: true}, 
                    password: {value:'', error: false, errorText: '', visible: false, required: true},
                    rememberMe: {value: false}}

const LoginForm = (props) => {

  const componentRef = useRef()
  
  const appRoot = props.appRoot;
  const [inDialog] = useState(props.inDialog);
	const [verifyData, setVerifyData] = useState('');
  const [resetMe, setResetMe] = useState(false);
  const [resetMessage, setResetMessage] = useState('');
  const [resetAlert, setResetAlert] = useState('');
	const [csrfToken, setCsrfToken] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const [alert, setAlert] = useState({display: props.alert && props.alert.display ? props.alert.display : false, title: props.alert && props.alert.title ? props.alert.title : '', message: props.alert && props.alert.message ? props.alert.message : '', type: props.alert && props.alert.type ? props.alert.type : 'error'});
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [formData, setFormData] = useState(defaultObj);
  const [browserSize, setBrowserSize] = useState(initialSize());
  const [googleLoading, setGoogleLoading] = useState(1);
  const [appleUser, setAppleUser] = useState('');
  const [appleState, setAppleState] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [defaultTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [socialInProgress, setSocialInProgress] = useState(false);
  // const [facebookUser, setFacebookUser] = useState('');

  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

  // To come back to this page from the verify form, when not in a dialog
  const backToLogin = () => {
    setVerifyData('');
    setResetMe(false);
    setSubmitting(false);
    setResetAlert('')
    fade('in', null, true);
  }

  const passback = props.passback ? props.passback : backToLogin;

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[navigate, fadeTime.length, props.appRoot.setFadeFooter]); 
	
  // Get csrfToken
  useEffect( () => {
    if (!csrfToken && loadingState === 'loading') {
      setLoadingState('step1');
      getCsrfToken( setCsrfToken );
    } 
    if (csrfToken && loadingState === 'step1') {
      setLoadingState('complete');
      props.appRoot.logout(csrfToken);
      fade('in', null, true);
    }
  }, [csrfToken, fade, loadingState, props.appRoot]);
	
  // Forgot password link is link or modal change
  const linkPassword = async (e) => {
    e.preventDefault();
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 500));
      props.passback({display: 'forgot'})
    } else {
      fade('out','/login/forgot');
    }
  }

  // Register link is link or modal change
  const linkSignup = async (e) => {
    e.preventDefault();
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 500));
      props.passback({display: 'new'})
    } else {
      fade('out','/login/new');
    }
  }

	const rememberToggle = (e) => {
		setFormData({...formData, rememberMe: {...formData.rememberMe, value: !formData.rememberMe.value}});
	}
	
	const handleClickShowPassword = (e) => {
    setFormData({...formData, password: {...formData.password, visible: !formData.password.visible}});
	};
	
	const handleMouseDownPassword = (e) => {
		e.preventDefault();
	};
	
  const validateContact = (e) => {
    const response = validate.check(e.target.value, ['required', 'email']);
    setFormData({...formData, contact: {...formData.contact, value: e.target.value, error: !response.success, errorText: !response.success ? `Must be a valid email.` : ''}})
  }
	
	const validatePassword = (e) => {
    const response = validate.check(e.target.value, ['required', 'minLength_8', 'password']);
    setFormData({...formData, password: {...formData.password, value: e.target.value, error: !response.success, errorText: response.message}});
	}
	
	const resetLogin = (message) => {
		setVerifyData('');
    fade('in', null, true);
		if(message) {
      setAlert({display: true, type: 'error', title: 'Oops!', message: message});
		}
	}

  // Sumbitting the password reset if users are required to reset their password at login
  const submitReset = (newPassword) => {
    if (!newPassword || !formData.password.value) {
      setResetAlert({display: true, type: 'error', title: 'Oops!', message: 'You must enter a new password.'});
      setSubmitting(false);
    } else if (newPassword.trim() === formData.password.value.trim()) {
      setResetAlert({display: true, type: 'error', title: 'Oops!', message: 'You cannot use the same password as previously. Please choose a new password.'});
      setSubmitting(false);
    } else {
      const postUrl = process.env.REACT_APP_API_URL + '/login';
      const bodyData = {
        contact: formData.contact.value.toLowerCase(),
        password: formData.password.value,
        newPassword: newPassword,
        rememberMe: formData.rememberMe.value
      };
      axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': csrfToken}})
      .then( async (response) => {
        appRoot.setUser(response.data.user);
        if (inDialog && props.passback) {
          fade('out');
          await new Promise(resolve => setTimeout(resolve, 400));
          props.passback({complete: true, message: ''})
        } else {
          fade('out', appRoot.redirect ? appRoot.redirect : '/app');
        }
      })
      .catch( async (error) => {
        if ( error.response && error.response.data.requireReset ) {
          appRoot.setUser('');
          if (error.response && error.response.data.requireResetMessage) setResetMessage(error.response.data.requireResetMessage);
          fade('out');
          await new Promise(resolve => setTimeout(resolve, 400));
          setAlert({...alert, display: false});
          setResetMe(true);
        } else if (error.response && error.response.data.message) {
          setResetAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
          setSubmitting(false);
        }
      })
    }
  }

  // Handles successful login after social logins
  const saveSuccessfulLogin = useCallback( (response) => {
    if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(response.data.user);
    props.appRoot.setUser(response.data.user);
    sessionStorage.setItem('user', JSON.stringify({id: response.data.id}));
    if (inDialog && props.passback) {
      props.passback({complete: true, message: '', user: response.data.user})
    } else {
      navigate(props.appRoot.redirect ? props.appRoot.redirect : '/app');
    }
    setSocialInProgress(false);
  }, [inDialog, navigate, props]);

  // Handles failed login after social logins
  const processFailedLogin = useCallback( async (error) => {
    if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(error);
    props.appRoot.setUser('');
    setSocialInProgress(false);
    setSubmitting(false);
    if (Boolean(error.socialError)) {
      setAlert({display: true, type: 'error', title: 'Oops!', message: `There was a problem using ${error.socialError} to log you in. Please try again and contact our support if this problem persists.`});
    } else if (error.response && error.response.data.verifyContact) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      setAlert({...alert, display: false});
      setVerifyData(error.response.data.verifyContact);
    } else if (error.response && error.response.data.message) {
      setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
    }
  }, [alert, fade, props]);

	// Submit the email login form
	const submitForm = (resend) => {
    let count = 0;
    let stopNow = false;
    getCsrfToken( setCsrfToken ).then( (localToken) => {
      // This is a safety. If the CSRFToken fails, then we're going to try again (up to 3 times) to avoid issues
      if (resend && resend.count && resend.count > 3) { 
        setAlert({display: true, message: resend.error.response.data.message, type: 'error'});
        window.scrollTo(0, 0);
        stopNow = true;
      } else if (resend) {
        count = resend.count + 1;
      }
      if (!stopNow) {
        if (!formData.contact.error && !formData.password.error) {
          setSubmitting(true);
          const postUrl = process.env.REACT_APP_API_URL + '/login';
          const bodyData = {
            contact: formData.contact.value.toLowerCase(),
            password: formData.password.value,
            rememberMe: formData.rememberMe.value
          };
          axios.post(postUrl, bodyData, {withCredentials: true, headers: {'CSRF-Token': localToken}})
          .then( async (response) => {
            appRoot.setUser(response.data.user);
            if (inDialog && props.passback) {
              fade('out');
              await new Promise(resolve => setTimeout(resolve, 400));
              props.passback({complete: true, message: '', user: response.data.user})
            } else {
              fade('out', appRoot.redirect && appRoot.redirect !== '/login' ? appRoot.redirect : '/app');
            }
          })
          .catch( async (error) => {
            appRoot.setUser('');
            if (error.response && error.response.data.verifyContact) {
              fade('out');
              await new Promise(resolve => setTimeout(resolve, 400));
              setAlert({...alert, display: false});
              setVerifyData(formData.contact.value);
            } else if ( error.response && error.response.data.requireReset ) {
              if (error.response && error.response.data.requireResetMessage) setResetMessage(error.response.data.requireResetMessage);
              fade('out');
              await new Promise(resolve => setTimeout(resolve, 400));
              setAlert({...alert, display: false});
              setResetMe(true);
              // if there is authentication problems, try again.
            } else if (error.response.data.message.includes('authenticating')) {
              submitForm({count: count, error: error});
            } else if (error.response && error.response.data.message) {
              setAlert({display: true, type: 'error', title: 'Oops!', message: error.response.data.message});
              setSubmitting(false);
            }
          });
        } else {
          setAlert({display: true, type: 'error', title: '', message: 'Please enter a valid email and password.'});
        }
      }
    });
	}

  // Handles google login after Google response
  const handleGoogleSignIn = useCallback( (res) => {
    if (res.clientId && res.credential) {
      setSocialInProgress(true);
      const bodyData = {
        clientId: res.clientId,
        credential: res.credential,
        timezone: defaultTimezone
      };
      // Get a new csrfToken
      axios.get(`${process.env.REACT_APP_API_URL}/app`, {withCredentials: true})
      .then( (response) => { 
        if (response.data.csrfToken) {
          setCsrfToken(response.data.csrfToken);
          axios.post(`${process.env.REACT_APP_API_URL}/login/google`, bodyData, {withCredentials: true, headers: {'CSRF-Token': response.data.csrfToken}})
          .then( async (response) => { saveSuccessfulLogin(response) })
          .catch( async (err) => { processFailedLogin(err) });
        }
      })
      .catch( (err) => { processFailedLogin({error:{response:{data:{message: 'There was a problem logging you in. Please reload this page and try logging in again.'}}}}) });
    }
  }, [defaultTimezone, processFailedLogin, saveSuccessfulLogin]);

  const emailLogin = async (e) => {
    e.preventDefault();
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 500));
      props.passback({display: 'email'})
    } else {
      fade('out','/login/email');
    }
  }


  // Action from Apple button
  const loginApple = async () => {
    try {
      // Login with Apple
      const data = await window.AppleID.auth.signIn();
      setAppleUser(data);
      if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(data)
      if (data.authorization.state !== appleState) throw new Error('There was a problem logging you in. Please reload this page and try logging in again.');
      // Handle successful response.
      setSocialInProgress(true);
      // Get a new csrfToken and send to backend
      axios.get(`${process.env.REACT_APP_API_URL}/app`, {withCredentials: true})
      .then( (response) => { 
        if (response.data.csrfToken) {
          setCsrfToken(response.data.csrfToken);
          const bodyData = appleUser ? {...appleUser, ...data, timezone: defaultTimezone} : {...data, timezone: defaultTimezone};
          axios.post(`${process.env.REACT_APP_API_URL}/login/apple`, bodyData, {withCredentials: true, headers: {'CSRF-Token': response.data.csrfToken}})
          .then( async (response) => { saveSuccessfulLogin(response) })
          .catch( async (error) => { processFailedLogin(error) });
        }
      })
      .catch( (err) => { processFailedLogin({error:{response:{data:{message: 'There was a problem logging you in. Please reload this page and try logging in again.'}}}}) });
    } catch (error) { 
      if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(error)
      processFailedLogin({...error, socialError: 'apple'}) 
    };
  }

  // Load the Google script
  useEffect(() => {
    const buttonIsLoaded = document.getElementById("g_id_signin");
    // If we've already loaded the script, we do nothing
    if (!Boolean(googleLoading)) {
    // If button is not there, we pause and try again
    } else if (!buttonIsLoaded) {
      setTimeout(setGoogleLoading(x=>x+1), 50);
    // If the button is there, we load the script
    } else {
      setGoogleLoading(false);
      const initializeGoogle = () => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
          callback: handleGoogleSignIn,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("g_id_signin"),
          { width: componentRef && componentRef.current && componentRef.current.offsetWidth ? `${componentRef.current.offsetWidth}px` : '320px', height: '32px', size:"medium", type: "standard", shape: "rectangular", theme: "outline", text: "continue_with", logo_alignment: "center" }  // customization attributes
        );
      }
      // load the Google script
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = initializeGoogle;
      script.async = true;
      script.id = "google-client-script";
      const head = document.querySelector("head");
      head.appendChild(script);
    }
    return () => {
      // Cleanup function that runs when component unmounts
      if (window.google && window.google.accounts && window.google.accounts.id) window.google.accounts.id.cancel();
      if (document.getElementById("google-client-script")) document.getElementById("google-client-script").remove();
    }
  }, [ googleLoading, handleGoogleSignIn, browserSize, inDialog])

  // Load the Apple script
  useEffect(() => {
    //Initialize Apple
    const initializeApple = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}/app/nonce`, {withCredentials: true})
      .then( (response) => { 
        const appleStateNum = `appleState${Math.floor(Math.random() * 1000000000)}`;
        setAppleState(appleStateNum);
        window.AppleID.auth.init({
          clientId : process.env.REACT_APP_APPLE_CLIENT_ID,
          scope : 'name email',
          redirectURI : `${process.env.REACT_APP_WEB_URL}/login`,
          state : appleStateNum,
          nonce : response.data.nonce,
          usePopup : true
        })
      })
      .catch( (error) => { 
        if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(error);
        console.log('Apple login not enabled.')
      });
    }
    if (process.env.REACT_ENV !== 'development') {
      // Load the script
      const appleScript = document.createElement("script");
      appleScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      appleScript.onload = initializeApple;
      appleScript.async = true;
      appleScript.id = "apple-auth";
      const head = document.querySelector("head");
      head.appendChild(appleScript);
    }
    return () => {
      // Cleanup function that runs when component unmounts
      if (document.getElementById("apple-auth")) document.getElementById("apple-auth").remove();
    }
  },[]);

  // Load the Facebook script
  // useEffect(() => {
  //   //Initialize Facebook and see if user has authorized this app previously
  //   const initializeFB = () => {
  //     window.fbAsyncInit = function() {
  //       window.FB.init({
  //         appId      : process.env.REACT_APP_FB_APP_ID,
  //         cookie     : true,                     // Enable cookies to allow the server to access the session.
  //         xfbml      : true,                     // Parse social plugins on this webpage.
  //         version    : 'v15.0'                   // Use this Graph API version for this call.
  //       });
  //       window.FB.getLoginStatus(function(response) {   // Called after the JS SDK has been initialized.
  //         if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(response);
  //         if (response.status === 'connected') {   // Logged into your webpage and Facebook.
  //           setFacebookUser(response);
  //         }
  //       });
  //     };
  //   }
  //   // Load the script
  //   const script2 = document.createElement("script");
  //   script2.src = 'https://connect.facebook.net/en_US/sdk.js';
  //   script2.onload = initializeFB;
  //   script2.async = true;
  //   script2.id = "facebook-auth";
  //   const head = document.querySelector("head");
  //   head.appendChild(script2);
  //   return () => {
  //     // Cleanup function that runs when component unmounts
  //     if (document.getElementById("facebook-auth")) document.getElementById("facebook-auth").remove()
  //   }
  // },[])

  // Action from FB button
  // const facebookLogin = () => {
  //   const loginCall = (bodyData) => {
  //     setSocialInProgress(true);
  //     // Get a new csrfToken
  //     const getUrl = process.env.REACT_APP_API_URL + '/app';
  //     axios.get(getUrl, {withCredentials: true})
  //     .then( (response) => { 
  //       if (response.data.csrfToken) {
  //         setCsrfToken(response.data.csrfToken);
  //         const postUrl = process.env.REACT_APP_API_URL + '/login/facebook';
  //         axios.post(postUrl, {...bodyData, timezone: defaultTimezone}, {withCredentials: true, headers: {'CSRF-Token': response.data.csrfToken}})
  //         .then( async (response) => { saveSuccessfulLogin(response) })
  //         .catch( async (error) => { processFailedLogin(error) });
  //       }
  //     }); 
  //   }
  //   // If you have already authorized this app and have a valid access token.
  //   if (facebookUser) {
  //     loginCall(facebookUser);
  //   // If you need to login and/or authorize this app
  //   } else {
  //     window.FB.login( (response) => {
  //       if (response) {
  //         if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(response);
  //         loginCall(response);
  //       }
  //     }, {scope: 'email'});
  //   }
  // }

  // Listen for window resize
  useEffect(() => {  
    if (inDialog) {
      window.addEventListener("resize", () => setBrowserSize(initialSize()));
    }
  }, [inDialog]);

  // preRenderReady
  useEffect( () => {
    if (!inDialog) {
      const goRender = async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        window.prerenderReady = true;
      }
      if (!window.prerenderReady && fadeTime[fadeTime.length-1] === true) goRender();
    }
  },[fadeTime]);

	
	const renderResults = () => {
		if (resetMe) {
			return (
				<RequireReset appRoot={appRoot} resetMessage={resetMessage} submitReset={submitReset} alert={resetAlert} resetLogin={backToLogin} resetForm={resetLogin} inDialog={inDialog} passback={passback} />
				)
    } else if (verifyData) {
			return (
				<VerifyForm appRoot={appRoot} csrfToken={csrfToken} contactData={verifyData} rememberMe={formData.rememberMe.value} resetLogin={backToLogin} resetForm={resetLogin} inDialog={inDialog} passback={passback}
          verifyTitle={'Verify email'} verifyMessage={'A code has been sent to your email. Enter below.'} />
				)
    } else if (socialInProgress) {
      return (
        <Container align="center" sx={{maxWidth: {xs: '352px', sm: '368px'}}}>
          <Box mt={18} pb={18}>
            <CircularProgress />
          </Box>
        </Container>
      )
		} else {
				return (
            <Container align="center">
            <Box sx={{maxWidth: {xs: '352px', sm: '368px'}}}>
              {!inDialog ? <Helmet><title>Login - Meetify authentication or registration</title>
                <meta name="description" content="Login to Meetify to access your account or register for a new account. Start scheduling meetings for free." />
              </Helmet> : null}      
              {!inDialog ? <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
                <Box>
                  <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
							      <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
								      Log In {appRoot.redirect ? 'to Proceed' : ''}
							      </Typography>
                  </Fade>
                </Box>
              </Slide> : null}
              <Box ref={componentRef} pt={2}></Box>
              {alert.display && alert.type === 'warning' ? <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}><Box pb={2}><AlertMessage id="alertBox" display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box></Fade> : null}
              <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box mt={2}>
                  <Box id="g_id_signin" sx={{width: '100%', height: '32px', overflow: 'hidden'}}></Box>
                </Box>
              </Fade>
              {/* <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box pt={2}>
                  <Button id="fb_signin" onClick={facebookLogin} size="medium" variant="outlined" fullWidth sx={{height: '32px', borderColor: '#dadce0', "&:hover": {borderColor: "#d2e3fc", backgroundColor: '#f8faff'}}}><FacebookIcon sx={{mr: '3px', color: '#3b5998', height: '20px'}} /><Typography variant="body2" sx={{textTransform: 'none', fontWeight: '500'}}>Continue with Facebook</Typography></Button>
                </Box>
              </Fade> */}
              <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box pt={2}>
                  <Button id="apple_signin" onClick={loginApple} size="medium" variant="outlined" fullWidth sx={{height: '32px', borderColor: '#dadce0', "&:hover": {borderColor: "#d2e3fc", backgroundColor: '#f8faff'}}}><AppleIcon sx={{mr: '3px', color: 'black', height: '20px'}} /><Typography variant="body2" sx={{textTransform: 'none', fontWeight: '500'}}>Continue with Apple</Typography></Button>
                </Box>
              </Fade> 
              <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box pt={2}>
                  <Button id="email_signin" onClick={emailLogin} size="medium" variant="outlined" fullWidth sx={{height: '32px', borderColor: '#dadce0', "&:hover": {borderColor: "#d2e3fc", backgroundColor: '#f8faff'}}}><EmailIcon sx={{mr: '3px', color: '#3b5998', height: '20px'}} /><Typography variant="body2" sx={{textTransform: 'none', fontWeight: '500'}}>Continue with Email</Typography></Button>
                </Box>
              </Fade> 
              <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box mt={3}>
                  <Divider sx={{"&::before, &::after": {borderColor: '#cecece'}}} mt={1}><Typography variant="body2" color="text.secondary">OR</Typography></Divider>
                </Box>
              </Fade>
              {alert.display && alert.type === 'error' ? <Box mt={2}><AlertMessage id="alertBox" display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>: null}
              <form noValidate autoComplete="off">
              <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box mt={1}>
                  <TextField id="contactInfo" size="small" label="email" variant="outlined" margin="normal" fullWidth required 
                  error={formData.contact.error} helperText={formData.contact.errorText}
                  onChange={validateContact} maxLength={150} />
                </Box>
              </Fade>
              <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box mt={1}>
                  <TextField id="password" size="small" label="password" type={formData.password.visible ? 'text' : 'password'} variant="outlined" margin="none" fullWidth required
                  error={formData.password.error} helperText={formData.password.errorText}  maxLength={50}
                  onChange={validatePassword}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large">
                    {formData.password.visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                  }} />
                </Box>
              </Fade>
              <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Box pt={0.5} pb={0.5}>
                  <FormControlLabel componentsProps={{ typography: { variant: 'body2' } }} control={<Checkbox id="remember" size="small" name="remember" value="1" color="primary" onChange={rememberToggle} />} label="Remember me" />
                </Box>
              </Fade>
              <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Stack mt={0} spacing={2} direction="row">
                  {inDialog && props.passback ? 
                  <Button id="cancelButton" type="button" variant="outlined" color="primary" fullWidth onClick={ () => props.passback({cancel: true}) }>
                    Cancel 
                  </Button>
                  : null}
                  <Button id="loginButton" type="button" variant="contained" color="primary" fullWidth onClick={submitForm}>
                    {!submitting ? 'Login' : <CircularProgress size={24} color="inherit" />} 
                  </Button>
                </Stack>
              </Fade>
              </form>
              <Fade in={fadeTime[10]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
                  <Link id="registerLink" component="button" variant="body2" onClick={linkSignup} color="textSecondary">
                    Register
                  </Link>
                  <Link id="forgotPassword" component="button" variant="body2" onClick={linkPassword} color="textSecondary">
                    Forgot password
                  </Link>
                </Stack>
              </Fade>
						</Box>
            </Container>
					);
				}
			}
			
			return renderResults();
			
		}
		
		export default LoginForm;

   