import React, {useState, useEffect} from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Typography, Link, Box, Stack, Fade } from '@mui/material';
import footerLogo from '../../images/nav/Meetify-icon.png';
import { format } from 'date-fns-tz';

const Footer = (props) => {

  const appRoot = props.appRoot ? props.appRoot : {};
  const [fadeTime, setFadeTime] = useState(Boolean(appRoot.fadeFooter));
  const [pathname, setPathname] = useState(props.pathname);
  const [fadeCount, setFadeCount] = useState(700);

  useEffect( ()=> {
    if (props.pathname !== pathname) {
      setFadeCount(0);
      setFadeTime(false);
      props.appRoot.setFadeFooter(false);
      setPathname(props.pathname);
    } else if (props.appRoot.fadeFooter !== fadeTime) {
      setFadeCount(props.appRoot.fadeFooter ? 700 : 200)
      setFadeTime(Boolean(props.appRoot.fadeFooter));
    }
  }, [fadeTime, pathname, props.appRoot, props.pathname])
    
  return (
    <Box>
      <Fade in={fadeTime} timeout={fadeCount}>
        <Box mt={12} pt={1} pb={1} align="center" sx={{backgroundColor: '#f6f6f6'}}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Box pt={1}>
              <Link component={ReactRouterLink} to="/" color="inherit" underline="none">
                <img src={footerLogo} alt="Meetify Logo Mark" width="30" height="36" />
              </Link>
            </Box>
            <Box>
              <Box>
              <Typography mt={1} color="textSecondary" variant="caption" gutterBottom={false}>© {format( new Date(), 'yyyy')} Meetify, Inc. All rights reserved.</Typography>
              </Box>
              <Stack direction="row" spacing={1} justifyContent="flex-start">
                <Link component={ReactRouterLink} to="/privacy-policy" variant="caption" color="textSecondary">Privacy Policy</Link>
                <Link component={ReactRouterLink} to="/terms-of-service" variant="caption" color="textSecondary">Terms of Service</Link>
                <Link href="/sitemap.html" variant="caption" color="textSecondary">Sitemap</Link>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Fade>
    </Box>
  );
}

export default Footer;

