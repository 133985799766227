import React from 'react';
import { Box } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';


const AlertMessage = (props) =>     {
    


    const renderAlert = () => {
        if (props.display) {
            return (
                <Box sx={{alignContent: 'center'}} id="alertMessage">
                    <Alert severity={props.severity} onClose={props.onChange} sx={{textAlign: 'left'}}>
                        {props.title ? <AlertTitle color={props.severity === 'error' ? 'error.main' : props.serverity === 'warning' ? 'warning.main' : props.serverity === 'success' ? 'success.main' : 'info.main'}>{props.title}</AlertTitle> : null}
                        {props.message ? props.message : null}
                    </Alert>
                </Box>
            );
        } else {
            return null;
        }
    }
    return renderAlert();
}

export default AlertMessage;

