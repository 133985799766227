import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Box, Link, TextField, Button, Typography, Stack, Fade, Slide } from '@mui/material';
import validate from '../../../services/validationService';
import AlertMessage from '../../library/alertMessage';
import ResetForm from './resetForm';
import ReCAPTCHA from 'react-google-recaptcha';
import { customFade, getCsrfToken, isPlaywright } from '../../../services/guiService'
import {Helmet} from "react-helmet-async";
import axios from 'axios';

const defaultObj = {contact: {value:'', error: false, errorText: '', required: true}};

const PasswordForm = (props) => {

  const passback = props.passback ? props.passback : '';
  const [inDialog] = useState(props.inDialog);
  const [loadingState, setLoadingState] = useState('loading');
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [slideTime, setSlideTime] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const [verifyData, setVerifyData] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [formData, setFormData] = useState(defaultObj);
  const [alert, setAlert] = useState({display: false, title: '', message: '', type: 'alert'});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const toggleAlert = () => { setAlert({...alert, display: !alert.display}) };
  const navigate = useNavigate();

  const captchaKey = process.env.REACT_APP_RECAPTCHA;

// Fade and slide animations
const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, setSlideTime, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[navigate, fadeTime.length, props.appRoot.setFadeFooter]); 

  // Get csrfToken
  useEffect( () => { 
    if (!csrfToken && loadingState === 'loading') {
      setLoadingState('step1');
      getCsrfToken( setCsrfToken );
    } 
    if (csrfToken && loadingState === 'step1') {
      setLoadingState('complete');
      fade('in', null, true);;
    }
  }, [csrfToken, fade, loadingState]);

  const linkLogin = async (e) => {
    e.preventDefault();
    if (inDialog && props.passback) {
      fade('out');
      await new Promise(resolve => setTimeout(resolve, 400));
      props.passback({display: 'login'})
    } else {
      fade('out','/login');
    }
  }

  const validateContact = (e) => {
    if (!formData.contact.required && !e.target.value) {
      setFormData({...formData, contact: {...formData.contact, value: e.target.value, error: false, errorText: ''}})
    } else {
      const response = validate.check(e.target.value, ['required', 'email']);
      setFormData({...formData, contact: {...formData.contact, value: e.target.value, error: !response.success, errorText: !response.success ? `Must be a valid email or phone.` : ''}})
      if (isPlaywright() && response.success) {
        setButtonDisabled(false);
      } else if (isPlaywright()) {  
        setButtonDisabled(true);
      }
    }
  }
    
  const resetLogin = (message) => {
    setVerifyData('');
    if (message) {
      setAlert({display: true, title: 'Oops!', message: message, type: 'error'});
    }
  }

  const onChange = (e) => {
    const postUrl = process.env.REACT_APP_API_URL + '/app/recaptcha';
    axios.post(postUrl, {captchaValue: e}, {withCredentials: true, headers: {'CSRF-Token': csrfToken}})
    .then( (response) => { 
      setButtonDisabled(false);
     })
    .catch( (error) => { 
      console.log(error);
    });
  }

  const submitForm = (resend) => {
    if (!buttonDisabled && !formData.contact.error) {
      let count = 0;
      let stopNow = false;
      getCsrfToken( setCsrfToken ).then( (localToken) => {
        // This is a safety. If the CSRFToken fails, then we're going to try again (up to 3 times) to avoid issues
        if (resend && resend.count && resend.count > 3) { 
          setAlert({display: true, message: resend.error.response.data.message, type: 'error'});
          window.scrollTo(0, 0);
          stopNow = true;
        } else if (resend) {
          count = resend.count + 1;
        }
        if (!stopNow) {
          setSubmitting(true);
          const postUrl = process.env.REACT_APP_API_URL + '/login/forgot';
          const formattedContact = formData.contact.value.toLowerCase();
          axios.post(postUrl, {contact: formattedContact}, {withCredentials: true, headers: {'CSRF-Token': localToken}})
          .then( async (response) => {
            fade('out');
            await new Promise(resolve => setTimeout(resolve, 500));
            setVerifyData(formattedContact);
          })
          .catch( (error) => { 
            setSubmitting(false);
            if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('authenticating')) {
              submitForm({count: count, error: error});
            } else if (error.response.data.errors) {
              for (let i=0; i < error.response.data.errors.length; i++) {
                let row = error.response.data.errors[i]; 
                setFormData({...formData, contact: {...formData.contact, error: true, errorText: row.message}});
              }
            }
          });
        }
      });
    }
  }
  
  const renderResults = () => {
    if (verifyData) {
      return (
        <ResetForm appRoot={props.appRoot} csrfToken={csrfToken} contactData={verifyData} resetLogin={resetLogin} inDialog={inDialog} passback={passback} />
      )
    } else {
      return (
          <Container maxWidth="xs" align="center">
            {!inDialog ? <Helmet><title>Reset Password - Meetify</title></Helmet> : null}    
            <Slide direction="down" in={slideTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)} mountOnEnter unmountOnExit>
              <Box>
                <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
                  <Typography component="h1" variant="h4" sx={{fontWeight: 'bold'}}>
                    Reset password
                  </Typography>
                </Fade>
              </Box>
            </Slide>
            <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Box><AlertMessage id="alertBox" display={alert.display} severity={alert.type} title={alert.title} message={alert.message} onChange={toggleAlert} /></Box>
          </Fade>
            <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Box mt={4}>
                <Typography>
                  Forgot your password? No worries. Enter your account email and we'll help you reset it.
                </Typography>
              </Box>
            </Fade>
            <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Box>
                <TextField id="contact" label="email" variant="outlined" margin="normal" fullWidth required
                  error={formData.contact.error} helperText={formData.contact.errorText} onChange={validateContact} maxLength={150} />
              </Box>
            </Fade>
            {isPlaywright() ? null :
            <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Box mt={2}>
                <ReCAPTCHA sitekey={captchaKey} onChange={onChange} />
              </Box>
            </Fade>}
            <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Stack mt={4} spacing={2} direction="row" justifyContent="center">
                {inDialog && props.passback ? 
                <Button id="cancelButton" type="button" variant="outlined" color="primary" size="large" onClick={ () => props.passback({cancel: true}) }>
                  Cancel 
                </Button>
                : null}
                <Button id="submitButton" type="button" variant="contained" color="primary" size="large" onClick={submitForm} disabled={buttonDisabled}>
                  {!submitting ? 'Submit' : <CircularProgress size={26} color="inherit" sx={{mr: 1.6, ml: 1.6}} />} 
                </Button>
              </Stack>
            </Fade>
            <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
              <Box mt={4} align="center">
                <Link id="loginLink" component="button" variant="body2" onClick={linkLogin}>
                  Back to login
                </Link>
              </Box>
            </Fade>
            <Box mt={6} sx={{textAlign: 'center'}}>
                <Typography variant="caption" color="textSecondary">Don't see a reCAPTCHA box? Reload this page.</Typography>
            </Box>
        </Container>
      );
    }
  }

  return renderResults();

}

export default PasswordForm;