import React from 'react';
import { Container, Typography, Box, Stack, Button } from '@mui/material';
import { Notifier } from '@airbrake/browser';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { getCsrfToken } from '../../services/guiService';
import axios from 'axios';
import footerLogo from '../../images/nav/Meetify-logo-200.png';

let theme = createTheme({
  typography: {
    allVariants: {
      color: '#5B5B5B',
    },
    h1: {
      color: '#132e53',
    },
    h2: {
      color: '#132e53',
    },
    h3: {
      color: '#132e53',
    },
    h4: {
      color: '#132e53',
    },
    h5: {
      color: '#007377',
    },
    h6: {
      color: '#007377',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#007377'
    },
    warning: {
      main: '#B86C0F'
    },
    error: {
      main: '#E00444'
    },
    success: {
      main: '#5DC98B',
    },
    secondary: {
      main: '#a8d5ba',
    },
    headers: {
      main: '#132e53',
    },
    text: {
      primary: '#5B5B5B',
      secondary: 'rgba(19,46,83,0.54)',
    },
    divider: '#a8d5ba',
  },
  components: {
    // Name of the component
    MuiToolbar: {
      styleOverrides: {
          dense: {
              height: 64,
              minHeight: 64
          }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(168, 212, 185, 0.8)',
        },
        invisible: {
          backgroundColor: 'rgb(0, 0, 0, 0)',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 11px 15px -7px rgb(20 46 83 / 30%), 0px 24px 38px 3px rgb(20 46 83 / 24%), 0px 9px 46px 8px rgb(20 46 83 / 22%)',
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: props.showError ? true : false };
    if (process.env.REACT_APP_ENV === 'production') {
      try {
      this.airbrake = new Notifier({
        projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
        projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
      });
      } catch (e) {
        console.log('airbrake error');
      }
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    if (process.env.REACT_APP_ENV === 'production') {
      if (error) console.log(error);
      if (info) console.log(info);
      try {
        // convert the error to a string and post it to the support email api call
        getCsrfToken().then((csrfToken) => {
          const stringError = String(error) + JSON.stringify(info);
          const data = {firstName: 'Error', lastName: 'Log', contact: 'nospam@mediaguy.com', message: stringError};
          const postUrl = process.env.REACT_APP_API_URL + `/app/support`;
          axios({method: 'POST', url: postUrl, data: data, withCredentials: true, headers: {'CSRF-Token': csrfToken}})
          .then( (response) => {
            console.log('error sent to support');
          })
          .catch( (error) => {
            if (process.env.REACT_APP_ENV !== 'production') console.log(error);
          });
        }).catch((error) => {
          if (process.env.REACT_APP_ENV !== 'production') console.log(error);
        });
        this.airbrake.notify({
          error: error,
          params: {info: info},
          environment: 'production'
        });
      } catch (e) {
        console.log('airbrake error');
      }
    } else {
      if (error) console.log(error);
      if (info) console.log(info);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ThemeProvider theme={theme}>
          <Container align="center" maxWidth="sm">
              <Box pt={6}>
                {!this.props.showError && <img src={footerLogo} alt="Meetify Logo" width="200" />}
              </Box>
              
            <Box mt={4} p={4} sx={{backgroundColor: 'rgb(226, 241, 232)', border: 1, borderColor: 'secondary.light', borderRadius: '8px', boxShadow: '0px 2px 5px #eeeeee'}}>
              <Box>
                <Typography variant="h4" sx={{fontWeight: 'bold'}}>Oops! We screwed up.</Typography>
              </Box>
              <Box pt={4}>
                <Typography variant="h6">We apologize, but the page you were accessing experienced an error. Our team has been notifed.</Typography>
              </Box>
              <Stack direction="row" spacing={2} mt={6} justifyContent="center">
                <Button variant="contained" href="/">Back to Site</Button>
                <Button variant="contained" href="https://meetify.com/support">Contact Us</Button>
              </Stack>

            </Box>
          </Container>
        </ThemeProvider>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;