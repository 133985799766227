import React, {useState, useEffect} from 'react';
import { Box, Autocomplete, Grid, Typography, TextField } from '@mui/material';
import { LocationOn }  from '@mui/icons-material';
import { getCsrfToken } from '../../services/guiService';
import { getNested } from '../../services/utilityService';
import axios from 'axios';


const AutoComplete = ({name, location={}, passback, size='medium', restrictTo, options, csrfToken='', baseLocation={}, baseCountry, displayProps={}, margin="normal"}) => {

  // props needed include csrfToken, baseLocation, location, passback (which will passback the location object)
  const [localLocation, setLocalLocation] = useState(location);
  const [autoOptions, setAutoOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [locToken, setLocToken] = useState(csrfToken);

  // Autocomplete calls for address field
  const autoComplete = async (e) => {
    const localToken = locToken && !getNested(e, 'resend.newToken') ? locToken : await getCsrfToken( setLocToken );
    if (!submitting) {
      let count = 0;
      let stopNow = false;
      // This is a safety. If the CSRFToken fails, then we're going to try again (up to 3 times) to avoid issues
      if (e.resend && e.resend.count && e.resend.count > 3) { 
        stopNow = true;
      } else if (e.resend) {
        count = e.resend.count + 1;
      }
      if (!stopNow) {
        setSubmitting(true);
        if (e) {
          const dataPackage = {
            locationField: e.target.value,
            baseLocation: baseLocation,
            baseCountry: baseCountry
          }
          const postUrl = process.env.REACT_APP_API_URL + '/app/autocomplete'
          axios.post(postUrl, dataPackage, {withCredentials: true, headers: {'CSRF-Token': localToken}})
          .then( (response) => {
              setSubmitting(false);
              let tempRows = response.data;
              // if we are restricting to certain types, then filter
              if (restrictTo) {
                tempRows = tempRows.filter(x=>restrictTo.includes(x.resultType));
              }
              // Return a max of 6 results
              tempRows = tempRows.splice(0, 6);
              for (let i=0; i<tempRows.length; i++) {
                // add unique key with the address for the label
                tempRows[i]['order'] = i;
                tempRows[i]['key'] = tempRows[i].address ? tempRows[i].address.label.split(',')[1] : null
              }
              // if options are found, set them. Otherwise, set to the props.options (which are prefilled addresses)
              setAutoOptions(tempRows && tempRows.length ? tempRows : options ? options : []);
          })
          .catch( (error) => {
            setSubmitting(false);
            if (error.response && error.response.data && error.response.data.message.includes('authenticating')) {
              setLocToken('');
              // wait 50 milliseconds and try again
              setTimeout(autoComplete({...e, resend: {count: count, error: error, newToken: true}}), 50);
            } else {
              console.log(error);
            }
          })
        } else {
          setSubmitting(false);
          setAutoOptions(options ? options : []);
        }
      }
    } 
  }

  // set the options if they are passed in
  useEffect( () => { 
    if (options) setAutoOptions(options);
    if (location) setLocalLocation(location);
  }, [options, location])

  // if a new row come in, update from the props. This should ONLY update on a row change (ignore the esLint warning)
  // This is for the old wizard?
  // useEffect( () => {
  //   setLocation(props.location);
  // }, [props.row])

  return (
      <Box>
        <Autocomplete
          id={name ? name + Math.floor(Math.random() * 1000000000) : 'location' + Math.floor(Math.random() * 1000000000)} /* using random numbers to avoid browser autoComplete */
          name={name ? name + Math.floor(Math.random() * 1000000000) : 'location' + Math.floor(Math.random() * 1000000000)}
          includeInputInList
          freeSolo={true}
          size={size ? size : 'medium'}
          options={autoOptions}
          filterOptions={(options, state) => {
            if (localLocation.value && options.filter(x=>x.title.includes(localLocation.value)).length) {
              return options.filter(x=>x.title.includes(localLocation.value));
            } else {
              return options;
            }
          }}
          getOptionLabel={ (option) => (typeof option === 'string' ? option : option.order + ') ' + option.title + ', ' + option.key)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={ (props, option) => {
            if (option.resultType !== 'categoryQuery' && option.resultType !== 'chainQuery') {
              // customized location result
              const limitedProps = {...props}
              delete limitedProps['key'];
              return (
                <li key={props.key} {...limitedProps} id="autocompleteOption">
                <Grid container alignItems="flex-start" mt={0.5}>
                  <Grid item>
                    <LocationOn color="secondary" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">
                      {option.title.split(',')[0]}
                    </Typography>
                    <Typography variant={option.address && option.address.label.split(',')[1] ? 'body2' : 'body1'} color={option.address && option.address.label.split(',')[1] ? 'textSecondary' : 'textPrimary'} >
                      {option.address.label.split(',')[1]}, {option.address.label.split(',')[2]}
                    </Typography>
                  </Grid>
                </Grid>
                </li>
              )
            }
          }}
          inputValue={localLocation.value ? localLocation.value : ''}
          value={localLocation.data && localLocation.data.title ? localLocation.data.title : ''}
          onChange={ (event, value) => {
            if (value && value.title) {
              // After item is selected, set the data. Value is reset if they just wiped out the data. 
              const updatedLocation = {...localLocation, data: value, value: value ? value.title : '', error: false, errorText: ''}
              setLocalLocation(updatedLocation);
              if (passback) passback(updatedLocation);
            }
          }}
          onInputChange={ (event, newInputValue) => {
            // this prevents double execution
            if (event) {
              // After user input, update the field value and set the error to true since items are not selected
              const updatedLocation = {...localLocation, value: newInputValue, error: (localLocation.data && localLocation.data.title === newInputValue) || !newInputValue ? false : true, errorText: newInputValue && newInputValue.length > 5 ? 'Select from the drop-down options.' : ''}
              // if they start adjusting the text, then we wipe out the data
              if (localLocation.data && localLocation.data.title !== newInputValue) { 
                updatedLocation.data = '';
              }
              setLocalLocation(updatedLocation);
              if (passback) passback(updatedLocation);
              autoComplete(event);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={localLocation.label ? localLocation.label : 'address or general area'} error={localLocation.error} helperText={localLocation.errorText} variant="outlined" margin={margin ? margin : 'normal'}
            sx={{textOverflow: "ellipsis", ...displayProps }} fullWidth required={localLocation.required} inputProps={{...params.inputProps, autoComplete: 'nope', form: {autoComplete: 'nope'}}} autoComplete="nope" id="addressField" />
          )}
        />
      </Box>
    )
}

export default AutoComplete;

