const parsePhoneNumber = require('awesome-phonenumber');

const validationService = ( ()=> {

  /**
   * Returns default validation object
   * @returns {Object}
   */
  const getDefaults = () => {
      return { success: true, message: ''};
  }

  /**
   * Is value greater than min characters
   * @param {String} value 
   * @param {Integer} min
   * @returns {Boolean}
   */
  const minLength = (value, min) => {
      let response = getDefaults();
      if ( value && value.length < min ) {
          response.success = false;
          response.message = `Must be at least ${min} characters. `;
      }
      return response;
  }

  /**
   * Is value greater than 8 characters
   * @param {String} value 
   * @returns {Boolean}
   */
  const minLength_8 = (value) => {
      return minLength(value, 8);
  }

  /**
   * Is value less than max characters
   * @param {String} value 
   * @param {Integer} max
   * @returns {Boolean}
   */
  const maxLength = (value, max) => {
      let response = getDefaults();
      if (value && typeof value === 'string' && value.length > max) {
          response.success = false;
          response.message = `Must be less than ${max} characters. `;
      }
      return response;
  }

  /**
   * Is value less than 50 characters
   * @param {String} value 
   * @returns {Boolean}
   */
  const maxLength_50 = (value) => {
      return maxLength(value, 50);
  }

  /**
   * Is value less than 100 characters
   * @param {String} value 
   * @returns {Boolean}
   */
  const maxLength_100 = (value) => {
      return maxLength(value, 100);
  }

  /**
   * Is value less than 500 characters
   * @param {String} value 
   * @returns {Boolean}
   */
     const maxLength_500 = (value) => {
      return maxLength(value, 500);
  }

  /**
   * Does value exist?
   * @param {String} value 
   * @returns {Boolean}
   */
  const required = (value) => {
      let response = getDefaults();
      if (value === '') {
          response.success = false;
          response.message = `Required. `;           
      }
      return response;
  }

  /**
   * Is Boolean
   * @param {Boolean} value 
   * @returns {Boolean}
   */
  const boolean = (value) => {
      let response = getDefaults();
      try { 
          if (!value !== true && !value !== false ) {
              response.success = false;
              response.message = `Must be boolean. `;           
          }
      } catch(any) {
          response.success = false;
          response.message = `Must be boolean. `;     
      }
      return response;
  }

  /**
   * Does username contain only numbers, letters, periods, or underscores?
   * @param {String} value 
   * @returns {Boolean}
   */
  const username = (value) => {
      let response = getDefaults();
      if (/[^0-9a-zA-Z//.//_]/.test(value)) {
          response.success = false;
          response.message = 'Can only contain numbers, letters, periods or underscores. ';
      }
      return response;
  }

  /**
   * Is a valid date?
   * @param {String} value 
   * @returns {Boolean}
   */
  const dateCheck = (value) => {
  let response = getDefaults();
  if ( !value || isNaN(Date.parse(value)) ) {
      response.success = false;
      response.message = 'Must contain a valid date. ';
  }
  return response;
  }

  /**
   * Is a valid time?
   * @param {String} value 
   * @returns {Boolean}
   */
  const timeCheck = (value) => {
  let response = getDefaults();
  const strongRegex = new RegExp("^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM|am|pm)$");  
  if (!strongRegex.test(value)) {
      response.success = false;
      response.message = 'Must contain a valid time. ';
  }
  return response;
  }

  /**
   * Is value an email address?
   * @param {String} value 
   * @returns {Boolean}
   */
  const email = (value) => {
      let response = getDefaults();
      const strongRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");  
      if ( !strongRegex.test(value) ) {
          response.success = false;
          response.message = `Must be a valid email address. `;           
      }
      return response;     
  }

    /**
   * Is value an phone number?
   * @param {String} value 
   * @returns {Boolean}
   */
      const phone = (value) => {
      let response = getDefaults();
      const checkPhone = parsePhoneNumber(value);
      if ( !checkPhone.isValid() ) {
          response.success = false;
          response.message = `Must be a valid phone number. `;           
      }
      return response;     
    }

  /**
   * Does provided password meet the password requirements of containing upper, lower, symbol, number?
   * @param {String} value 
   * @returns {Boolean}
   */
  const password = (value) => {
      let response = getDefaults();
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=)");
      if ( !strongRegex.test(value) ) {
          response.success = false;
          response.message = `Must contain upper case, lower case, symbol and number. `;           
      }
      return response;      
  }

  const individualChecks = {
      minLength_8: minLength_8,
      maxLength_50: maxLength_50,
      maxLength_100: maxLength_100,
      maxLength_500: maxLength_500,
      maxLength: maxLength,
      required: required,
      boolean: boolean,
      username: username,
      email: email,
      phone: phone,
      timeCheck: timeCheck,
      dateCheck: dateCheck,
      password: password
  }

  /**
   * Take an individual value and run multiple checks on it
   * @param {String} value
   * @param {Array} checks An array of checks to run on the value ['required','email']
   * @returns {Object} {success: boolean, message: string}
   */
  const check = (value, checks) => {
      let evaluation = getDefaults();
      let testResponse = getDefaults();
      for (let i=0; i < checks.length; i++) {
          testResponse = individualChecks[checks[i]](value);
          if (!testResponse.success) {
              evaluation.success= false;
              evaluation.message = evaluation.message + testResponse.message;
          }
      }
      return evaluation;
  }

  /**
   * Check a set of values against a set of criteria
   * @param {Array} args An array of arrays, formated: [ ['first_name', user.first_name, ['required', 'maxLength_50']], ['email', user.email, ['required', 'email']] ]
   * @returns {Object} {success: boolean, message: string, errors: array of objects}
   */
  const multiCheck = (args) => {
      // args is formated like: 
      let multiEval = { success: true, message: '', errors: []};
      let thisCheck;
      args.forEach( row => {
          thisCheck = check(row[1],row[2]);
          if (!thisCheck.success) {
              multiEval.success= false;
              multiEval.message = multiEval.message + row[0] + ': ' + thisCheck.message;
              multiEval.errors.push( {type: row[0], source: {parameter: row[1]}, message: thisCheck.message} );
          }
      })
      return multiEval;
  }

  return {
      check,
      multiCheck
  }

})();

module.exports = validationService;