// This page split from App.js so that the appRoot can be passed down to everything with location data

import React, {useEffect, useState, useCallback, Suspense, lazy, useRef} from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { recentLogin, initialSize2, standardSize } from './services/guiService';
import axios from 'axios';
import Cookies from "js-cookie";
// page elements
import LoadingIcon from './components/library/loadingIcon';
import PublicHeader from './components/nav/headerPublic';
import AppHeader from './components/nav/headerApp';
// main pages to preload
import Home from './components/main/home';
import Features from './components/main/features';
import Pricing from './components/main/pricing';
import Resources from './components/main/resources';
import Article from './components/main/article';
import MainApp from './components/app/app'; 
import ErrorBoundary from './components/library/errorBoundary';

// https://www.bitahoy.com/blog/post/fixing-chunk-load-errors-in-webpack-and-react-js
const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        } else {
          // Send error information to support before rejecting
          try {
            const errorData = {
              firstName: 'System',
              lastName: 'Error',
              contact: 'support@meetify.com',
              message: `Lazy loading error:\nPath: ${window.location.pathname}\nUser: ${JSON.stringify(sessionStorage.getItem('user'))}\nFailed Component: ${storageKey}\nError: ${error.message || error}`
            };
            axios.post(`${process.env.REACT_APP_API_URL}/app/support`, errorData, {withCredentials: true});
          } catch (err) {
            // ignore any errors from sending the support message
          }
          reject(error); // Default error behaviour as already tried refresh
        }
      });
  });

// less important pages to lazy load
const About = lazy( () => lazyRetry(() => import('./components/main/about')));
const Support = lazy( () => lazyRetry(() => import('./components/main/support')));
const TOS = lazy( () => lazyRetry(() => import('./components/main/tos')));
const Privacy = lazy( () => lazyRetry(() => import('./components/main/privacy')));
const Cookie = lazy( () => lazyRetry(() => import('./components/main/cookie-policy')));
const Unsubscribe = lazy( () => lazyRetry(() => import('./components/main/unsubscribe')));
const StyleGuide = lazy( () => lazyRetry(() => import('./components/main/style-guide')));
const NotFound = lazy( () => lazyRetry(() => import('./components/main/404')));
const Checkout = lazy( () => lazyRetry(() => import('./components/main/checkout')));
const Calendly = lazy( () => lazyRetry(() => import('./components/main/landing-calendly')));
const YouCanBookMe = lazy( () => lazyRetry(() => import('./components/main/landing-youcanbookme')));
const Pastors = lazy( () => lazyRetry(() => import('./components/main/landing-pastors')));
const Realtors = lazy( () => lazyRetry(() => import('./components/main/landing-realtors')));
const Acuity = lazy( () => lazyRetry(() => import('./components/main/landing-acuity')));
// site sections to lazy load
const Admin = lazy( () => lazyRetry(() => import('./components/admin/app')));


// Show the active Header based on the location and login status
function ActiveNav(props) {
  if (props.appRoot && props.appRoot.pathname) {
    const appPaths = ['/app', '/admin', '/invite', '/login', '/a/'];
    let iAmInApp = false;
    appPaths.forEach( (path) => {
      if ( String(props.appRoot.pathname).slice(0, path.length) === path ) iAmInApp = true;
    });
    // include pathname and query in appRoot
    if (props.appRoot.pathname.includes('/login/microsoft')) {
      return null; // don't show header on microsoft login
    } else if (props.appRoot.user && iAmInApp) {
      return <AppHeader appRoot={props.appRoot} />
    } else {
      return <PublicHeader appRoot={props.appRoot} />
    }
  } else {
    return null;
  }
}

// The app router, navigation, and user state
function AppRoutes() {

  const [loadingState, setLoadingState] = useState('loading');
  const [user, setUser] = useState('');
  const [blockedUser, setBlockedUser] = useState(false);
  const [fadeFooter, setFadeFooter] = useState(false);
  const [browserSize, setBrowserSize] = useState(initialSize2());
  const [windowSize, setWindowSize] = useState(standardSize());
  const [viewPage, setViewPage] = useState('');
  const [appRoot, setAppRoot] = useState(null);
  const location = useLocation();
  const measureRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Get a specific cookie
  const getCookie = (name) => {
    return document.cookie.split('; ').filter(row => row.startsWith(name + '=')).map(c=>c.split('=')[1])[0];
  }

  // For the test server
  const testServerCheck = useCallback( () => {
    let urlVar;
    const urlParams = window.location.search.substring(1);
    if (urlParams && urlParams[0] === 't') urlVar = urlParams.replace('t=','');
    const testCookie = getCookie('test');
    if (urlVar && urlVar === process.env.REACT_APP_TEST_COOKIE) {
      window.history.pushState({}, document.title, "/" );
      document.cookie = `test=${process.env.REACT_APP_TEST_COOKIE};max-age=2592000`;
    } else if (!testCookie || (testCookie !== process.env.REACT_APP_TEST_COOKIE)) {
      setBlockedUser(true);
    }
  }, []);

  // Update the local user and store the fact that you are logged in in the session
  const updateUser = useCallback( (obj) => {
    const newUser = obj && user ? {...user, ...obj} : obj;
    setUser(newUser);
    if (newUser) {
      sessionStorage.setItem('user', JSON.stringify(newUser));
      const nowTime = newUser.exp ? newUser.exp : new Date(Date.now() + (12*60*60*1000));
      localStorage.setItem('lastLogin', JSON.stringify({login: new Date(), exp: nowTime}));
    } else {
      sessionStorage.removeItem('user');
      localStorage.removeItem('lastLogin');
    }
  }, [user]);

  // Get the user from the server
  const getUser = useCallback( async (options) => {
    const urlOptions = options ? options : '?includeFeatures=1&includeUsage=1';
    const getUrl = `${process.env.REACT_APP_API_URL}/users/s/${urlOptions}`;
    try {
      const response = await axios.get(getUrl, {withCredentials: true, headers: {'Cache-Control': 'no-cache','Pragma': 'no-cache','Expires': '0'}});
      const newUser = user ? {...user, ...response.data} : response.data;
      updateUser(newUser);
      return newUser;
    } catch (error) {
      updateUser('');
      return null;
    }
  }, [user, updateUser]);

  // Global logout function
  const logout = useCallback( async (token) => {
    let csrfToken = token;
    updateUser('');
    try { 
      if (!csrfToken) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/app`, {withCredentials: true});
        csrfToken = response.data.csrfToken;
      }
      axios.delete(`${process.env.REACT_APP_API_URL}/login`, {withCredentials: true, data: {}, headers: {'CSRF-Token': csrfToken} })
      .then( (response) => { 
        const cookieSettings = {httpOnly: true, sameSite: 'strict', path: '/'};
        if (process.env.REACT_APP_ENV !== 'development') {
          cookieSettings.secure = true; 
          cookieSettings.domain = '.meetify.com';
        }
        Cookies.remove('jwt', cookieSettings);
        Cookies.remove('refresh', cookieSettings);
        Cookies.remove('isAdmin', cookieSettings);
        sessionStorage.clear();
        return response.data
       } )
      .catch( () => { return {} } );
    } catch (error) {
      if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(error);
      return {};
    }
  }, [updateUser]);

  // Global functions used to control login and layout
 
  // On first load, check if the user is logged in
  useEffect( () => {
    if(loadingState === 'loading') {
      setLoadingState('step1');
      // Test environment requires the URL variable or the cookie
      if (process.env.REACT_APP_ENV === 'test') testServerCheck();
      // Check the user if you recently logged in
      const localUser = recentLogin() ? sessionStorage.getItem('user') : null;
      // If user stored in session (same tab)
      if (localUser) {
        const userObj = JSON.parse(localUser);
        setUser(userObj);
      // If they logged in recently, but session is empty (new tab or remember me), we'll go check if they are logged in
      } else if (recentLogin()) {
        getUser();
      }
      // set the appRoot object with items that don't change
      setAppRoot({
        setUser: updateUser,
        setViewPage: setViewPage,
        refreshUser: getUser,
        logout: logout,
        setFadeFooter: setFadeFooter,
      });
    }
    if (loadingState === 'step1' && appRoot) {
      setLoadingState('complete');
    }
  }, [loadingState, testServerCheck, getUser, updateUser, appRoot, logout, setFadeFooter, setViewPage]);

  // determine the responsive size of the box with measureRef
  const determineSize = useCallback( () => {
    if (measureRef.current) {
      const width = measureRef.current.getBoundingClientRect().width;
      let responsiveSize;
      if (width === 10) responsiveSize = 'xs';
      if (width === 20) responsiveSize = 'sm';
      if (width === 30) responsiveSize = 'md';
      if (width === 40) responsiveSize = 'lg';
      if (width === 50) responsiveSize = 'xl';
      return responsiveSize;
    } else {
      return null;
    }
  }, [measureRef]);

  // Listen for window resize and update the browserSize and windowSize
  useEffect(() => {
    const handleResize = () => { 
      const bSize = initialSize2();
      setBrowserSize(bSize);
      const wSize = determineSize();
      setWindowSize(wSize);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [determineSize]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const fullUrl = window.location.href;
    const trueUrl = fullUrl.split('?')[0];
    const paramList = [];
    searchParams.forEach((value, key) => {
      paramList.push(`${key}=${value}`);
    });
    if (pathname.endsWith('/') && trueUrl.endsWith('/')) {
      // special case for the home page, where we remove params after a slash
      if (pathname === '/' && paramList.length > 0) {
        // can't find anyway to keep the params for the root page
        setSearchParams({});
      } else {
        // if there were trailing params, we keep them
        if (paramList && paramList.length > 0) {
          const url = `${pathname.slice(0, -1)}?${paramList.join('&')}`
          window.history.replaceState({}, '', url);
        } else {
          window.history.replaceState({}, '', `${pathname.slice(0, -1)}`);
        }
      }
    }
  }, [searchParams, setSearchParams]);

  // Update the appRoot object with changable variables after the initial load
  useEffect( () => {
    if (loadingState === 'complete') {
      const localSize = determineSize();
      setAppRoot(e=>({...e, user: user, fadeFooter: fadeFooter, browserSize: browserSize, windowSize: localSize, pathname: location.pathname, query: location.search, state: location.state, viewPage: viewPage}));
    }
  }, [user, fadeFooter, browserSize, windowSize, location.pathname, location.search, location.state, viewPage, loadingState, setAppRoot, determineSize]);

  // allow state to evaluate the cookie before deciding status
  if (loadingState !== 'complete') {
    return <Box></Box>
  // if on test server
  } else if (blockedUser) {
    return (
      <Box>Access denied.</Box>
    )
  // full site 
  } else {

    return (
        <div>
          <ActiveNav appRoot={appRoot} />
          <Suspense fallback={<LoadingIcon />}>
            <Routes>
              {/* Main site */}
              <Route path="/" element={<Home appRoot={appRoot} />} />
              <Route path="/about" element={<About appRoot={appRoot} />} />
              <Route path="/features" element={<Features appRoot={appRoot} />} />
              <Route path="/pricing" element={<Pricing appRoot={appRoot} />} />
              <Route path="/support" element={<Support appRoot={appRoot} />} />
              <Route path="/resources/:category/:uid" element={<Article appRoot={appRoot} />} />
              <Route path="/resources/:category" element={<Resources appRoot={appRoot} />} />
              <Route path="/resources" element={<Resources appRoot={appRoot} />} />
              <Route path="/privacy-policy" element={<Privacy appRoot={appRoot} />} />
              <Route path="/terms-of-service" element={<TOS appRoot={appRoot} />} />
              <Route path="/cookie-policy" element={<Cookie appRoot={appRoot} />} />
              <Route path="/calendly-alternative" exact element={<Calendly appRoot={appRoot} />} />
              <Route path="/youcanbookme-alternative" exact element={<YouCanBookMe appRoot={appRoot} />} />
              <Route path="/acuity-scheduling-alternative" exact element={<Acuity appRoot={appRoot} />} />
              <Route path="/scheduling-for-pastors" exact element={<Pastors appRoot={appRoot} />} />
              <Route path="/real-estate-scheduling" exact element={<Realtors appRoot={appRoot} />} />
              <Route path="/mail-preferences" element={<Unsubscribe appRoot={appRoot} />} />
              <Route path="/checkout" element={<Checkout appRoot={appRoot} />} />
              <Route path="/404" element={<NotFound appRoot={appRoot} />} />
              <Route path="/error" element={<ErrorBoundary appRoot={appRoot} showError={true} />} />
              {process.env.REACT_APP_ENV !== 'production' ? <Route path="/style-guide" element={<StyleGuide appRoot={appRoot} />} /> : null}
              {/* Application */}
              <Route path="/admin/*" element={<Admin appRoot={appRoot} />} />
              <Route path="/*" element={<MainApp appRoot={appRoot} />} />
            </Routes>
          </Suspense>
          <Box sx={{width: {xs: '10px', sm: '20px', md: '30px', lg: '40px', xl: '50px'}}} ref={measureRef}></Box>
      </div>
    );
  }
}

export default AppRoutes;
